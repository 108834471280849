import Api from "@/services/Api";

export default {
  async transaction(id) {
    const response = await Api().get("payments/transaction/" + id);
    return response.data;
  },
  async emailReceipt(id, altEmail, invoice) {
    const response = await Api().post(
      "payments/transaction/" +
        id +
        "/email?" +
        (altEmail ? "&email=" + altEmail : "") +
        (invoice ? "&invoice=" + invoice : "")
    );
    return response.data;
  },
  async transactions(managedAcademyId, familyMemberId) {
    const response = await Api().get(
      "payments/transactions/all" +
        (familyMemberId && managedAcademyId
          ? "?ma=" + managedAcademyId + "&fm=" + familyMemberId
          : "")
    );
    return response.data;
  },
  async count() {
    const response = await Api().get("payments/transactions/count");
    return response.data;
  },
  async complete(id) {
    const response = await Api().patch("payments/checkout/complete/" + id);
    return response.data;
  },
  async completeCreditCardUpdate(tid, sid) {
    const response = await Api().patch(
      "payments/checkout/update/complete/" + tid + "/" + sid
    );
    return response.data;
  },
  async completeCOFUpdate(managedAcademyId, mid, sid) {
    const response = await Api().patch(
      "payments/managedacademy/" +
        managedAcademyId +
        "/checkout/update/complete/member/" +
        mid +
        "/" +
        sid
    );
    return response.data;
  },
  async retryCardPayment(tid, pid) {
    const response = await Api().post(
      "payments/subscription/" + tid + "/retry/payment",
      {
        paymentIntent: pid,
      }
    );
    return response.data;
  },
  async cancel(id) {
    const response = await Api().patch("payments/checkout/cancel/" + id);
    return response.data;
  },
  async markExpired(id) {
    const response = await Api().patch(
      "payments/transactions/" + id + "/expire"
    );
    return response.data;
  },
  async initTransactionCheckout(id) {
    const response = await Api().patch(
      "payments/transactions/" + id + "/checkout/init"
    );
    return response.data;
  },
  async getUpdateSession(id) {
    const response = await Api().get(
      "payments/transaction/" + id + "/update/session"
    );
    return response.data;
  },
  async initUpdateCreditCardSession(id) {
    const response = await Api().patch(
      "payments/transaction/" + id + "/cc/update"
    );
    return response.data;
  },
  async initUpdateCreditCardSessionMember(managedAcademyId, memberId) {
    const response = await Api().patch(
      "payments/managedacademy/" +
        managedAcademyId +
        "/member/" +
        memberId +
        "/cc/update"
    );
    return response.data;
  },
  async initCheckoutEvent(eventId) {
    const response = await Api().post(
      "payments/checkout/onetime/credit/event",
      { event: eventId }
    );
    return response.data;
  },
  async getTransactionV3(id) {
    const response = await Api().get("payments/v3/user/transaction/" + id);
    return response.data;
  },
  async submitPaymentV3(id, data) {
    const response = await Api().post(
      "payments/v3/user/transaction/" + id + "/pay",
      data
    );
    return response.data;
  },
  async updateCreditCardV3(id, data) {
    const response = await Api().post(
      "payments/v3/user/managedacademy/" + id + "/cc/update",
      data
    );
    return response.data;
  },
  async paymentsModuleData(id, paymentsModule) {
    const response = await Api().get(
      "payments/v3/user/managedacademy/" +
        id +
        "/payments-module/" +
        paymentsModule +
        "/data?source=social"
    );
    return response.data;
  },
};
