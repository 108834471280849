import Api from "@/services/MediaApi";

export default {
  async uploadVideo(formData, progress) {
    const response = await Api().post("vid", formData, {
      onUploadProgress: (uploadEvent) => {
        progress(uploadEvent);
      },
    });
    return response.data;
  },
  async uploadImage(formData, progress) {
    const response = await Api().post("img", formData, {
      onUploadProgress: (uploadEvent) => {
        progress(uploadEvent);
      },
    });
    return response.data;
  },
  async uploadFile(formData, progress) {
    const response = await Api().post("file", formData, {
      onUploadProgress: (uploadEvent) => {
        progress(uploadEvent);
      },
    });
    return response.data;
  },
};
