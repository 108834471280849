<template>
  <v-container grid-list-md class="pa-0">
    <div v-if="loading">
      <v-skeleton-loader
        type="card"
      ></v-skeleton-loader>
    </div>
    <v-sheet v-else-if="!loading && (isManager || addingNew)" :rounded="isSmallerDisplay() ? '' : 'lg'">
      <v-card-actions class="mb-0 pb-0">
        <input
          style="display: none;"
          type="file"
          @change="onVideoFileSelect"
          ref="videoFileUploadInput"
        />
        <v-btn
          small
          v-if="videoId == 'new_user_video'"
          style="margin-left: 5px;"
          @click="videoFilePicker()"
          color="primary"
        >
          <VideoCameraIcon class="hero-button"></VideoCameraIcon>
          {{ $t("message.upload_video") }}
        </v-btn>
      </v-card-actions>
      <v-card-text class="text-center">
        <v-responsive v-if="video.ready" class="pa-0">
          <VideoDisplay :skip-autoplay="true" :video="video"></VideoDisplay>
        </v-responsive>
        <div v-else-if="(videoId != 'new_user_video' && !video.ready) || uploading">
          <div class="pb-2">
            {{$t("message.video_processing")}}
          </div>
          <v-progress-circular
            class="mt-2"
            size="60"
            indeterminate
          ></v-progress-circular>
        </div>
        <span v-else-if="uploadingMessage">
          <CheckCircleIcon :color="themeColor('success')" v-if="uploadedVideoFile && videoId == 'new_user_video'"></CheckCircleIcon>
          {{ uploadingMessage }}
        </span>
      </v-card-text>
      <v-card-actions class="pa-0" v-if="uploadedVideoFile && videoId == 'new_user_video'">
        <v-spacer></v-spacer>
        <v-btn
          fab
          small
          color="error"
          @click="removeUploadedVideo()"
        >
          <TrashIcon class="hero-button"></TrashIcon>
        </v-btn>
      </v-card-actions>
      <v-card-text>
        <v-text-field
          outlined
          dense
          v-model="video.title"
          :label="$t('message.title')"
        ></v-text-field>
        <category-combo
          v-model="video.category"
          :allow-new="true"
          :label="$t('message.category')"
        />
        <jjtags-combo
          v-model="video.tags"
          :label="$t('message.video_tags') + ' (' + $t('message.optional') + ')'"
        />
        <DynamicInput 
          class="mb-2"
          :outlined="true" :edit="true" v-model="video.description">
        </DynamicInput>
        <links-chips
          class="mt-2 pt-2"
          ref="userTagsCombo"
          :user="$store.state.user._id"
          v-model="userTags"
        ></links-chips>
        <v-switch
          v-if="videoId == 'new_user_video'"
          v-model="acknowledgement"
          :label="$t('message.video_acknowledgement')"
        ></v-switch>
      </v-card-text>
      <v-card-actions>
        <v-btn
          v-if="videoId == 'new_user_video'"
          :disabled="
            !video.title ||
            !uploadedVideoFile ||
            !video.category ||
            !acknowledgement
          "
          @click="submitNewVideo()"
          color="primary"
        >
          {{ $t("message.add") }}
        </v-btn>
        <v-btn v-else @click="updateVideo()" color="primary">
          {{ $t("message.update") }}
        </v-btn>
        <v-btn @click="goBack()" color="secondary">
            {{ $t("message.back") }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          v-if="videoId != 'new_user_video'"
          @click="displayDeleteVideoConfirm()"
          color="error"
        >
          {{ $t("message.delete") }}
        </v-btn>
      </v-card-actions>
    </v-sheet>
    <div v-else>
      <IconLike size="200px" class="rotate" :color="themeColor('error')"></IconLike>
    </div>
    <v-dialog v-model="deleteVideoDialog" max-width="50%">
      <v-card outlined>
        <v-card-title>
          <div class="text-subtitle-1">{{ $t("message.delete_video") }}</div>
        </v-card-title>
        <v-card-text>
          <h4>{{ $t("message.remove_video_message") }}</h4>
        </v-card-text>
        <v-card-actions>
          <v-btn color="error" @click.stop="deleteVideo()">{{
            $t("message.delete")
          }}</v-btn>
          <v-btn @click.stop="deleteVideoDialog = false">{{
            $t("message.close")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import FileService2 from "@/services/FileService2";
import UserService from "@/services/UserService";
import VideoService from "@/services/VideoService";
import JjtagsCombo from "@/components/inputs/JiuJitsuTagCombo.vue";
import CategoryCombo from "@/components/inputs/VideoCategoryCombo.vue";
import LinksChips from "@/components/inputs/LinksChips.vue";
import IconLike from '@/components/displays/icons/IconLike.vue';
import VideoDisplay from "@/components/displays/VideoDisplay.vue";
import DynamicInput from "@/components/inputs/DynamicInput";

import { 
  CheckCircleIcon,
  VideoCameraIcon,
  TrashIcon
} from '@vue-hero-icons/outline'

export default {
  data() {
    return {
      addingNew: false,
      videoId: this.$route.params.videoId,
      loading: true,
      video: null,
      isManager: false,
      uploading: false,
      errorMessage: null,
      uploadingMessage: null,
      uploadedVideoFile: null,
      uploadingVideo: false,
      acknowledgement: false,
      deleteVideoDialog: false,
      bjjlinkVideoId: null,
      userTags: null,
      canLongerVideo: null,
    };
  },
  created() {
    //get events
    if (this.videoId == "new_user_video") {
      this.loading = false;
      this.addingNew = true;
      this.video = {
        user: this.$store.state.user._id,
      };
    } else {
      this.loading = true;
      this.video = this.getVideo();
    }
    UserService.user(this.$store.state.user._id)
      .then((data) => {
        if (data) {
          if (data.specialPermissions && data.specialPermissions.allowLongVideo)
            this.canLongerVideo = true;
          else this.canLongerVideo = false;
        }
      });
  },
  components: {
    JjtagsCombo,
    CategoryCombo,
    LinksChips,
    CheckCircleIcon,
    IconLike,
    VideoCameraIcon,
    TrashIcon,
    VideoDisplay,
    DynamicInput,
  },
  methods: {
    submitNewVideo() {
      const data = {
        _id: this.bjjlinkVideoId,
        title: this.video.title,
        description: this.video.description,
        category: this.video.category,
        tags: this.video.tags,
        userTags: this.userTags,
        fileUrl: this.uploadedVideoFile,
        acknowledgement: this.acknowledgement,
      };
      VideoService.addVideo(data)
        .then((res) => {
          this.clearParameters();
          this.navigateTo({
            name: "viewvideo",
            params: { videoId: res.video._id },
          });
        })
        .catch(() => {
          this.uploading = false;
          this.showAlert("error", this.$t("message.oops_not_working"));
        });
    },
    removeUploadedVideo() {
      this.uploadedVideoFile = null;
    },
    clearParameters() {
      this.uploadingMessage = null;
      this.video.description = null;
      this.uploadedVideoFile = null;
      this.uploadingVideo = false;
      this.video.tags = [];
      this.video.category = null;
      this.video.title = null;
      this.uploading = false;
      this.uploadingVideo = false;
      this.acknowledgement = false;
    },
    onVideoFileSelect(event) {
      if (event.target.files[0]) {
        const size = event.target.files[0].size;
        const sizeMb = (size / 1000000).toFixed(1);
        if (sizeMb > 300.0) {
          this.showAlert(
            "error",
            this.$t("message.video_too_big") + " (< 300 MB)"
          );
          return;
        } else {
          this.uploading = true;
          this.uploadingVideo = true;
          this.selectedFile = event.target.files[0];
          this.uploadedVideoFile = null;
          this.uploadedFile = null;
          this.uploadVideo();
        }
      } else {
        this.uploading = false;
      }
    },
    uploadVideo() {
      this.uploadingMessage = this.$t("message.video_uploading");
      const formData = new FormData();
      formData.append("video", this.selectedFile, this.selectedFile.name);
      if (this.canLongerVideo)
        formData.append("sp", "YWxsb3cgbG9uZyB2aWRlbw==");
      try {
        FileService2.uploadVideo(formData, function () {})
          .then((res) => {
            this.uploadedVideoFile = res.file;
            this.uploading = false;
            this.uploadingMessage = this.$t("message.video_processing_video");
            VideoService.initialize({
              fileUrl: res.file,
              thumbnail: res.thumbnail,
            })
              .then((vid) => {
                this.bjjlinkVideoId = vid.videoId;
              })
              .catch((err) => {
                this.uploading = false;
                const errorMessage =
                  err.response && err.response.data.message
                    ? this.$t("message." + err.response.data.message)
                    : this.$t("message.oops_not_working");
                this.clearParameters();
                this.showAlert("error", errorMessage);
              });
          })
          .catch((err) => {
            console.log(err);
            this.uploading = false;
            const errorMessage =
              err.response && err.response.data.message
                ? this.$t("message." + err.response.data.message)
                : this.$t("message.oops_not_working");
            this.clearParameters();
            console.log("We are here uploading video error!");
            this.showAlert("error", errorMessage);
          });
      } catch (err) {
        this.uploading = false;
        const errorMessage = this.$t("message.oops_not_working");
        this.clearParameters();
        console.log("We are here uploading video error!", err);
        this.showAlert("error", errorMessage);
      }
    },
    goBack() {
      this.$router.go(-1);
    },
    goodToProceed() {
      if (this.video && this.video.title && this.video.category) {
        if (this.videoId == "new_user_video") return false;
        else return true;
      } else {
        return false;
      }
    },
    getVideo() {
      VideoService.video(this.videoId)
        .then((data) => {
          if (data) {
            this.video = data.video;
            if (this.video.user._id == this.$store.state.user._id)
              this.isManager = true;
            this.loading = false;
            //this.uploadedVideoFile = this.video.fileUrl;
          }
        });
    },
    videoFilePicker() {
      this.$refs.videoFileUploadInput.click();
    },
    updateVideo() {
      if (this.goodToProceed()) {
        VideoService.updateVideo(this.video)
          .then((data) => {
            if (data) {
              this.showAlert("success", this.$t("message.updated"));
            }
          })
          .catch(() => {
            this.showAlert("error", this.$t("message.oops_not_working"));
          });
      }
    },
    displayDeleteVideoConfirm() {
      this.deleteVideoDialog = !this.deleteVideoDialog;
    },
    async deleteVideo() {
      const data = await VideoService.deleteVideo(this.videoId);
      if (data) {
        this.navigateTo({ name: "videos" });
      }
    },
  },
};
</script>

<style scoped>
.edit-image-button {
  margin-top: -80px;
}
.edit_box {
  height: 100%;
}
</style>
