<template>
  <v-container pa-0 v-if="posts && posts.length > 0">
    <template v-for="(p, index) in posts" class="pa-0 posts">
      <v-sheet
        :rounded="isSmallerDisplay() ? '' : 'lg'" 
        :key="index+'_'+p._id + '_post'"
        :post-id="p._id"
        class="pa-0 mt-2"
        :position="index"
        v-if="p.user && !p.user.hidden"
      >
        <v-card-title v-if="p">
          <partner-name v-if="p.partner && p.partner._id" :partner="p.partner">
          </partner-name>
          <user-name
            v-else
            :user="getRepostData(p).user"
            :hide-flag="true"
            size="30"
            :trunc="25"
          >
          </user-name>
          <span class="gray--text text-subtitle-2 ml-2 mt-1">•</span>
          <span class="gray--text text-subtitle-2 ml-2 mt-1">{{
            $d(new Date(getRepostData(p).time), "month_day_year", $i18n.locale)
          }}</span>
          <v-spacer></v-spacer>
          <country-flag
            v-if="getRepostData(p).user.nationality"
            :countryCode="getRepostData(p).user.nationality"
          ></country-flag>
        </v-card-title>
        <v-card-text class="post_contents message_area text-left pa-2" v-if="showPostContents(getRepostData(p))">
          <dynamic-links
            v-if="getRepostData(p).contentsTranslation && p.translationSubs[0]"
            :text="
              $t(
                'message.' + p.contentsTranslation,
                getRepostData(p).extraData && getRepostData(p).extraData.belt
                  ? [$t('message.' + p.translationSubs[0])]
                  : p.translationSubs
              )
            "
          ></dynamic-links>
          <dynamic-links
            v-else
            :text="getRepostData(p).contents"
          ></dynamic-links>
        </v-card-text>
        <v-responsive v-if="getRepostData(p).image" class="post_image">
          <ImageDisplay style="width: 100%;" :src="getRepostData(p).image"></ImageDisplay>
        </v-responsive>
        <v-responsive
          style="margin-top: -20px"
          v-else-if="
            getRepostData(p).extraData && getRepostData(p).extraData.belt
          "
        >
          <v-card-text class="text-center">
            <v-icon
              size="100"
              :color="themeColor(getRepostData(p).extraData.belt)"
            >mdi-check-decagram
            </v-icon>
          </v-card-text>
        </v-responsive>
        <v-responsive
          v-else-if="
            getRepostData(p).extraData && getRepostData(p).extraData.masterId
          "
        >
          <v-card-title>
            <LineageDisplay
              class="text-body-1"
              :icon="true"
              :master="getRepostData(p).extraData.masterName"
              :user-id="getRepostData(p).user.id"
              :master-id="getRepostData(p).extraData.masterId"
              :user="getRepostData(p).user.fName"
            />
          </v-card-title>
        </v-responsive>
        <div 
          v-else-if="getRepostData(p).extraData && getRepostData(p).extraData.medal"
          class="text-center pa-2">
          <IconCompMedal size="large" v-if="getRepostData(p).extraData.medal == 1" :color="themeColor('gold')"></IconCompMedal>
          <IconCompMedal size="large" v-else-if="getRepostData(p).extraData.medal == 2" :color="themeColor('silver')"></IconCompMedal>
          <IconCompMedal size="large" v-else-if="getRepostData(p).extraData.medal == 3" :color="themeColor('bronze')"></IconCompMedal>
          <IconCompMedal size="large" v-else :color="themeColor('primary')"></IconCompMedal>
        </div>
        <div
          :style="getSpecialPostStyle(getRepostData(p))"
          v-else-if="
            getRepostData(p).joinedEvent
          "
          @click="goToEvent(getRepostData(p).joinedEvent._id)"
          class="text-center"
        >
          <v-card-text class="pa-2 text-subtitle-1 font-weight-bold">
            {{getRepostData(p).joinedEvent.title}}<CalendarIcon class="hero-icon" size="20"></CalendarIcon>
          </v-card-text>
          <div class="pa-1">
            <v-avatar v-if="getRepostData(p).joinedEvent.image" size="350" class="grey lighten-4">
              <img
                style="width: 100%; max-width: 550px"
                :src="userFile(getRepostData(p).joinedEvent.image)"
              />
            </v-avatar>
          </div>
        </div>
        <v-responsive 
          v-else-if="p.postType == 'member_shoutout'"
        >
          <v-card-text class="pa-2 text-center">
            <div class="text-subtitle-1">{{$t('message.post_member_shoutout')}}
              <v-icon size="20">mdi-bullhorn-variant-outline</v-icon>
            </div>
            <div class="text-h5 primary--text" @click="goToUser(p.extraData.memberId)" v-html="p.extraData.memberName"></div>
          </v-card-text>
          <div class="pa-0 text-center pb-2">
            <v-avatar v-if="p.extraData.memberImage" size="300" class="grey lighten-4">
              <img
                style="width: 100%; max-width: 450px"
                :src="userFile(p.extraData.memberImage)"
              />
            </v-avatar>
          </div>
          <v-card-text class="pa-1 text-center" v-if="p.contents" v-html="p.contents">
          </v-card-text>
        </v-responsive>
        <v-responsive
          v-else-if="
            getRepostData(p).videoObj && getRepostData(p).videoObj.ready
          "
          class="pa-0"
        >
          <video-display :video="getRepostData(p).videoObj"></video-display>
        </v-responsive>
        <div
          v-else-if="getRepostData(p).videoId"
          class="yt-video-wrapper text-center"
          style="text-align: center"
        >
          <iframe
            width="100%"
            :src="'https://www.youtube.com/embed/' + getRepostData(p).videoId"
            frameborder="0"
            allow="autoplay; encrypted-media"
            allowfullscreen
          >
          </iframe>
        </div>
        <div v-else-if="getRepostData(p).link">
          <FetchedUrl :url="getRepostData(p).link" />
        </div>
        <div v-if="p.postType == 'aggregated_checkins'" class="pa-0">
          <div v-if="p.users && p.users.length > 1" class="post_contents message_area text-left">
            <dynamic-links
              v-if="p.users.length == 2"
              :text="$t('message.post_checkins_two_users',[p.translationSubs[0]])"
              :display-belt="true"
              :belt="p.user.belt"
            ></dynamic-links>
            <dynamic-links
              v-else
              :text="$t('message.post_checkins_multiple_users',[(p.users.length - 1), p.translationSubs[0]])"
              :display-belt="true"
              :belt="p.user.belt"
            ></dynamic-links>
          </div>
          <div v-else class="post_contents message_area text-xs-left">
            <dynamic-links
            v-if="p.contentsTranslation && p.translationSubs[0]"
            :text="
              $t(
                'message.' + p.contentsTranslation,
                p.extraData && p.extraData.belt
                  ? [$t('message.' + p.translationSubs[0])]
                  : p.translationSubs
              )
            "
            :display-belt="true"
            :belt="p.user.belt"
          ></dynamic-links>
          </div>
          <map-image
            v-if="p.location.source != 'post'"
            :latitude="p.location.latitude"
            :longitude="p.location.longitude"
            :show-marker="true"
          >
          </map-image>
          <v-card
            elevation="0"
            v-if="
              p.location.title && p.location.url
            "
            grid-list-md
            pa-0
            class="text-xs-left"
          >
            <v-list-item three-line>
              <v-list-item-avatar
                item
                size="80"
              >
                <v-avatar size="80" @click="goToAcademy(p.location.academyId, p.location.url)">
                  <v-img v-if="p.location.image" :src="userFile(p.location.image)"/>
                  <LocationMarkerIcon class="hero-icon" v-else></LocationMarkerIcon>
                </v-avatar>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="text-h5 mb-1 text-uppercase">
                  <span v-html="truncate(p.location.title, 30)"></span>
                  <academy-stars class="mt=0 pt-0" size="small" :rating="null" 
                    :academyId="p.location.academyId"/>
                </v-list-item-title>
                <v-list-item-subtitle 
                  v-if="p.location.description"
                  v-html="truncate(p.location.description, 150)">
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-card-actions v-if="p.location.academyId">
              <v-spacer></v-spacer>
              <v-btn 
                small
                block
                text
                outlined
                color="primary" 
                @click="goToAcademy(p.location.academyId, p.location.url)">
                {{ $t("message.view_academy_page") }}
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </div>
        <div v-else-if="getRepostData(p).location" class="pa-2">
          <map-image
            v-if="getRepostData(p).location.source != 'post'"
            :latitude="getRepostData(p).location.latitude"
            :longitude="getRepostData(p).location.longitude"
            :show-marker="true"
          >
          </map-image>
          <v-card
            elevation="0"
            v-if="getRepostData(p).location.title && getRepostData(p).location.url"
          >
            <v-list-item three-line>
              <v-list-item-avatar
                item
                size="80"
              >
                <v-avatar size="80" @click="goToAcademy(getRepostData(p).location.academyId, getRepostData(p).location.url)">
                  <v-img v-if="getRepostData(p).location.image" :src="userFile(getRepostData(p).location.image)"/>
                  <LocationMarkerIcon class="hero-icon" v-else></LocationMarkerIcon>
                </v-avatar>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title 
                  @click="goToAcademy(getRepostData(p).location.academyId, getRepostData(p).location.url)"
                  class="text-h5 mb-1 text-uppercase">
                  <span v-html="truncate(getRepostData(p).location.title, 45)"></span>
                  <academy-stars class="mt=0 pt-0" size="small" :rating="null" 
                    :academyId="getRepostData(p).location.academyId"/>
                </v-list-item-title>
                <v-list-item-subtitle 
                  v-if="getRepostData(p).location.description"
                  v-html="truncate(getRepostData(p).location.description, 150)">
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-card-actions v-if="getRepostData(p).location.academyId">
              <v-spacer></v-spacer>
              <v-btn 
                small
                block
                text
                outlined
                color="primary" 
                @click="goToAcademy(getRepostData(p).location.academyId, getRepostData(p).location.url)">
                {{ $t("message.view_academy_page") }}
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </div>
        <v-responsive
          v-if="
            p.poll
          "
          class="pa-0"
        >
          <PollDisplay :moderator="p.moderator" :poll="p.poll"></PollDisplay>
        </v-responsive>
        <v-divider v-if="displayPostActions(p)"></v-divider>
        <v-card-actions v-if="displayPostActions(p)" class="pa-2">
          <reactions-button :post="p"></reactions-button>
          <v-btn text outlined small class="elevation-0 ml-1" @click="showComments(p)">
            <ChatAltIcon size="20" class="hero-button" :color="themeColor('gray')"></ChatAltIcon>
          </v-btn>
          <v-btn text outlined small class="elevation-0 ml-1" v-if="false" @click="openRepostDialog(p)">
            <ShareIcon size="20" :color="themeColor('gray')" class="hero-button"></ShareIcon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-menu v-if="p.user._id == $store.state.user._id">
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="elevation-0" style="margin-left:15px" icon v-bind="attrs" v-on="on">
                <DotsVerticalIcon color="gray" class="hero-button"></DotsVerticalIcon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item v-if="!p.internal" @click.stop="displayUpdatePostDialog(p)">
                <v-list-item-title>{{ $t("message.edit") }}</v-list-item-title>
              </v-list-item>
              <v-list-item @click.stop="displayDeletePostConfirm(p)">
                <v-list-item-title>{{$t("message.delete")}}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-menu v-else-if="p.moderator">
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="elevation-0" style="margin-left:15px" icon v-bind="attrs" v-on="on">
                <v-icon>mdi-trash-can-outline</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click.stop="displayDeletePostConfirm(p)">
                <v-list-item-title>{{$t("message.delete")}}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-card-actions>
        <v-card-actions v-if="displayPostActions(p)" class="pl-2 pr-2 text-caption">
          <OssReactionsCount :post="p"></OssReactionsCount>
          <span class="ml-2 mr-2">•</span>
          <span
            class="clickable text-lowercase"
            @click="showComments(p)">
            {{ p.commentsCount ? p.commentsCount : 0 }}
            {{p.commentsCount && p.commentsCount == 1 ? $t("message.comments") : $t("message.comment") }}
          </span>
        </v-card-actions>
        <v-card-actions
          v-if="p.userTags && p.userTags.length > 0"
          class="pa-2 text-xs-left"
        >
          <user-avatars :users="p.userTags" />
        </v-card-actions>
        <div 
          class="pa-0"
          :position="index"
          v-if="posts && posts.length > 4 && (posts.length-3 == index)"
          v-intersect="visibilityChanged">
        </div>
      </v-sheet>
    </template>
    <v-dialog v-model="deletePostDialog" max-width="500px">
      <v-card outlined>
        <v-card-title class="text-h6">
          {{ $t("message.sure_remove_post") }}
        </v-card-title>
        <v-card-actions>
          <v-btn small class="elevation-0" color="error" @click.stop="deletePost()">{{
            $t("message.delete")
          }}</v-btn>
          <v-btn small class="elevation-0" color="secondary" @click.stop="deletePostDialog = false">{{
            $t("message.close")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog 
      v-model="updatePostDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar dark color="dark">
          <v-toolbar-title>
            {{ $t("message.edit_post") }}
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-flex>
            <DynamicInput 
              :outlined="true"
              :edit="true"
              v-model="updatedContents"
              class="mt-2"
            ></DynamicInput>
          </v-flex>
        </v-card-text>
        <v-footer app fixed>
          <v-card-actions style="margin-bottom: 10px">
            <v-btn small elevation="0" color="primary" @click.stop="updatePost()">{{
              $t("message.edit")
            }}</v-btn>
            <v-btn small elevation="0" color="secondary" @click.stop="updatePostDialog = false;updatedContents=null;">{{
              $t("message.close")
            }}</v-btn>
          </v-card-actions>
        </v-footer>
      </v-card>
    </v-dialog>
    <v-dialog 
      v-model="commentsDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition">
      <v-card v-if="selectedPost">
        <v-toolbar style="flex:none">
          <v-btn 
            v-if="replyingComment"
            @click="cancelReplyComment()" 
            icon small>
            <XCircleIcon class="hero-button"></XCircleIcon>
          </v-btn>
          <current-user-avatar 
            class="mr-1"
            v-else
          ></current-user-avatar>
          <dynamic-input
            v-model="postCommentContents['comment_' + selectedPost._id]"
            @keyup.enter.native="addComment(selectedPost._id)"
            :label="commentTextareaLabel"
            style="width:100%"
            :height="'40px;'"
          ></dynamic-input>
          <v-spacer></v-spacer>
          <v-btn class="elevation-0 ml-1"
            color="primary"
            fab
            small
            @click="addComment(selectedPost._id)"
          >
            <ReplyIcon v-if="replyingComment" class="hero-button"></ReplyIcon>
            <ChatAltIcon v-else class="hero-button"></ChatAltIcon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container v-if="selectedPost" class="pa-2" grid-list-lg fluid text-xs-center>
            <v-skeleton-loader
              v-if="loadingComments"
              type="list-item-avatar-three-line"
            ></v-skeleton-loader>
            <div class="supporting-text" v-if="!selectedPost.comments || selectedPost.comments.length == 0">{{$t("message.no_comments")}}</div>
            <template v-for="(c, index) in selectedPost.comments">
              <div
                v-if="c && c.user"
                :key="index + c._id + '_comment'"
                class="comment_section"
                :style="highlight && highlight == c._id ? highlightedComment : ''" 
              >
                    <input type="text" v-if="showInput" :ref="'focus_' + c._id" />
                    <v-card-title class="pa-1">
                      <user-name
                          :user="c.user"
                          :addHover="false"
                          class="post_user"
                          :trunc="25" 
                          :size="'30px'"
                        >
                      </user-name>
                      <v-spacer></v-spacer>
                      <span
                          class="text-left text-subtitle-2 hidden-sm-and-down"
                          >{{
                            $d(new Date(c.time), "month_day_year", $i18n.locale)
                          }}</span
                        >
                        <span
                          class="text-left text-subtitle-2 hidden-md-and-up"
                          >{{
                            $d(new Date(c.time), "month_year", $i18n.locale)
                          }}</span
                        >
                    </v-card-title>
                    <v-card-text :class="'text-left comment_message_area pa-1 ' + (isDarkTheme() ? 'grey darken-4' : 'grey lighten-4')">
                      <dynamic-links
                        :text="c.contents"
                        :length="200"
                      ></dynamic-links>
                    </v-card-text>
                    <v-card-actions class="pa-0">
                      <like-comment
                        :like="likeComment"
                        :unlike="unlikeComment"
                        :view="viewCommentLikes"
                        :obj="c"
                        size="20"
                      ></like-comment>
                      <span class="ml-2 mr-2">•</span>
                      <span class="text-caption">{{c.repliesCount ? c.repliesCount : 0}} {{$t("message.replies")}}</span>
                      <v-spacer></v-spacer>
                      <v-menu
                        style="margin-right: 16px"
                        offset-y
                        v-if="c.user && c.user._id == $store.state.user._id"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn class="elevation-0" icon small v-bind="attrs" v-on="on">
                            <DotsVerticalIcon size="20" class="hero-button"></DotsVerticalIcon>
                          </v-btn>
                        </template>
                        <v-list>
                          <v-list-item
                            @click.stop="displayUpdateCommentDialog(c)"
                          >
                            <v-list-item-title>{{
                              $t("message.edit")
                            }}</v-list-item-title>
                          </v-list-item>
                          <v-list-item
                            @click.stop="displayDeleteCommentConfirm(c, selectedPost)"
                          >
                            <v-list-item-title>{{
                              $t("message.delete")
                            }}</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                      <v-btn small icon right @click="displayReplyComment(c)">
                        <ReplyIcon size="20" class="hero-icon"></ReplyIcon>
                      </v-btn>
                    </v-card-actions>
                    <v-card-text v-if="c.repliesCount" class="replies-box text-left" style="margin-bottom:5px">
                      <template v-for="(r, index2) in c.replies">
                        <v-flex :key="index2 + r._id + '_comment_reply'" class="mb-1">
                          <v-card-title class="pa-1" style="margin-bottom:-10px">
                            <user-name
                              :user="r.user"
                              :addHover="false"
                              :trunc="25" 
                              :size="'15px'"
                              :hide-flag="true"
                            >
                            </user-name>
                          </v-card-title>
                          <div 
                            :style="reply && reply == r._id ? 'border-left:2px solid ' + themeColor('primary') : ''" 
                            :class="'text-left pa-1 '+(isDarkTheme() ? 'grey darken-4' : 'grey lighten-4')">
                            <dynamic-links :text="r.contents" :length="200"></dynamic-links>
                          </div>
                          <div class="text-right">
                            <span class="text-caption mr-2">
                              {{$d(new Date(r.time), "month_year", $i18n.locale)}}
                            </span>
                            <span class="text-caption" v-if="r.user._id == $store.state.user._id" small icon @click="displayDeleteReplyConfirm(r, c, index2)">
                              {{$t("message.delete").toLowerCase()}}
                            </span>
                          </div>
                        </v-flex>
                      </template>
                    </v-card-text>
                    <v-card-actions v-if="c.replies && c.repliesCount > c.replies.length">
                      <v-spacer></v-spacer>
                      <v-btn color="primary" x-small text @click="viewMoreReplies(c)">{{$t("message.view_more").toLowerCase()}}</v-btn>
                      <v-spacer></v-spacer>
                    </v-card-actions>
                </div>
            </template>
          </v-container>
        </v-card-text>
        <v-footer app fixed dark>
          <v-layout>
            <v-spacer></v-spacer>
            <v-btn small icon @click="hideComments()">
              <XCircleIcon class="hero-button"></XCircleIcon>
            </v-btn>
          </v-layout>
        </v-footer>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteCommentDialog" max-width="500px">
      <v-card outlined>
        <v-card-title>
          <h3>{{ $t("message.sure_remove_comment") }}</h3>
        </v-card-title>
        <v-card-actions>
          <v-btn class="elevation-0" color="error" @click.stop="deleteComment()">{{
            $t("message.delete")
          }}</v-btn>
          <v-btn class="elevation-0" @click.stop="deleteCommentDialog = false">{{
            $t("message.close")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog 
      v-model="updateCommentDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar dark color="dark">
          <v-toolbar-title>
            {{ $t("message.edit_comment") }}
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-flex>
            <dynamic-input 
              :edit="true"
              v-model="updatedContents"
            ></dynamic-input>
          </v-flex>
        </v-card-text>
        <v-footer app fixed>
          <v-card-actions style="margin-bottom: 10px">
            <v-btn class="elevation-0" color="primary" @click.stop="updateComment()">{{
              $t("message.edit")
            }}</v-btn>
            <v-btn class="elevation-0" color="secondary" @click.stop="updateCommentDialog = false">{{
              $t("message.close")
            }}</v-btn>
          </v-card-actions>
        </v-footer>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import PostService from "@/services/PostService";
import VideoService from "@/services/VideoService";
import UserName from "@/components/displays/UserNameDisplay.vue";
import PartnerName from "@/components/displays/PartnerName.vue";
import ReactionsButton from "@/components/inputs/OssReactionsButton.vue";
import CurrentUserAvatar from "@/components/displays/CurrentUserAvatarDisplay.vue";
import UserAvatars from "@/components/displays/UserAvatars.vue";
import DynamicLinks from "@/components/displays/DynamicLinks.vue";
import FetchedUrl from "@/components/displays/FetchedUrlDisplay.vue";
import MapImage from "@/components/displays/StaticMapDisplay.vue";
import LineageDisplay from "@/components/displays/LineageDisplay.vue";
import VideoDisplay from "@/components/displays/VideoDisplay.vue";
import ImageDisplay from "@/components/displays/ImageDisplay.vue";
import PollDisplay from "@/components/displays/PollDisplay.vue";
import LikeComment from "@/components/inputs/GenericLikeButton.vue";
import DynamicInput from "@/components/inputs/DynamicInput";
import AcademyStars from "@/components/displays/AcademyStarsDisplay.vue";
import OssReactionsCount from "@/components/displays/OssReactionsCount.vue";
import IconCompMedal from "@/components/displays/icons/IconCompMedal";
import CountryFlag from "@/components/displays/CountryFlagDisplay.vue";

import { 
  DotsVerticalIcon,
  ReplyIcon,
  CalendarIcon,
  ChatAltIcon,
  ShareIcon,
  XCircleIcon,
  LocationMarkerIcon,
} from '@vue-hero-icons/outline'

export default {
  props: ["posts", "addHovers", "newsFeed", "hideRepost", "single", "highlight","reply"],
  data() {
    return {
      deletePostDialog: false,
      repost: null,
      currentCount: 0,
      lastCount: 0,
      showInput: true,
      updatePostDialog: false,
      deleteCommentDialog: false,
      updateCommentDialog: false,
      selectedPost: null,
      selectedComment: null,
      updatedContents: null,
      showActionsByIndex: null,
      showByPostId: null,
      currentComments: null,
      postCommentContents: {},
      addingComment: false,
      commentsDialog: false,
      commentTextareaLabel: this.$t('message.new_comment_label'),
      replyingComment: null,
      hideRepostButton: false,
      loadingComments: false,
      targetComment: null,
      highlightedComment: 'border-radius: 5px;border: 2px solid rgba(27, 117, 188, .3); transition: all 3s ease;',
      highlightedReply: 'border-radius: 5px;border: 2px solid rgba(27, 117, 188, .3); transition: all 3s ease;',
    };
  },
  components: {
    UserName,
    CurrentUserAvatar,
    UserAvatars,
    DynamicLinks,
    FetchedUrl,
    MapImage,
    ReactionsButton,
    PartnerName,
    LineageDisplay,
    VideoDisplay,
    ImageDisplay,
    LikeComment,
    DynamicInput,
    AcademyStars,
    PollDisplay,
    OssReactionsCount,
    DotsVerticalIcon,
    ReplyIcon,
    CalendarIcon,
    ChatAltIcon,
    ShareIcon,
    XCircleIcon,
    LocationMarkerIcon,
    IconCompMedal,
    CountryFlag
  },
  created() {
    if (this.hideRepost) this.hideRepostButton = true;
    if(this.highlight && this.posts && this.posts.length == 1){
      this.showComments(this.posts[0]);
    }
  },
  mounted(){
    this.mountedCompleted = true;
  },
  methods: {
    getPostedDays(postDay) {
      const today = new Date();
      const daysBetween = this.daysBetween(postDay, today);
      let metric = this.$t("message.days");
      return daysBetween + ' ' + metric + ' ago';
    },
    isReplyVisible(){
      if(this.selectedPost && this.selectedPost.comments && this.selectedPost.comments.length > 0){
        for(const c of this.selectedPost.comments){
          if(c._id == this.highlight){
            this.targetComment = c;
            if(this.reply){
              const replies = c.replies;
              if(replies && replies.length > 0){
                for(const r of replies){
                  if(r._id == this.reply){
                    return true;
                  }
                }
              }
            }
          }
        }
      }
      return false;
    },
    async viewMoreReplies(c){
      const index = c.replies ? c.replies.length : 0;
      const data = await PostService.getReplies(c._id, index);
      if(data){
        if(data.count > 0){
          if(!this.reply){
            c.replies = c.replies.concat(data.replies);
          }else{
            for(const r of data.replies){
              if(r._id != this.reply)
                c.replies.push(r);
            }
          }
        }
      }
    },
    displayReplyComment(c){
      this.replyingComment = c;
      if(c.user){
        if(c.user._id.toString() == this.$store.state.user._id.toString())
          this.commentTextareaLabel = this.$t('message.replying_to')+': '+this.$t('message.you');
        else
          this.commentTextareaLabel = this.$t('message.replying_to')+': '+this.truncate(c.user.displayName, 20);
      }
    },
    cancelReplyComment(){
      this.replyingComment = null;
      this.commentTextareaLabel = this.$t('message.new_comment_label');
    },
    displayPostActions(p){
      if(p && p.postType == 'aggregated_checkins' && p.users && p.users.length > 1){
        return false
      }
      return true;
    },
    postSeen(postId) {
      console.log('POST SEEN: ', postId);
      //Call patch to add user to list of seen
      //This means we should not return this seenBy all the time
    },
    visibilityChanged(entries){
      if(entries[0]){
        const position = entries[0].target.getAttribute('position');
        if(this.currentCount >= 2 && this.lastCount != position){
          //console.log('Loading posts. Position: ', position);
          this.$emit('load_more');
          this.lastCount = position;
          this.currentCount = 0;
        }
        this.currentCount++;
      }
    },
    async likeComment(id, whenDone){
      const data = await PostService.likeComment(id);
      if (data) {
        whenDone();
      } else {
        this.showAlert("error", this.$t("message.oops_not_working"));
      }
    },
    async unlikeComment(id, whenDone){
      const data = await PostService.unlikeComment(id);
      if (data) {
        whenDone();
      } else {
        this.showAlert("error", this.$t("message.oops_not_working"));
      }
    },
    async viewCommentLikes(id, whenDone){
      const data = await PostService.viewCommentLikes(id);
      if (data) {
        whenDone(data);
      }
    },
    videoPlayed(vid) {
      VideoService.viewVideo(vid);
    },
    getRepostData(p) {
      return p;
      /*
      if (p.repost) {
        return p.repost;
      } else {
        return p;
      }
      */
    },
    openRepostDialog(post) {
      this.repost = post;
      this.$emit("event_repost", this.repost);
    },
    getVideoHeightStyle() {
      if (this.isSmallerDisplay()) return "padding-bottom: 70.25%;";
      else return "padding-bottom: 30.25%;";
    },
    goToLocation(url) {
      window.location = url;
    },
    showPostContents(p){
      if(p && p.postType && (p.postType == 'member_shoutout' || p.postType == 'aggregated_checkins'))
        return false;
      return true;
    },
    getSpecialPostStyle(p){
      if(p && p.postType && p.postType == 'member_shoutout')
        return "background-color: "+this.themeColor('blue')+';';
      return "";
    },
    displayUpdatePostDialog(p) {
      this.selectedPost = p;
      this.updatedContents = p.contents;
      this.updatePostDialog = true;
    },
    displayDeletePostConfirm(p) {
      this.selectedPost = p;
      this.deletePostDialog = !this.deletePostDialog;
    },
    displayUpdateCommentDialog(c) {
      this.selectedComment = c;
      this.updatedContents = c.contents;
      this.updateCommentDialog = true;
    },
    displayDeleteCommentConfirm(c, p) {
      this.selectedComment = c;
      this.selectedPost = p;
      this.deleteCommentDialog = !this.deleteCommentDialog;
    },
    displayDeleteReplyConfirm(reply, comment, index){
      if(reply){
        PostService.deleteRepliedMessage(reply._id)
          .then(data => {
            if(data){
              const currentCount = comment.repliesCount;
              comment.repliesCount = currentCount > 0 ? (currentCount - 1) : 0;
              comment.replies.splice(index, 1);
            }
          })
          .catch(() => {
            this.showAlert(
              "error",
              this.$t('message.oops_not_working')
            );
          });
      }
    },
    hideComments(){
      this.showByPostId = null;
      this.selectedPost = null;
      this.commentsDialog = false;
      this.loadingComments = false;
      this.cancelReplyComment();
    },
    async showComments(post) {
      const postId = post._id;
      this.selectedPost = post;
      if (post && this.showByPostId != null && this.showByPostId == postId) {
        //Clicking same one, hide again
        this.showByPostId = null;
        this.selectedPost = null;
      } else {
        this.loadingComments = true;
        this.commentsDialog = true;
        this.showByPostId = postId;
        //Get the actual comments
        const data = await PostService.getComments(post._id);
        this.selectedPost.comments = data;
        if(this.highlight && this.reply && data && data.length > 0){
          const isReplyVisible = this.isReplyVisible();
          if(!isReplyVisible){
            const reply = await PostService.getReply(this.highlight, this.reply);
            if(reply && this.targetComment && this.targetComment.replies){
              this.targetComment.replies.push(reply.reply);
            }
          }
        }
        const vm = this;
        this.loadingComments = false;
        if(this.highlight){
          setTimeout(function(){
            const theComment = vm.$refs['focus_'+vm.highlight][0];
            if(theComment){
              //theComment.focus();
              vm.$vuetify.goTo(theComment, {})
              vm.showInput = false;
              theComment.focus();
            }
          }, 1000);
        }else{
          this.showInput = false;
        }
      }
    },
    async addComment(postId) {
      if (!this.addingComment) {
        this.addingComment = true;
        let contents = this.postCommentContents["comment_" + postId];
        contents = contents.trim();
        if (!contents || contents == "" || contents == '<div><br></div><div><br></div>') {
          this.addingComment = false;
          return;
        }
        let hashTags = this.extractHashTags(contents);
        contents = contents.replaceAll('<div><br></div>','');
        let data;
        if(this.replyingComment){
          data = await PostService.replyComment(postId, this.replyingComment._id, {
            contents: contents,
            time: this.getNowDate(),
            hashTags: hashTags,
          });
        }else{
          data = await PostService.addComment(postId, {
            contents: contents,
            time: this.getNowDate(),
            hashTags: hashTags,
          });
        }
        if (data) {
          this.addingComment = false;
          //Comment Added
          this.postCommentContents["comment_" + postId] = "";
          //data.comment.user = data.comment.user;
          if (this.selectedPost) {
            if(this.replyingComment){
              let repliesCount = this.replyingComment.repliesCount;
              if(isNaN(repliesCount))
                this.replyingComment.repliesCount = 0;
              if(this.replyingComment.repliesCount == 0){
                this.replyingComment.replies = [];
                this.replyingComment.replies.push(data.comment)
              }else{
                this.replyingComment.replies.unshift(data.comment);
              }
              this.replyingComment.repliesCount = this.replyingComment.repliesCount + 1;
              this.cancelReplyComment();
              //TODO
            }else{
              if (!this.selectedPost.comments) {
                this.selectedPost.comments = [];
                this.selectedPost.comments.push(data.comment);
              } else {
                this.selectedPost.comments.unshift(data.comment);
              }
            }
          }
        } else {
          this.showAlert("error", this.$t("message.oops_not_working"));
        }
      }
    },
    async deletePost() {
      this.deletePostDialog = false;
      try {
        const data = await PostService.deletePost(this.selectedPost._id);
        if (data) {
          //Deleted!
          var i;
          for (i = 0; i < this.posts.length; i++) {
            const p = this.posts[i];
            if (p._id == this.selectedPost._id) {
              break;
            }
          }
          this.posts.splice(i, 1);
        }
        this.selectedPost = null;
      } catch (err) {
        this.showAlert("error", this.$t("message.oops_not_working"));
      }
    },
    async updatePost() {
      this.updatePostDialog = false;
      const data = await PostService.updatePost(
        this.selectedPost._id,
        this.updatedContents
      );
      if (data) {
        //updated!
        var i;
        for (i = 0; i < this.posts.length; i++) {
          const p = this.posts[i];
          if (p._id == this.selectedPost._id) {
            p.contents = this.updatedContents;
          }
        }
        this.updatedContents = "";
        this.selectedPost = null;
      }
    },
    async deleteComment() {
      this.deleteCommentDialog = false;
      const data = await PostService.deleteComment(this.selectedComment._id);
      const comments = this.selectedPost.comments;
      if (data) {
        //Deleted!
        var i;
        for (i = 0; i < comments.length; i++) {
          const c = comments[i];
          if (c._id == this.selectedComment._id) {
            break;
          }
        }
        this.selectedPost.comments.splice(i, 1);
      }
      this.selectedComment = null;
      this.selectedPost = null;
    },
    async updateComment() {
      this.updateCommentDialog = false;
      const data = await PostService.updateComment(
        this.selectedComment._id,
        this.updatedContents
      );
      //const comments = this.selectedPost.comments;
      if (data) {
        this.selectedComment.contents = this.updatedContents;
        this.updatedContents = "";
        this.selectedComment = null;
      }
    },
  },
  computed: {},
};
</script>

<style scoped>
.post_contents {
  padding: 5px;
  font-size: .9375rem;
  white-space: pre-wrap;
  word-wrap: break-word;
}
.yt-video-wrapper {
  position: relative;
  position: relative;
  padding-bottom: 56.25%; /* - 16:9 aspect ratio (most common) */
  /* padding-bottom: 62.5%; - 16:10 aspect ratio */
  /* padding-bottom: 75%; - 4:3 aspect ratio */
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}

.yt-video-wrapper iframe,
.yt-video-wrapper object,
.yt-video-wrapper embed {
    border: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.comment_section {
  margin-bottom: 15px;
}

.message_area {
  font-size: 1.0em;
}


.replies-box{
  border-left: 1px solid gray;
  padding-left: 10px;
  padding-top: 1px;
  padding-bottom: 1px;
  margin-left:15px;
}
</style>
