import Vue from "vue";
import App from "./App.vue";
import { sync } from "vuex-router-sync";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueI18n from "vue-i18n";
import * as VueGoogleMaps from "vue2-google-maps";
import VueSweetAlert2 from "vue-sweetalert2";
import Croppa from "vue-croppa";

//Flowchart
import FlowchartNodeView from "@/components/displays/FlowchartNodeView";
import FlowyPlugin from "@hipsjs/flowy-vue";

//CSS
import "vue-croppa/dist/vue-croppa.css";
import "sweetalert2/dist/sweetalert2.min.css";
import "@/assets/styles/main.css";
import "@hipsjs/flowy-vue/dist/lib/flowy-vue.css";

//Internal Services
import MessageService from "@/services/MessageService";
import NotificationService from "@/services/NotificationService";
import UserService from "@/services/UserService";
import AdminService from "@/services/AdminService";
import SuggestionsService from "@/services/SuggestionsService";
import NewsService from "@/services/NewsService";
import AuthenticationService from "@/services/AuthenticationService";

//Data
import countriesJson from "@/assets/data/countries.json";

Vue.config.productionTip = false;

const moment = require("moment");

//Google maps
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyDS7qZboGXsPMwSUxk35eRX7pDu26mU-MI",
    libraries: "places", // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)

    //// If you want to set the version, you can do so:
    // v: '3.26',
  },
  //// If you intend to programmatically custom event listener code
  //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  //// you might need to turn this on.
  // autobindAllEvents: false,

  //// If you want to manually install components, e.g.
  //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
  //// Vue.component('GmapMarker', GmapMarker)
  //// then disable the following:
  // installComponents: true,
});

//Keep store and router in sync
sync(store, router);

const ASSETS_URL = process.env.VUE_APP_ASSETS_URL;
let lastMeRefresh = null;
let appPanels = {};

Vue.mixin({
  data() {
    return {
      searchesLimit: 20,
      fromRegistration: false,
      browserLang: this.getBrowserLang(),
      validationRules: {
        number: (value) => {
          const pattern = /^\d+$/;
          return pattern.test(value) || this.$t("message.not_a_number");
        },
      },
      notificationTypes: {
        NEWEVENTPARTICIPANT: "new_event_participant", //EVENT
        NEWEVENTMANAGER: "new_event_managers", //EVENT
        EVENTINVITE: "event_invite", //EVENT
        EVENTINVITEACCEPTED: "event_invite_accepted", //EVENT
        NEWLINK: "new_link", //LINK
        NEWACADEMYMEMBER: "new_academy_member", //ACADEMY
        NEWACADEMYMANAGER: "new_academy_manager", //ACADEMY
        ACADEMYINVITE: "academy_invite", //ACADEMY
        POSTLIKED: "post_liked", //LIKE
        ACADEMYLIKED: "academy_liked", //LIKE
        ACADEMYREVIEWED: "academy_reviewed", //LIKE
        ACADEMYMESSAGE: "academy_message", //Academy message
        POSTTAG: "post_tag", //TAG
        COMMENTLIKED: "comment_liked",
        POSTCOMMENTED: "post_commented", //COMMENT
        COMMENTREPLIED: "comment_replied", //COMMENT
        REPOST: "post_reposted", //TAG
        VIDEOLIKED: "video_liked", //COMMENT
        VIDEOCOMMENTED: "video_commented", //REPOST
        VIDEOREADY: "video_ready",
        VIDEODELETED: "video_deleted",
        PAYMENTREQUEST: "payment_request", //PAYMENT
        CC_UPDATE_REQUEST: "cc_update_request", //PAYMENT
        PAYMENTCONFIRMATION: "payment_confirmation", //PAYMMENT
        NEWACADEMYVIDEO: "new_academy_video", //New academy video
        DOCUMENTREQUEST: "document_signature_request",
        DOCUMENTREQUESTSURVEY: "document_survey_request",
        BELTVERIFIED: "belt_verified",
        BELTREJECTED: "belt_rejected",
        POSTOSS: "post_ossed",
        POSTFIRE: "post_fire",
        POSTRNC: "post_rnc",
        CLASSREQUEST: "class_request",
        VIDEOREMOVED: "video_removed",
        NOTI_SAVECOMPETITORALERT: "saved_competitor_alert",
      },
    };
  },
  methods: {
    doNothing() {},
    trackEvent(category, label, val, action) {
      this.$gtm.trackEvent({
        event: null, // Event type [default = 'interaction'] (Optional)
        category: category,
        action: action ? action : "click",
        label: label,
        value: val,
        noninteraction: false, // Optional
      });
    },
    copyToClipboard(text, alertText) {
      const vm = this;
      const ua = navigator.userAgent.toLowerCase();
      const isAndroid = ua.indexOf("android") > -1; //&& ua.indexOf("mobile");
      if (
        isAndroid &&
        typeof navigator !== "undefined" &&
        typeof navigator.clipboard !== "undefined" &&
        navigator.permissions !== "undefined"
      ) {
        const type = "text/plain";
        const blob = new Blob([text], { type });
        // eslint-disable-next-line
        const data = [new ClipboardItem({ [type]: blob })];
        navigator.permissions
          .query({ name: "clipboard-write" })
          .then((permission) => {
            if (
              permission.state === "granted" ||
              permission.state === "prompt"
            ) {
              navigator.clipboard
                .write(data)
                .then(() => {
                  vm.showAlert(
                    "success",
                    alertText ? alertText : vm.$t("message.copied")
                  );
                })
                .catch(() => {
                  vm.showAlert("error", vm.$t("message.error_generic"));
                });
            } else {
              vm.showAlert("error", vm.$t("message.error_generic"));
            }
          });
      } else {
        navigator.clipboard.writeText(text).then(
          function () {
            vm.showAlert(
              "success",
              alertText ? alertText : vm.$t("message.copied")
            );
          },
          function () {
            /* clipboard write failed */
            vm.showAlert("error", vm.$t("message.error_generic"));
          }
        );
      }
    },
    getAppPanelsData() {
      return appPanels;
    },
    async getSuggestions() {
      if (!this.isSmallerDisplay()) {
        const data = await SuggestionsService.suggestions();
        if (data) {
          appPanels.suggestions = data.suggestions;
          return data.suggestions;
        }
      }
      return null;
    },
    async getNews() {
      if (!this.isSmallerDisplay()) {
        const data = await NewsService.random({ always: true });
        if (data) {
          appPanels.newsArticle = data.entry;
          return data.entry;
        }
      }
      return null;
    },

    getTechniqueTitle(t) {
      let title = this.$t("message.submission");
      if (t == "submission") title = this.$t("message.submission");
      else if (t == "pass") title = this.$t("message.pass");
      else if (t == "mount") title = this.$t("message.mount");
      else if (t == "side-control") title = this.$t("message.side_control");
      else if (t == "sweep") title = this.$t("message.sweep");
      else if (t == "guard") title = this.$t("message.guard");
      else if (t == "back") title = this.$t("message.back");
      else if (t == "takedown") title = this.$t("message.takedown");
      else if (t == "transition") title = this.$t("message.transition");
      else if (t == "standup") title = this.$t("message.standup");
      else if (t == "start") title = this.$t("message.start");
      return title;
    },
    async getMe() {
      const now = new Date();
      //We will only refresh once every 6 hours
      now.setHours(now.getHours() - 6);
      if (!lastMeRefresh || lastMeRefresh.getTime() < now.getTime()) {
        const path = this.$route.path;
        if (path == "/login" || path == "/") return null;
        lastMeRefresh = new Date();
        try {
          const data = await UserService.initMe();
          if (data) {
            const me = data.me;
            let theme = "light";
            if (me.darkMode) theme = "dark";
            this.$vuetify.theme.dark = me.darkMode;
            this.$store.dispatch("setTheme", theme);
            const image = me.userImageSmall;
            if (image != this.$store.state.userImage) {
              this.$store.dispatch("setUserImage", image);
            }
            if (me.sessionUser) {
              this.$store.dispatch("setUser", me.sessionUser);
            }
            appPanels.me = me;
            if (!me.active) {
              AuthenticationService.logout();
              this.$store.dispatch("setToken", null);
              this.$store.dispatch("setUser", null);
              this.$store.dispatch("setInApp", null);
              localStorage.removeItem("token");
              window.location.replace("/login?inactive=true");
            }
            return me;
          }
        } catch (err) {
          if (err) console.log("Not logged in, heading to login page :)");
        }
      }
      return null;
    },
    isDarkTheme() {
      if (this.$store.state.theme == "dark") return true;
      return false;
    },
    isSameDate(date1, date2) {
      if (date1 && date2) {
        date1.setHours(0, 0, 0, 0);
        date2.setHours(0, 0, 0, 0);
        //console.log('Is ['+date1+'] == ['+date2+']? ', val);
        if (
          date1.getFullYear() === date2.getFullYear() &&
          date1.getMonth() === date2.getMonth() &&
          date1.getDate() === date2.getDate()
        )
          return true;
        else return false;
      }
      return false;
    },
    themeColor(color) {
      let theme = this.$store.state.theme;
      if (!theme) theme = "dark";
      let themeColor = this.$vuetify.theme.themes[theme][color];
      if (themeColor) return themeColor;
      else return this.$vuetify.theme.themes.light.primary;
    },
    assets(file) {
      if (file) return ASSETS_URL + "/" + file;
      else return ASSETS_URL;
    },
    doNoting() {},
    seconds2Minutes(time) {
      if (!time) return "00:00";
      let minutes = Math.floor(time / 60);
      let seconds = time - minutes * 60;
      if (minutes < 10) minutes = "0" + minutes;
      if (seconds < 10) seconds = "0" + seconds;
      return minutes + ":" + seconds;
    },
    hasRequiredVersion(v) {
      if (
        v &&
        v != "" &&
        this.$store &&
        this.$store.state &&
        this.$store.state.appVersion
      ) {
        try {
          let string = v.replace(/\./g, "");
          const neededVersion = new Number(string).valueOf();
          string = this.$store.state.appVersion.replace(/\./g, "");
          const currentVersion = new Number(string).valueOf();
          if (currentVersion >= neededVersion) return true;
        } catch (err) {
          //Nothing
        }
      }
      return false;
    },
    getIntervalDisplay(interval) {
      if (interval == "day") return this.$t("message.daily");
      else if (interval == "month") return this.$t("message.monthly");
      else if (interval == "week") return this.$t("message.weekly");
      else if (interval == "bi-week") return this.$t("message.bi_weekly");
      else if (interval == "bi-year") return this.$t("message.bi_yearly");
      else if (interval == "year") return this.$t("message.yearly");
      else return "";
    },
    getBeltColorForProgress(belt) {
      let color = this.themeColor(belt);
      if (belt && belt.indexOf("white") >= 0) color = this.getColor("light");
      return color;
    },
    getHighestVerifiedBelt(belts) {
      var temp = [];
      if (belts) {
        for (const b in belts) {
          if (b == "white") temp.push({ value: "white", rank: 1 });
          else if (b == "blue") temp.push({ value: "blue", rank: 2 });
          else if (b == "purple") temp.push({ value: "purple", rank: 3 });
          else if (b == "brown") temp.push({ value: "brown", rank: 4 });
          else if (b == "black") temp.push({ value: "black", rank: 5 });
          else if (b == "coral") temp.push({ value: "coral", rank: 6 });
          else if (b == "red") temp.push({ value: "red", rank: 7 });
          else if (b == "hero") temp.push({ value: "hero", rank: 20 });
        }
        temp.sort(function (a, b) {
          if (a.rank < b.rank) return 1;
          if (a.rank > b.rank) return -1;
          return 0;
        });
        return temp[0].value;
      }
      return null;
    },
    beltFromStripes(belt) {
      if (belt) {
        if (belt.indexOf("white") >= 0) return "white";
        else if (belt.indexOf("blue") >= 0) return "blue";
        else if (belt.indexOf("purple") >= 0) return "purple";
        else if (belt.indexOf("brown") >= 0) return "brown";
        else if (belt.indexOf("black") >= 0) return "black";
        else return "white";
      }
    },
    isAcademyAdmin() {
      AdminService.academies().then((data) => {
        if (data) {
          if (data.length > 0)
            this.$store.dispatch("setManagedAcademyAdmin", true);
          else this.$store.dispatch("setManagedAcademyAdmin", false);
        }
      });
    },
    goToUser(id) {
      if (id) this.navigateTo({ name: "viewprofile", params: { userId: id } });
    },
    goToEvent(id) {
      if (id) this.navigateTo({ name: "viewevent", params: { eventId: id } });
    },
    getLastPartOfUrl(url) {
      if (url && url.indexOf("/") > 0) {
        const split = url.split("/");
        if (split && split.length > 0) {
          if (split.length == 1) {
            return split[0];
          } else {
            let text = split[split.length - 1];
            if (!text || text == "") text = split[split.length - 2];
            return text;
          }
        }
      }
      return url;
    },
    getInterestsValues(interests) {
      const items = [
        { value: "mentor", text: this.$t("message.interest_mentor") },
        { value: "mentoring", text: this.$t("message.interest_mentoring") },
        { value: "competing", text: this.$t("message.interest_competing") },
        { value: "privates", text: this.$t("message.interest_privates") },
        { value: "teaching", text: this.$t("message.interest_teaching") },
      ];
      let array = [];
      if (interests) {
        for (const i of items) {
          if (interests.indexOf(i.value) >= 0) array.push(i);
        }
      }
      return array;
    },
    goBack(log) {
      if (log) {
        UserService.logActivity({
          activityType: "clicked_menu_back",
        });
      }
      window.history.length > 1
        ? this.$router.go(-1)
        : this.navigateTo({ name: "home" });
    },
    setLang() {
      const browserLang = navigator.language;
      if (!this.$store || !this.$store.state || !this.$store.state.language) {
        var newLang = "en";
        if (browserLang) {
          if (browserLang.indexOf("es") >= 0) newLang = "es";
          else if (browserLang.indexOf("fr") >= 0) newLang = "fr";
          else if (browserLang.indexOf("pt") >= 0) newLang = "pt";
        }
        console.log("Picking language: ", newLang);
        this.$i18n.locale = newLang;
      }
    },
    getBrowserLang() {
      var lang = "en";
      var blang = navigator.language || navigator.userLanguage;
      if (blang) {
        const split = blang.split("-");
        lang = split[0];
      }
      return lang;
    },
    capitalizeText(value) {
      if (!value) return "";
      return value.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    },
    getNotificationsCount(action) {
      NotificationService.count().then((data) => {
        if (data) action(data.count);
      });
    },
    getMessagesCount(action) {
      MessageService.count().then((data) => {
        if (data) action(data.count);
      });
    },
    getCountry(code) {
      for (const c of countriesJson) {
        if (c.countryCode == code) return c;
      }
      return null;
    },
    dateFromString(dateString) {
      //Expected format: 2021-04-23T13:00:00.000Z
      let d = null;
      if (dateString && dateString != "") {
        const split = dateString.toString().split("-");
        if (split.length <= 2) {
          return new Date(dateString);
        }
        try {
          let split = dateString.split("T");
          const time = split[1].split(":");
          const date = split[0].split("-");
          if (date && date.length == 3 && time && time.length == 3) {
            d = new Date(dateString);
            d.setUTCFullYear(date[0]);
            //console.log("Year: ", date[0]);
            const monthNumber = new Number(date[1]).valueOf() - 1;
            d.setUTCMonth(monthNumber);
            //console.log("Month: ", monthNumber);
            d.setUTCDate(date[2]);
            //console.log("Date: ", date[2]);
            const hoursNumber = new Number(time[0]).valueOf();
            d.setUTCHours(hoursNumber);
            //console.log("Hours: ", hoursNumber);
            const minutesNumber = new Number(time[1]).valueOf();
            d.setUTCMinutes(minutesNumber);
            //console.log("Min: ", minutesNumber);
            d.setUTCSeconds(0);
            //console.log("From[" + dateString + "] to: ", d);
            d.setUTCMilliseconds(0);
            //console.log("New date from string: ", d);
            return d;
          } else {
            "Invalid date string. Actual date: " +
              dateString +
              ". Expected: yyyy-mm-ddThh:mm:ss.000Z";
          }
        } catch (err) {
          console.log(err);
          console.log(
            "Error converting date from string. Actual date: " +
              dateString +
              ". Expected: yyyy-mm-ddThh:mm:ss.000Z"
          );
        }
      }
      return d;
    },
    unixString2Datetime(s) {
      return new Date(new Number(s) * 1000);
    },
    /*
    getBrowserOffsetMinutes(date) {
      let num = 0;
      if (date) {
        num = date.getTimezoneOffset() * -1;
      }
      return num;
    },
    getTimeZoneOffsetMinutes(date, tz) {
      let offset = 0;
      if (date && tz) {
        try {
          const tzDate = moment.tz(date, tz);
          return tzDate._offset;
        } catch (err) {
          console.log(err);
        }
      }
      return offset;
    },
    */
    adjustTimeToOffset(date, timeZone) {
      if (timeZone) {
        const offset = this.getTimezoneOffset(timeZone);
        const d = new Date(date);
        //console.log("The date is: ", d);
        //console.log("Offset with [" + timeZone + "] is: ", offset);
        if (offset != 0) {
          d.setHours(d.getHours() + offset);
          //console.log("Returning adjusted: ", d);
          return d;
        } else {
          //console.log("Returning date: ", d);
          return d;
        }
      }
      return date;
    },
    getPaymentStatus(transaction, status) {
      if (status) return this.getTransactionStatusText(status);
      if (transaction.cancelled) {
        if (transaction.subscription) return this.$t("message.inactive");
        else return this.$t("message.cancelled");
      } else if (transaction.completed) {
        if (transaction.subscription) return this.$t("message.active");
        else return this.$t("message.success");
      } else {
        return this.$t("message.waiting_for_payment");
      }
    },
    getTransactionStatusText(status) {
      if (status == "active") return this.$t("message.active");
      else if (status == "succeeded" || status == "success")
        return this.$t("message.success");
      else if (status == "pending_payment")
        return this.$t("message.pending_payment");
      else if (status == "paused") return this.$t("message.paused");
      else if (status == "trialing")
        return this.$t("message.subscription_status_trialing");
      else if (status == "open") return this.$t("message.payment_status_open");
      else if (status == "past_due")
        return this.$t("message.subscription_status_past_due");
      else return status;
    },
    getTransactionStatusColor(status) {
      if (status == "active") return this.themeColor("success");
      if (status == "completed") return this.themeColor("success");
      else if (status == "succeeded") return this.themeColor("success");
      else if (status == "success") return this.themeColor("success");
      else if (status == "pending_payment") return this.themeColor("error");
      else if (status == "paused") return "#FFD700";
      else if (status == "trialing") return "#FFD700";
      else if (status == "open") return this.themeColor("success");
      else if (status == "past_due") return this.themeColor("error");
      else return this.themeColor("error");
    },
    getStatusColor(t, status) {
      if (!status && t.subscription && t.subscription.status)
        status = t.subscription.status;
      if (status) {
        return this.getTransactionStatusColor(status);
      } else if (t) {
        if (!t._id) {
          //Passing the status itself
          if (t == "active") return "green";
          else if (t == "expired") return "red";
          else if (t == "pending_payment") return "#FFD700";
          else return "#FFD700";
        } else {
          const now = new Date();
          if (t.cancelled) {
            return this.themeColor("error");
          } else if (t.completed && t.pausedUntil) {
            return "#FFD700";
          } else if (t.completed && t.subscriptionStartDate) {
            try {
              const startDate = new Date(t.subscriptionStartDate);
              if (startDate > now) return "#FFD700";
              else return this.themeColor("success");
            } catch (err) {
              console.log("Invalid date: ", t.subscriptionStartDate);
            }
          } else if (t.completed) {
            return this.themeColor("success");
          } else {
            return this.themeColor("error");
          }
        }
      }
    },
    adjustTimeToPost(date, timeZone) {
      /**
       * Get time ready to match UTC before the browser fucks it up
       */
      if (timeZone) {
        const offset = this.getTimezoneOffset(timeZone) * -1;
        const d = new Date(date);
        //console.log("The date is: ", d);
        //console.log("Reverted Offset with [" + timeZone + "] is: ", offset);
        if (offset != 0) {
          d.setHours(d.getHours() + offset);
          //console.log("Returning adjusted: ", d);
          return d;
        } else {
          //console.log("Returning date: ", d);
          return d;
        }
      }
      return date;
    },
    getTimezoneOffset(timeZone) {
      if (!timeZone) return 0;
      var currentTimeAtCityString = new Date().toLocaleString("en-US", {
        timeZone: timeZone,
      });
      var timeAtCity = new Date(currentTimeAtCityString);
      const here = new Date();
      const hoursHere = here.getHours();
      const hoursTimezone = timeAtCity.getHours();
      if (timeAtCity < here) {
        if (hoursTimezone <= hoursHere) return hoursTimezone - hoursHere;
        else return hoursTimezone - (hoursHere + 24);
      } else {
        if (hoursTimezone >= hoursHere) return hoursTimezone - hoursHere;
        else return hoursTimezone - (hoursHere - 24);
      }
    },
    /*
    getTimezoneOffsetOld(timeZone) {
      //From Admin
      if (!timeZone) return 0;
      var currentTimeAtCityString = new Date().toLocaleString("en-US", {
        timeZone: timeZone
      });
      var timeAtCity = new Date(currentTimeAtCityString);
      const here = new Date();
      const hoursHere = here.getHours();
      const hoursTimezone = timeAtCity.getHours();
      if (timeAtCity < here) {
        if (hoursTimezone <= hoursHere) return hoursTimezone - hoursHere;
        else return hoursTimezone - (hoursHere + 24);
      } else {
        if (hoursTimezone >= hoursHere) return hoursTimezone - hoursHere;
        else return hoursTimezone - (hoursHere - 24);
      }
    },
    */
    prettyTimeZone(tz, html) {
      let tzDisplay = tz;
      if (tz) {
        const split = tz.split("/");
        const main = split[0];
        const city = split[1].replace(/_/g, " ");
        if (html)
          tzDisplay =
            "<span>" +
            main +
            '</span><span class="supporting-text" style="margin-left:5px;">' +
            city +
            "</span>";
        else tzDisplay = main + " " + city;
      }
      return tzDisplay;
    },
    getLinkRegex() {
      //return /(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?([^\n <]*)?/g;
      return /(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?([^\n <]*)?/g;
    },
    getNowDate() {
      const d = new Date();
      return d.toString();
    },
    getNowDateString() {
      const d = new Date();
      return moment.utc(d).format("YYYY-MM-DD");
    },
    getFormattedString(d, format) {
      return moment(d).format(format);
    },
    toSimpleDateFormat(dateString) {
      const simpler = moment.utc(dateString).format("YYYY-MM-DD");
      return simpler;
    },
    toNiceDateFormat(d) {
      return moment.utc(d).format("dddd, MMMM Do YYYY");
    },
    toUTC(d) {
      return moment.utc(d);
    },
    toNiceDateCustomFormatAdjusted(d, format) {
      return moment(d).format(format);
    },
    toNiceDateCustomFormat(d, format) {
      return moment.utc(d).format(format);
    },
    toNiceDateTimeFormat(d) {
      return moment.utc(d).format("dddd, MMMM Do YYYY [at] hh:mm A");
    },
    toNiceDateTimeFormatAdjusted(d) {
      return moment(d).format("dddd, MMMM Do YYYY [at] hh:mm A");
    },
    toDateNotAdjusted(d) {
      if (!d) return new Date();
      if (typeof d === "object")
        //This was meant for string to date convertion
        return d;
      //Return the exat same you see on the string no matter what timezone
      try {
        const theDate = new Date(d);
        //It has to be of the format: 2021-06-01T12:00:00.312Z
        if (d.indexOf("T") > 0) {
          let split = d.split("T");
          if (split[0]) {
            split = split[0].split("-");
            if (split[2]) {
              const originalDate = new Number(split[2]).valueOf();
              theDate.setDate(originalDate);
            }
          }
        }
        return theDate;
      } catch (e) {
        console.log(e);
        return d;
      }
    },
    toYYYYMMDDAdjusted(d) {
      const simpler = moment(d).format("YYYY-MM-DD");
      return simpler;
    },
    toMonthYear(d) {
      return moment.utc(d).format("MMMM YYYY");
    },
    toUTCMilliseconds(d) {
      return moment.utc(d).valueOf();
    },
    getMeridiem(date) {
      let d = date;
      if (!date) d = new Date();
      let meridiem = "AM";
      let hours = d.getHours();
      if (d.getHours() > 12) {
        hours = d.getHours() % 12;
        if (hours == 2) hours = 12;
        meridiem = "PM";
      }
      return meridiem;
    },
    isValidId(id) {
      var checkForHexRegExp = new RegExp("^[0-9a-fA-F]{24}$");
      return checkForHexRegExp.test(id);
    },
    userImg(img, logoImage) {
      //Replace with versionn 2: return process.env.API_FILES_HOST + img;
      if (img && img != "" && img.indexOf("https:") == 0) return img;
      else if (img && img != "" && img.indexOf("userfiles") >= 0)
        return process.env.VUE_APP_API_HOST + "/api/" + img;
      else if (img && img != "" && img.indexOf("media") >= 0)
        return process.env.VUE_APP_API_MEDIA_HOST + "/" + img;
      else if (!logoImage) return ASSETS_URL + "/blank-profile-picture.png";
      else return ASSETS_URL + "/logo-circle.png";
    },
    getApiBase() {
      return process.env.VUE_APP_API_HOST + "/api/";
    },
    encodeBase64(text) {
      let encoded = btoa(text);
      return encoded;
    },
    getHostName(url) {
      try {
        const urlObj = new URL(url);
        return urlObj.hostname;
      } catch (e) {
        return "";
      }
    },
    userFile(path) {
      //Replace with versionn 2: return process.env.API_FILES_HOST + img;
      if (!path || path == "") return ASSETS_URL + "/blank-profile-picture.png";
      else if (path && path != "" && path.indexOf("https:") == 0) return path;
      else if (path.indexOf("userfiles") >= 0)
        return process.env.VUE_APP_API_HOST + "/api/" + path;
      else return process.env.VUE_APP_API_MEDIA_HOST + "/" + path;
    },
    markLegendSeen(store, legend_id) {
      var seenLegends = store.state.user.seenLegends;
      if (!seenLegends) seenLegends = {};
      seenLegends[legend_id] = "yes";
      UserService.updateUser([
        { propName: "seenLegends", value: seenLegends },
      ]).then((data) => {
        if (data) {
          const updatedUser = store.state.user;
          updatedUser.seenLegends = seenLegends;
          store.dispatch("setUser", updatedUser);
        }
      });
    },
    isAppDisplay() {
      if (store.state.inApp) return true;
      return false;
    },
    isSessionUser(id) {
      if (this.$store.state.user._id == id) return true;
      return false;
    },
    isSmallerDisplay() {
      if (
        this.$vuetify.breakpoint.name == "xs" ||
        this.$vuetify.breakpoint.name == "sm"
      )
        return true;
      else return false;
    },
    extractHashTags(inputText) {
      var hashTags = [];
      const regex = /#([\wñÑ]+)/g;
      var match;
      while ((match = regex.exec(inputText))) {
        hashTags.push(match[1]);
      }
      return hashTags;
    },
    contains(array, element, field) {
      if (!array || !element) return false;
      if (!field) {
        const found = array.indexOf(element);
        if (found >= 0) return true;
      } else {
        for (const a of array) {
          if (a[field] && a[field] == element) return true;
        }
      }
      return false;
    },
    hasSchedule(academySchedule) {
      if (academySchedule) {
        if (
          (academySchedule.monday && academySchedule.monday.length > 0) ||
          (academySchedule.tuesday && academySchedule.tuesday.length > 0) ||
          (academySchedule.wednesday && academySchedule.wednesday.length > 0) ||
          (academySchedule.thursday && academySchedule.thursday.length > 0) ||
          (academySchedule.friday && academySchedule.friday.length > 0) ||
          (academySchedule.saturday && academySchedule.saturday.length > 0) ||
          (academySchedule.sunday && academySchedule.sunday.length > 0)
        ) {
          return true;
        }
      }
      return false;
    },
    navigateTo(info, queryParams) {
      if (
        (this.$route.name == info.name && info.name == "notifications") ||
        (this.$route.name == info.name && info.name == "inbox")
      ) {
        this.$router.go();
      } else {
        if (queryParams) {
          const query = this.stringToParams(queryParams);
          info.query = query;
        }
        this.$router.push(info).catch(() => {});
        UserService.logActivity({
          activityType: "social_router_" + info.name,
        });
      }
    },
    centsToAmount(s) {
      if (!s) return 0;
      const amount = s + "";
      //console.log('String to amount: ', amount);
      if (s.length <= 2) {
        let n = new Number("0." + s);
        return n.valueOf();
      } else {
        const price = amount.substring(0, amount.length - 2);
        const priceDecimal = amount.substring(amount.length - 2, amount.length);
        const n = new Number(price + "." + priceDecimal);
        return n.valueOf();
      }
    },
    stringToParams(queryParams) {
      let params = {};
      if (queryParams) {
        let split = queryParams.split("&");
        if (split && split.length > 0) {
          for (const group of split) {
            split = group.split("=");
            if (split && split.length == 2) {
              params[split[0]] = split[1];
            }
          }
        }
      }
      return params;
    },
    getCurrencySymbol(c) {
      if (!c) return "";
      if (c.toLowerCase() == "usd") return "$";
      else return c;
    },
    checkUser() {
      if (!this.$store.state.isUserLoggedIn) {
        // this.navigateTo({name: 'invalid'})
      }
    },
    getBeltColor(belt) {
      if (belt && belt == "red_black") return "#EC7063";
      else if (belt && belt == "red_white") return "#EC7063";
      else if (belt && belt.indexOf("white") >= 0) return "#FFFFFF";
      else if (belt && belt.indexOf("blue") >= 0) return "#1683f0";
      else if (belt && belt.indexOf("purple") >= 0) return "#8D70B2";
      else if (belt && belt.indexOf("brown") >= 0) return "#A97942";
      else if (belt && belt.indexOf("black") >= 0) return "#000000";
      else if (belt && belt.indexOf("red") >= 0) return "#EC7063";
      else if (belt && belt.indexOf("orange") >= 0) return "#fd9927";
      else if (belt && belt.indexOf("green") >= 0) return "#6ca754";
      else if (belt && belt.indexOf("silver") >= 0) return "#999999";
      else return "#FFFFFF";
    },
    getWorkoutColor(type) {
      let color = "orange";
      if (type == "cardio") {
        color = "green";
      } else if (type == "strength_and_conditioning") {
        color = "error";
      } else if (type == "stretching") {
        color = "brown";
      } else if (type == "cross_straining") {
        color = "terciary";
      }
      return color;
    },
    getRandomInt(num) {
      return Math.floor(Math.random() * num);
    },
    getEditorToolbar(hideInserts) {
      let inserts = ["link", "image", "video"];
      if (hideInserts) inserts = [];
      const toolbar = [
        [{ font: [] }],

        [{ header: [false, 1, 2, 3, 4, 5, 6] }],

        [{ size: ["small", false, "large", "huge"] }],

        ["bold", "italic", "underline", "strike"],

        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" },
        ],

        [{ header: 1 }, { header: 2 }],

        ["blockquote", "code-block"],

        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],

        [{ script: "sub" }, { script: "super" }],

        [{ indent: "-1" }, { indent: "+1" }],

        [{ color: [] }, { background: [] }],

        inserts,
      ];
      return toolbar;
    },
    goToAcademy(id, url) {
      if (id)
        this.navigateTo({ name: "viewacademy", params: { academyId: id } });
      else if (url) window.location = url;
    },
    getPodiumIconColor(place) {
      if (place == 1) return this.themeColor("gold");
      else if (place == 2) return this.themeColor("silver");
      else if (place == 3) return this.themeColor("bronze");
    },
    goToPartner(id) {
      if (id)
        this.navigateTo({ name: "viewpartner", params: { partnerId: id } });
    },
    goToVideo(id) {
      if (id) this.navigateTo({ name: "viewvideo", params: { videoId: id } });
    },
    goToHelp() {
      this.navigateTo({ name: "helpsecure" });
    },
    getColor(c) {
      var color = "white";
      if (c == "light") color = "grey";
      else if (c == "dark") color = "white";
      else if (c == "blue") color = this.themeColor(c);
      else if (c == "purple") color = this.themeColor(c);
      else if (c == "brown") color = this.themeColor(c);
      else if (c == "black") color = "black";
      else if (c == "primary") color = this.themeColor(c);
      else if (c == "bronze") color = this.themeColor(c);
      else if (c == "gold") color = this.themeColor(c);
      else if (c == "hero") color = this.themeColor(c);
      else if (c == "silver") color = this.themeColor(c);
      else if (c == "orange") color = this.themeColor(c);
      else if (c == "green") color = "#6ca754";
      else if (c == "red") color = "#EC7063";
      else if (c == "gray") color = "#f5f5f5";
      else if (c) color = c;
      return color; //#4b4b4b; gray
    },
    hourInNumberToDate(time, date) {
      const split = time.toString().split(".");
      let hoursString = split[0];
      let minutesString = "00";
      if (split.length > 1) {
        if (split[1] == 25) {
          hoursString = split[0];
          minutesString = "15";
        } else if (split[1] == 5) {
          hoursString = split[0];
          minutesString = "30";
        } else if (split[1] == 75) {
          hoursString = split[0];
          minutesString = "45";
        } else {
          hoursString = split[0];
          minutesString = "00";
        }
      }
      const d = new Date(date);
      d.setHours(hoursString);
      d.setMinutes(minutesString);
      d.setSeconds(0);
      return d;
    },
    showAlert(type, title, text, time, position) {
      try {
        this.$swal.fire({
          position: position ? position : "bottom",
          icon: type, // warning, error, success, info, and question
          title: title,
          toast: true,
          text: text,
          color: "red",
          timer: time ? time : 5000,
          timerProgressBar: true,
          showConfirmButton: false,
          customClass: {
            popup: "sweet-alert-light",
          },
        });
      } catch (err) {
        console.log(err);
      }
      /*
        .then((result) => {
          // Nothing to do here for now
        });
      */
    },
    getQueryParams(params) {
      var queryParams = "?";
      if (params) {
        for (const prop in params)
          queryParams =
            queryParams + "&" + prop + "=" + encodeURIComponent(params[prop]);
      }
      return queryParams;
    },
    toBeginningOfDay(d) {
      if (d) {
        d.setHours(0);
        d.setMinutes(0);
        d.setSeconds(0);
        return d;
      }
    },
    toEndOfDay(d) {
      if (d) {
        d.setHours(23);
        d.setMinutes(59);
        d.setSeconds(59);
        return d;
      }
    },
    daysBetween(d1, d2) {
      if (d1 && d2) {
        const timeDiff = d1.getTime() - d2.getTime();
        // To calculate the no. of days between two dates
        const daysDiff = timeDiff / (1000 * 3600 * 24);
        return Math.round(daysDiff);
      }
      return 0;
    },
    monthsBetween(d1, d2) {
      const a = moment(d1);
      const b = moment(d2);
      return a.diff(b, "months"); // =1
    },
    getWeekDays() {
      return [
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
        "friday",
        "saturday",
        "sunday",
      ];
    },
    getWeekDaysTranslated() {
      return [
        { value: "monday", display: this.$t("message.monday") },
        { value: "tuesday", display: this.$t("message.tuesday") },
        { value: "wednesday", display: this.$t("message.wednesday") },
        { value: "thursday", display: this.$t("message.thursday") },
        { value: "friday", display: this.$t("message.friday") },
        { value: "saturday", display: this.$t("message.saturday") },
        { value: "sunday", display: this.$t("message.sunday") },
      ];
    },
    getWeekDay(day) {
      let days = [
        "sunday",
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
        "friday",
        "saturday",
      ];
      return days[day];
    },
    camelCase(str) {
      if (str) {
        return str
          .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
            return index === 0 ? word.toLowerCase() : word.toUpperCase();
          })
          .replace(/\s+/g, "");
      }
    },
    capitalizeFirstLetter(str) {
      if (str) return str.charAt(0).toUpperCase() + str.slice(1);
    },
    hasAgreements() {
      if (localStorage.hasAgreements == "true") return true;
      return false;
    },
    getTransactionTotal(t) {
      const q = t.quantity;
      const a = t.amount ? t.amount : t.product ? t.product.amountDecimal : 0;
      return (q * a).toFixed(2);
    },
    truncate(value, limit, skipDots) {
      if (value) {
        let dots = "...";
        if (skipDots) dots = "";
        if (!limit) {
          limit = this.descriptionLimit;
          dots = "";
        }
        if (value.length > limit) {
          value = value.substring(0, limit - 3) + dots;
        }
        return value;
      }
    },
    dayToNumber(day) {
      if (day == "monday") return 0;
      else if (day == "tuesday") return 1;
      else if (day == "wednesday") return 2;
      else if (day == "thursday") return 3;
      else if (day == "friday") return 4;
      else if (day == "saturday") return 5;
      else return 6;
    },
    lightenDarkenColor(col, amt) {
      let usePound = false;
      if (col[0] == "#") {
        col = col.slice(1);
        usePound = true;
      }
      let num = parseInt(col, 16);
      let r = (num >> 16) + amt;
      if (r > 255) r = 255;
      else if (r < 0) r = 0;
      let b = ((num >> 8) & 0x00ff) + amt;
      if (b > 255) b = 255;
      else if (b < 0) b = 0;
      let g = (num & 0x0000ff) + amt;
      if (g > 255) g = 255;
      else if (g < 0) g = 0;
      return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
    },
    isSpecialAdmin(id) {
      if (
        id == "5c0efc082e9ce106857709f0" ||
        id == "5becfc760a3a3a09912cb546" ||
        id == "5ad5642fb5883f5b2fed7fac" ||
        id == "5bf0448692698d06ab415088"
      )
        return true;
      else return false;
    },
    isAppAdmin(userId) {
      const bjjlinkAdmins = [
        "5becfc760a3a3a09912cb546",
        "5befa9c192698d06ab415071",
        "5bf1b1a849808205ba15039a",
        "5ad5642fb5883f5b2fed7fac",
        "5bf0448692698d06ab415088",
        "5c0efc082e9ce106857709f0",
        "5c00956df58c0a1c27aba5f2",
        "5cad890845ec963c3217df93", //Santiago yahoo
        "5f328352384f3644d0e30d7b", //Andre,
        //"5faaaa8268c82d4a4576e146", //Joseph
        //"5cd9d27e19a469214c77aa61", //Chris
        "5bf2a56849808205ba150404", //Antonio
        "618d79245d32097eabcd34d0", //Damian
        "642cb8e7c5674668b97ac03f", //Justin
      ];
      if (bjjlinkAdmins.indexOf(userId) >= 0) return true;
      else return false;
    },
    goToLink(url, internal) {
      //console.log("Going to link: ", url);
      let fixedUrl = url;
      if (fixedUrl) {
        if (
          !internal &&
          fixedUrl.indexOf("http://") != 0 &&
          fixedUrl.indexOf("https://") != 0
        )
          fixedUrl = "https://" + fixedUrl;
        UserService.logActivity({
          activityType: "clicked_website",
          value: fixedUrl,
        });
        const os = this.getOS();
        if (os == "iOS" || os == "Android") {
          window.location = fixedUrl;
        } else {
          window.open(fixedUrl, "_blank");
        }
      }
    },
    goToDirections(address) {
      if (!address) return;
      const os = this.getOS();
      const encoded = encodeURIComponent(address);
      if (os == "iOS" || os == "Android") {
        window.location =
          "https://www.google.com/maps/dir/?api=1&destination=" + encoded;
      } else {
        window.open(
          "https://www.google.com/maps/dir/?api=1&destination=" + encoded,
          "_blank"
        );
      }
    },
    getPlatform() {
      return (
        window.navigator?.userAgentData?.platform ||
        window.navigator?.platform ||
        "unknown"
      );
    },
    getOS() {
      const platform =
        window.navigator?.userAgentData?.platform ||
        window.navigator?.platform ||
        "unknown";
      let userAgent = window.navigator.userAgent,
        macosPlatforms = [
          "Macintosh",
          "MacIntel",
          "MacPPC",
          "Mac68K",
          "macOS",
          "mac",
        ],
        windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
        iosPlatforms = ["iPhone", "iPad", "iPod"],
        os = null;

      if (macosPlatforms.indexOf(platform) !== -1) {
        os = "Mac OS";
      } else if (iosPlatforms.indexOf(platform) !== -1) {
        os = "iOS";
      } else if (windowsPlatforms.indexOf(platform) !== -1) {
        os = "Windows";
      } else if (/Android/.test(userAgent)) {
        os = "Android";
      } else if (!os && /Linux/.test(platform)) {
        os = "Linux";
      }
      return os;
    },
    hasMedals(user) {
      if (
        (user && user.overview && user.overview.goldMedals > 0) ||
        user.overview.silverMedals > 0 ||
        user.overview.bronceMedals > 0
      )
        return true;
      return false;
    },
    getMedalColor(place) {
      if (!place) return this.themeColor("gray");
      else if (place == 1) return this.themeColor("gold");
      else if (place == 2) return this.themeColor("silver");
      else if (place == 3) return this.themeColor("bronze");
    },
  },
});

//Uses
Vue.use(VueI18n);
Vue.use(VueSweetAlert2);
Vue.use(Croppa);
Vue.use(FlowyPlugin);

//Localization
import t_es from "@/assets/locale/translations_es.json";
import t_en from "@/assets/locale/translations_en.json";
import t_pt from "@/assets/locale/translations_pt.json";
import t_fr from "@/assets/locale/translations_fr.json";

const messages = {
  en: t_en,
  es: t_es,
  pt: t_pt,
  fr: t_fr,
};

Vue.directive("hideEmbed", {
  // When the bound element is inserted into the DOM...
  inserted: function (el, binding, vnode) {
    // Focus the element
    const embed = binding.value && binding.value == "true" ? true : false;
    //console.log("User permissions: ", permissions);
    //console.log("Against [", binding.value + "]");
    if (embed) {
      vnode.elm.parentElement.removeChild(vnode.elm);
    }
  },
});

const dateTimeFormats = {
  en: {
    time: {
      hour: "numeric",
      minute: "numeric",
    },
    month_day_year: {
      year: "numeric",
      month: "short",
      day: "numeric",
    },
    month_day: {
      month: "short",
      day: "numeric",
    },
    month_year: {
      month: "short",
      year: "numeric",
    },
    short: {
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    },
    long: {
      year: "numeric",
      month: "short",
      day: "numeric",
      weekday: "short",
      hour: "numeric",
      minute: "numeric",
    },
    long_seconds: {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    },
  },
  fr: {
    time: {
      hour: "numeric",
      minute: "numeric",
    },
    month_day: {
      month: "short",
      day: "numeric",
    },
    month_year: {
      month: "long",
      year: "numeric",
    },
    month_day_year: {
      year: "numeric",
      month: "short",
      day: "numeric",
    },
    short: {
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    },
    long: {
      year: "numeric",
      month: "short",
      day: "numeric",
      weekday: "short",
      hour: "numeric",
      minute: "numeric",
    },
    long_seconds: {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    },
  },
  es: {
    time: {
      hour: "numeric",
      minute: "numeric",
    },
    month_day_year: {
      year: "numeric",
      month: "short",
      day: "numeric",
    },
    month_day: {
      month: "short",
      day: "numeric",
    },
    month_year: {
      month: "long",
      year: "numeric",
    },
    short: {
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    },
    long: {
      year: "numeric",
      month: "short",
      day: "numeric",
      weekday: "short",
      hour: "numeric",
      minute: "numeric",
    },
    long_seconds: {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    },
  },
  pt: {
    time: {
      hour: "numeric",
      minute: "numeric",
    },
    month_day_year: {
      year: "numeric",
      month: "short",
      day: "numeric",
    },
    month_day: {
      month: "short",
      day: "numeric",
    },
    month_year: {
      month: "long",
      year: "numeric",
    },
    short: {
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    },
    long: {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    },
    long_seconds: {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    },
  },
};

Vue.component("FlowchartNodeView", FlowchartNodeView);

const i18n = new VueI18n({
  locale: "en", // set locale
  fallbackLocale: "en",
  messages, // set locale messages
  dateTimeFormats,
  silentTranslationWarn: true,
});

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
