<template>
  <div v-if="!$store.state.hidden">
    <v-sheet :rounded="isSmallerDisplay() ? '' : 'lg'" class="pa-2">
      <v-card-title class="text-subtitle-2 pa-0">
        {{ $t("message.filter_videos") }}
      </v-card-title>
      <v-container>
        <v-row class="mt-0 pt-0 pb-0 mb-0">
                <v-col class="mt-0 pt-0 pb-0 mb-0">
                  <TagsSelect
                    v-model="tagsFilter"
                    :academy-only="true"
                    :label="$t('message.tags')"
                  />
                </v-col>
                <v-col class="mt-0 pt-0 pb-0 mb-0">
                  <category-combo
                    v-model="categoryFilter"
                    :academy-only="true"
                    :label="$t('message.category')"
                  />
                </v-col>
              </v-row>
              <v-row class="mt-0 pt-0 pb-0 mb-0" v-show="false">
                <v-col class="mt-0 pt-0 pb-0 mb-0">
                  <v-switch
                    v-model="userVideosOnlyFilter"
                    :label="$t('message.only_my_videos')"
                  ></v-switch>
                </v-col>
                <v-col class="mt-0 pt-0 pb-0 mb-0">
                  <v-switch
                    v-model="academyVideosOnlyFilter"
                    :label="$t('message.only_academy_videos')"
                  ></v-switch>
                </v-col>
              </v-row>
      </v-container>
    </v-sheet>
    <div>
      <div v-if="!loading && result.length == 0" class="text-center pa-2 ma-2">
        <h4>{{ $t("message.no_videos_match") }}</h4>
      </div>
      <template v-else v-for="(r, index) in result">
        <v-sheet
          :rounded="isSmallerDisplay() ? '' : 'lg'"
          :key="index + r._id"
          :position="index"
          class="mt-2"
          v-if="r.fileUrl"
        >
          <v-card-title class="pa-2">
            <span class="gray--text text-caption ml-1">
              {{ $d(new Date(r.uploaded), "month_day", $i18n.locale) }}
            </span>
          </v-card-title>
          <v-card-title class="text-subtitle-1">
            {{ r.title }}
          </v-card-title>
          <v-responsive class="pa-0">
            <video-display :video="r"></video-display>
          </v-responsive>
          <v-card-text
            class="text-left"
          >
            <span v-html="truncate(r.description, 500)"></span>
          </v-card-text>
          <div v-if="r.tags && r.tags.length > 0" class="pa-2">
            <template v-for="(t, index2) in r.tags">
              <v-chip small class="text-overline mr-1" :key="r._id + 'tag' + index2">{{ t }}</v-chip>
            </template>
          </div>
        </v-sheet>
      </template>
      <div v-if="loading">
        <v-skeleton-loader
          type="card"
        ></v-skeleton-loader>
      </div>
      <div class="text-center">
        <v-btn @click="searchMoreVideos" small color="primary" :disabled="!viewMore">{{
          $t("message.view_more")
        }}</v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import VideoService from "@/services/VideoService";
import CategoryCombo from "@/components/inputs/VideoCategoryCombo.vue";
import TagsSelect from "@/components/inputs/VideoTagSelect.vue";
import VideoDisplay from "@/components/displays/VideoDisplay.vue";

export default {
  data() {
    return {
      academyOnly: this.$route.query.academyonly,
      result: [],
      loading: false,
      loadingMore: false,
      viewMore: false,
      titleFilter: null,
      beltFilter: null,
      tagsFilter: null,
      userVideosOnlyFilter: false,
      academyVideosOnlyFilter: true,
      categoryFilter: null,
      pointer: 0,
      addVideoDialog: null,
      descriptionLimit: 200,
    };
  },
  components: {
    CategoryCombo,
    TagsSelect,
    VideoDisplay,
  },
  created() {
    if (this.academyOnly) this.academyVideosOnlyFilter = true;
    this.filter();
  },
  methods: {
    async viewLikes(id, whenDone) {
      const data = await VideoService.viewLikes(id);
      if (data) {
        whenDone(data);
      }
    },
    async likeVideo(id, whenDone) {
      const data = await VideoService.likeVideo(id);
      if (data) {
        whenDone(data);
      } else {
        this.showAlert("error", this.$t("message.oops_not_working"));
      }
    },
    async unlikeVideo(id, whenDone) {
      const data = await VideoService.unlikeVideo(id);
      if (data) {
        whenDone(data);
      } else {
        this.showAlert("error", this.$t("message.oops_not_working"));
      }
    },
    goToVideo(id, comments) {
      if(comments)
        this.navigateTo({ name: "viewvideo", params: { videoId: id } });
      else
        this.navigateTo({ name: "viewvideo", query: { comments: true }, params: { videoId: id } });
    },
    clearFilters() {
      this.cityFilter = null;
      this.titleFilter = null;
      this.beltFilter = null;
      this.tagsFilter = null;
      this.categoryFilter = null;
    },
    searchMoreVideos() {
      this.filter(true);
    },
    displayAddDialog() {
      this.addVideoDialog = true;
    },
    filter(append) {
      if (!this.loading) {
        this.viewMore = false;
        if (!append) {
          this.result = [];
          this.pointer = 0;
        }
        this.loading = true;
        var params = {};
        if (this.academyVideosOnlyFilter) params["academyonly"] = true;
        if (this.titleFilter && this.titleFilter != "")
          params["title"] = this.titleFilter;
        if (this.beltFilter && this.beltFilter != "")
          params["belt"] = this.beltFilter;
        if (this.categoryFilter && this.categoryFilter != "")
          params["category"] = this.categoryFilter;
        if (this.tagsFilter && this.tagsFilter.length > 0)
          params["tags"] = this.tagsFilter;
        if (this.userVideosOnlyFilter) params["user"] = true;
        params["pointer"] = this.pointer;
        VideoService.managedAcademyVideos(this.pointer, params)
          .then((data) => {
            if (data) {
              this.result = this.result.concat(data.videos);
              this.loading = false;
              this.pointer = this.pointer + data.count;
              if (data.count >= this.searchesLimit) this.viewMore = true;
            }
          })
          .catch(() => {
            this.loading = false;
            this.result = [];
          });
      }
    },
  },
  watch: {
    titleFilter: {
      handler: function () {
        this.pointer = 0;
        this.filter();
      },
    },
    beltFilter: {
      handler: function () {
        this.pointer = 0;
        this.filter();
      },
    },
    tagsFilter: {
      handler: function () {
        this.pointer = 0;
        this.filter();
      },
    },
    categoryFilter: {
      handler: function () {
        this.pointer = 0;
        this.filter();
      },
    },
    userVideosOnlyFilter: {
      handler: function () {
        this.pointer = 0;
        this.filter();
      },
    },
    academyVideosOnlyFilter: {
      handler: function () {
        this.pointer = 0;
        this.filter();
      },
    },
  },
};
</script>

<style scoped>
</style>
