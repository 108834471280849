<template>
  <v-sheet :rounded="isSmallerDisplay() ? '' : 'lg'">
    <v-skeleton-loader
      v-if="loading"
      type="article"
    ></v-skeleton-loader>
    <v-expansion-panels class="mt-2" flat>
      <v-expansion-panel 
        :class="$store.state.theme == 'light' ? 'border-light' : 'border-dark'"
        v-if="subscriptions && subscriptions.length > 0">
                <v-expansion-panel-header class="text-left">
                  <div>
                    <RefreshIcon class="hero-icon"></RefreshIcon>
                    {{ $t('message.subscriptions') }}
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-list>
                    <template v-for="(i, index) in subscriptions">
                      <v-list-item :key="'transaction'+index"
                        @click="navigateTo({ name: 'transaction', params: { transactionId: i._id } })"
                      >
                        <v-list-item-action>
                          <v-tooltip v-if="i.expired" bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-badge
                                v-bind="attrs"
                                v-on="on"
                                avatar
                                bordererd
                                overlap
                              >
                                <template v-slot:badge>
                                  <ClockIcon size="20"></ClockIcon>
                                </template>
                                <RefreshIcon class="hero-icon" v-if="i.subscription" :color="getStatusColor(i)" size="25"></RefreshIcon>
                                <CashIcon class="hero-icon" v-else :color="getStatusColor(i)" size="25"></CashIcon>
                              </v-badge>
                            </template>
                            <span>{{$t('message.expired')}}</span>
                          </v-tooltip>
                          <RefreshIcon class="hero-icon" v-else-if="i.subscription" :color="getStatusColor(i)" size="25"></RefreshIcon>
                          <CashIcon class="hero-icon" v-else :color="getStatusColor(i)" size="25"></CashIcon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>
                              {{truncate(i.product.name, 20)}}
                              <TagIcon v-if="i.coupon" size="20" :color="themeColor('primary')"></TagIcon>
                              <span class=""> - {{getTransactionTotal(i)+' '+i.currency}}</span>
                          </v-list-item-title>
                          <v-list-item-subtitle v-if="i.time">{{ $d(new Date(i.time), 'long', $i18n.locale) }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider v-if="index + 1 < subscriptions.length" :key="index"></v-divider>
                    </template>
                  </v-list>
                </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel 
        :class="$store.state.theme == 'light' ? 'border-light' : 'border-dark'"
        v-if="payments && payments.length > 0">
                <v-expansion-panel-header class="text-left">
                  <div>
                    <CashIcon class="hero-icon"></CashIcon>
                    {{ $t('message.payments') }}
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-list>
                    <template v-for="(i, index) in payments">
                      <v-list-item
                        :key="'transaction'+index"
                        @click="navigateTo({ name: 'transaction', params: { transactionId: i._id } })"
                      >
                        <v-list-item-action>
                          <CashIcon class="hero-icon" v-if="!i.sessionId" :color="getStatusColor(i)" size="25"></CashIcon>
                          <CreditCardIcon class="hero-icon" v-else :color="getStatusColor(i)" size="25"></CreditCardIcon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>
                            <div v-if="i.product">
                              {{i.product.name}}
                              <TagIcon class="hero-icon" v-if="i.coupon" size="20" :color="themeColor('primary')"></TagIcon>
                              <span class="t"> - {{getTransactionTotal(i)+' '+i.currency}}</span>
                            </div>
                            <div v-else-if="i.items">
                              <TagIcon class="hero-icon" v-if="i.coupon" size="20" :color="themeColor('primary')"></TagIcon>
                              <span v-if="i.items.length == 1">{{i.items[0].name}}</span>
                              <span v-else>{{i.items.length}} {{ $t('message.items') }}</span>
                              <span
                                class=""
                              > - {{getTransactionTotal(i)+' '+i.currency}}</span>
                            </div>
                          </v-list-item-title>
                          <v-list-item-subtitle v-if="i.time">{{ $d(new Date(i.time), 'long', $i18n.locale) }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider v-if="index + 1 < payments.length" :key="index"></v-divider>
                    </template>
                  </v-list>
                </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
    <div
      class="mt-2"
      v-if="!loading && ((!subscriptions && !payments) || (subscriptions.length == 0 && payments.length == 0))"
    >
      <h2>{{$t('message.no_transactions')}}</h2>
    </div>
    <v-card-actions v-if="!loading">
      <v-spacer></v-spacer>
      <v-btn elevation="0" small @click="goBack()" color="secondary">
        {{$t('message.back')}}
      </v-btn>
      <v-spacer></v-spacer>
    </v-card-actions>
  </v-sheet>
</template>

<script>
import PaymentsService from "@/services/PaymentsService";

//Icons
import { 
  CashIcon,
  RefreshIcon,
  ClockIcon,
  CreditCardIcon,
  TagIcon,
} from '@vue-hero-icons/outline'

export default {
  data() {
    return {
      loading: true,
      sessionId: this.$route.query.sessionId,
      queryTransactionId: this.$route.query.transaction,
      transactionId: this.$route.params.transactionId,
      sessionStatus: this.$route.query.status,
      checkoutType: this.$route.query.type,
      payments: [],
      subscriptions: [],
    };
  },
  components: {
    CashIcon,
    RefreshIcon,
    ClockIcon,
    CreditCardIcon,
    TagIcon,
  },
  async created() {
    if (this.sessionId && this.sessionStatus == "success") {
      if(this.checkoutType && this.checkoutType == 'update')
        this.completeCreditCardUpdate();
      else
        this.completePurchase(this.sessionId);
    } else if (this.sessionId && this.sessionStatus == "cancel") {
      const vm = this;
      if (this.queryTransactionId) {
        vm.navigateTo({ name: 'transaction', params: { transactionId: this.queryTransactionId } });
      } else {
        this.navigateTo({
          name: 'myacademytab',
          params: { tabId: 2 },
        });
      }
    } else {
      this.getTransactions();
    }
  },
  methods: {
    getTotal(t) {
      const q = t.quantity;
      let a = t.amount ? t.amount : (t.product ? t.product.amountDecimal : 0);
      if(t.coupon){
        a = t.coupon.discountedTotal;
      }
      return (q * a).toFixed(2);
    },
    cancelPurchase(id) {
      PaymentsService.cancel(id)
        .then(response => {
          if(response){
            this.showAlert("info", this.$t("message.payment_cancelled"));
            this.getTransactions();
          }
        })
        .catch(() => {
          this.getTransactions();
        });
    },
    completePurchase(id) {
      PaymentsService.complete(id)
        .then(response => {
          if(response){
            this.showAlert("success", this.$t("message.payment_completed"));
            const vm = this;
            setTimeout(function () {
              if (vm.queryTransactionId) {
                vm.navigateTo({ name: 'transaction', params: { transactionId: vm.queryTransactionId } });
              } else {
                vm.navigateTo({
                  name: 'myacademytab',
                  params: { tabId: 2 },
                });
              }
            }, 1000);
          }
        })
        .catch(() => {
          this.getTransactions();
        });
    },
    completeCreditCardUpdate() {
      let transactionId = this.transactionId;
      if (!transactionId)
        transactionId = this.queryTransactionId;
      PaymentsService.completeCreditCardUpdate(transactionId, this.sessionId)
        .then(response => {
          if(response){
            this.showAlert("success", this.$t("message.updated"));
            const vm = this;
            setTimeout(function () {
              if (transactionId){
                vm.navigateTo({ name: 'transaction', params: { transactionId: transactionId } });
              } else {
                vm.navigateTo({
                  name: 'myacademytab',
                  params: { tabId: 2 },
                });
              }
            }, 1000);
          }
        })
        .catch(e => {
          console.log(e);
          this.navigateTo({ name: 'transaction', params: { transactionId: transactionId } });
        });
    },
    getTransactions() {
      PaymentsService.transactions()
        .then(response => {
          this.payments = response.payments;
          this.subscriptions = response.subscriptions;
          this.loading = false;
        })
        .catch(e => {
          console.log(e);
        });
    }
  },
  mounted() {},
  computed: {},
  watch: {}
};
</script>

<style scoped>
.country-display {
  height: 20px;
  vertical-align: middle;
  margin-right: 2px;
}
.title-amount{
  color:gray
}
</style>
