<template>
  <div v-if="$store.state.isUserLoggedIn">
    <div v-if="loading">
      <v-skeleton-loader class="mt-2" type="image, card-heading"></v-skeleton-loader>
    </div>
    <div v-if="!loading && user && (!user.hidden || isSessionUser(userId))">
      <v-sheet :rounded="isSmallerDisplay() ? '' : 'lg'">
        <v-card-actions v-if="isSessionUser(userId)">
        <v-spacer></v-spacer>
          <v-btn elevation="0" class="mr-1" small @click="loadIdCard()">
            <v-icon>mdi-card-account-details-outline</v-icon>
          </v-btn>
          <v-btn elevation="0" class="mr-1" small @click.stop="navigateTo({ name: 'editprofile' })">
            <v-icon>mdi-square-edit-outline</v-icon>
          </v-btn>
          <v-btn elevation="0" class="mr-1" small @click.stop="navigateTo({ name: 'settings' })">
            <v-icon>mdi-tune-variant</v-icon>
          </v-btn>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="ml-1" small elevation="0" v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
                <v-divider></v-divider>
                <v-list-item @click="inviteFriendsDialog=true">
                  <v-list-item-title>
                    {{ $t("message.invite_friends") }}
                  </v-list-item-title>
                </v-list-item>
                <v-list-item @click.stop="navigateTo({ name: 'edithistory' })">
                  <v-list-item-title>
                    {{ $t("message.belts_and_history") }}
                  </v-list-item-title>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item @click="toggleThemeColor()">
                  <v-list-item-title v-if="!isDarkTheme()">
                    <v-icon color="#1A237E">mdi-weather-night</v-icon>
                    {{ $t("message.dark_mode") }}
                  </v-list-item-title>
                  <v-list-item-title v-else>
                    <v-icon color="#FFCA28">mdi-weather-sunny</v-icon>
                    {{ $t("message.light_mode") }}
                  </v-list-item-title>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item @click="navigateTo({ name: 'viewourterms' })">
                  <v-list-item-title>
                    <v-icon class="mr-1">mdi-file-document-outline</v-icon>
                    {{ $t("message.terms_of_use") }}
                  </v-list-item-title>
                </v-list-item>
                <v-divider v-if="!isAppDisplay()"></v-divider>
                <v-list-item v-if="!isAppDisplay()" @click="logout()">
                  <v-list-item-title>
                    <v-icon class="mr-1">mdi-logout</v-icon>
                    {{ $t("message.logout") }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
          </v-menu>
        <v-spacer></v-spacer>
        </v-card-actions>
        <div align="center" class="pa-0 pb-2">
          <div v-if="userId == $store.state.user._id" class="pt-2">
            <v-tooltip v-if="progress && progress.value >= 0" right>
              <template v-slot:activator="{ on, attrs }">
                <v-progress-circular v-bind="attrs" v-on="on" :width="40" :value="progress.value" :color="progressColor"
                  :size="170" class="clickable" @click="goToProgressDetails()">
                  <UserAvatar :user="user" size="150" :has-progress="true">
                  </UserAvatar>
                </v-progress-circular>
              </template>
              <span>{{progress.template}}: {{progress.level}} ({{progress.value}}%). {{ progress.nextLevel ?
                $t("message.progress_next_level") + ': ' + progress.nextLevel : '' }}</span>
            </v-tooltip>
            <UserAvatar v-else :user="user" size="150"></UserAvatar>
          </div>
          <div v-else>
            <BeltContainer :height="100" :belt="user.belt"></BeltContainer>
            <div class="text-center" style="margin-top:-80px">
              <UserAvatar :user="user" size="150"></UserAvatar>
            </div>
          </div>
          <div class="text-center mt-2">
            <div class="text-subtitle-1 text-truncate">
              <BeltDisplay size="15" class="mr-1" :show-text="false" :belt="user.belt"></BeltDisplay>
              {{user.displayName}}
            </div>
            <div @click="copyPublicUrl()" v-if="user.identifier"
              class="pa-0 text-subtitle-2 gray--text text-truncate pt-0 clickable">
              {{user.identifier}}
              <ClipboardCopyIcon size="20" class="hero-icon"></ClipboardCopyIcon>
            </div>
            <v-container>
              <v-row class="mt-2">
                <v-col @click="linksDialog=true">
                  <div class="text-subtitle-1"><a>{{user.linkedBy ? user.linkedBy.length : 0}}</a></div>
                  <div class="text-caption"><a>{{ $t("message.links") }}</a></div>
                </v-col>
                <v-col v-if="isSessionUser(userId)" @click="goToPoints()">
                  <div class="text-subtitle-1">
                    <a>{{user.jjPoints}}</a>
                  </div>
                  <div class="text-caption"><a>JJPs</a></div>
                </v-col>
                <v-col v-else>
                  <div class="text-subtitle-1">
                    {{user.jjPoints}}</div>
                  <div class="text-caption">JJPs</div>
                </v-col>
                <v-col>
                  <div class="text-subtitle-1">{{user.postsCount}}</div>
                  <div class="text-caption">{{ $t("message.posts") }}</div>
                </v-col>
              </v-row>
              <v-divider v-if="hasMedals()"></v-divider>
              <v-row v-if="hasMedals()" class="mt-1">
                <v-col>
                </v-col>
                <v-col>
                  <v-badge :content="user.overview.bronceMedals ? user.overview.bronceMedals : '0'" overlap>
                    <IconCompMedal :color="themeColor('bronze')"></IconCompMedal>
                  </v-badge>
                </v-col>
                <v-col>
                  <v-badge :content="user.overview.silverMedals ? user.overview.silverMedals : '0'" overlap>
                    <IconCompMedal :color="themeColor('silver')"></IconCompMedal>
                  </v-badge>
                </v-col>
                <v-col>
                  <v-badge :content="user.overview.goldMedals ? user.overview.goldMedals : '0'" overlap>
                    <IconCompMedal :color="themeColor('gold')"></IconCompMedal>
                  </v-badge>
                </v-col>
                <v-col>
                </v-col>
              </v-row>
              <div v-if="user.overview.competitions > 0" class="text-overline">
                {{ $t("message.competitions") }}: {{user.overview.competitions}}
              </div>
            </v-container>
          </div>
        </div>
      </v-sheet>
      <v-sheet class="mt-2" :rounded="isSmallerDisplay() ? '' : 'lg'" v-if="$store.state.user._id != userId">
        <v-card-actions>
          <v-spacer></v-spacer>
          <link-button v-if="$store.state.user._id != userId" :linked="alreadyLinked" :userId="userId" class="mr-2">
          </link-button>
          <v-btn small elevation="0" color="primary" v-if="$store.state.user._id != userId" @click="openDialog()">message</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-sheet>
      <v-sheet :rounded="isSmallerDisplay() ? '' : 'lg'" class="mt-2">
        <div class="text-left pt-2 pb-2">
          <v-tabs v-model="infoTab" show-arrows>
            <v-tab key="overview">
              {{ $t("message.overview") }}
            </v-tab>
            <v-tab @click="loadHistory()" key="history">
              {{ $t("message.history") }}
            </v-tab>
            <v-tab key="social">
              {{ $t("message.social") }}
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="infoTab" class="info-box" style="overflow-y: auto">
            <v-tab-item key="overview">
              <v-list-item v-if="user.nationality">
                <v-list-item-action>
                  <CountryFlag size="35" :avatar="true" :country-code="user.nationality"></CountryFlag>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    <CountryDisplay :hide-image="true" :country-code="user.nationality"></CountryDisplay>
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{$t("message.nationality")}}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider v-if="user.nationality"></v-divider>
              <v-list-item v-if="user.dob">
                <v-list-item-action>
                  <CakeIcon size="35" class="hero-icon"></CakeIcon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    <dob-display :dob="user.dob" :hide-age="user.hideAge"></dob-display>
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{$t("message.dob")}}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider v-if="user.overview && user.overview.bjjSince"></v-divider>
              <v-list-item v-if="user.overview && user.overview.bjjSince">
                <v-list-item-action>
                  <ClockIcon size="35" class="hero-icon"></ClockIcon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    <dob-display :dob="user.overview.bjjSince" :hide-age="false"></dob-display>
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{$t("message.bjj_since")}}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider v-if="user.academyId"></v-divider>
              <v-list-item v-if="user.academyId">
                <v-list-item-action>
                  <LibraryIcon size="35" class="hero-icon"></LibraryIcon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    <a @click="goToAcademy(user.academyId)">
                      {{user.academy ? user.academy.name : user.academyName}}
                    </a>
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{$t("message.academy")}}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider v-if="user.belt"></v-divider>
              <v-list-item v-if="user.belt">
                <v-list-item-action>
                  <IconBelt :color="isDarkTheme() ? 'white' : 'black'" style="margin-left:-4px" width="45" height="20"
                    class="hero-icon"></IconBelt>
                </v-list-item-action>
                <v-list-item-content style="margin-left:-6px">
                  <v-list-item-title>
                    <BeltDisplay :hide-icon="true" :show-text="true" :belt="user.belt"></BeltDisplay>
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{$t("message.rank")}}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider v-if="user.specialty && !user.notBjjUser"></v-divider>
              <v-list-item v-if="user.specialty && !user.notBjjUser">
                <v-list-item-action>
                  <HandIcon size="35"></HandIcon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    {{user.specialty}}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{$t("message.specialty")}}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-tab-item>
            <v-tab-item key="history">
              <v-skeleton-loader v-if="historyLoading" type="list-item-avatar-three-line"></v-skeleton-loader>
              <template v-for="h in history">
                <v-list-item three-line :key="h._id" @click="doNothing()">
                  <v-list-item-avatar class="hidden-sm-and-down">
                    <FireIcon v-if="h.historyType == 'competition'" size="35" class="hero-icon"></FireIcon>
                    <LibraryIcon v-if="h.historyType == 'new_academy'" size="35" class="hero-icon"></LibraryIcon>
                    <ChevronDoubleUpIcon v-if="h.historyType == 'promotion'" size="35" class="hero-icon">
                    </ChevronDoubleUpIcon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>
                      <span v-if="h.academy && h.historyType == 'new_academy'">{{ $t("message.joined") }}
                        {{ h.academy.name }}</span>
                      <span v-else-if="h.historyType == 'promotion'">{{
                        $t("message.belt_promotion")
                        }}</span>
                      <span v-else>{{ h.title }}</span>
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{$d(new Date(h.date), "month_day_year", $i18n.locale)}}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                      <span v-if="h.academy && h.historyType == 'new_academy'">{{ h.academy.cityDisplay }}</span>
                      <span v-if="h.historyType == 'promotion' && h.givenBy">{{ $t("message.given_by") }}: {{ h.givenBy
                        }}</span>
                      <span v-if="h.historyType == 'competition'">
                        {{ h.federation ? h.federation + ": " : "" }}
                        {{
                        h.competitionType ? h.competitionType + ", " : ""
                        }}
                        {{ h.age ? h.age + ", " : "" }}
                        {{ h.belt ? capitalizeText(h.belt) + ", " : "" }}
                        {{ h.weight ? h.weight : "" }}
                      </span>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <div v-if="h.historyType == 'new_academy'"></div>
                    <div v-else-if="h.historyType == 'promotion'">
                      <belt-display :belt="h.belt" :show-text="false"></belt-display>
                    </div>
                    <div v-else>
                      <IconCompMedal v-if="h.place > 0" :color="getMedalColor(h.place)"></IconCompMedal>
                    </div>
                  </v-list-item-action>
                </v-list-item>
              </template>
            </v-tab-item>
            <v-tab-item key="social">
              <v-list-item dense v-if="user.social.facebook">
                <v-list-item-action>
                  <IconMeta></IconMeta>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    {{user.social.facebook ? user.social.facebook : $t("message.unknown")}}
                  </v-list-item-title>
                  <v-list-item-subtitle>Meta</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item dense v-if="user.social.instagram">
                <v-list-item-action>
                  <IconInstagram color="#C13584"></IconInstagram>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ user.social.instagram ? user.social.instagram : $t("message.unknown")}}
                  </v-list-item-title>
                  <v-list-item-subtitle>Instagram</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item dense v-if="user.social.twitter">
                <v-list-item-action>
                  <IconTwitter color="#1DA1F2"></IconTwitter>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    {{user.social.instagtwitterram ? user.social.twitter : $t("message.unknown")}}
                  </v-list-item-title>
                  <v-list-item-subtitle>Twitter</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item dense v-if="user.social.tiktok">
                <v-list-item-action>
                  <IconTiktok></IconTiktok>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    {{user.social.tiktok ? user.social.tiktok : $t("message.unknown")}}
                  </v-list-item-title>
                  <v-list-item-subtitle>Twitter</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-tab-item>
          </v-tabs-items>
        </div>
      </v-sheet>
      <v-sheet class="mt-2" :rounded="isSmallerDisplay() ? '' : 'lg'"
        v-if="(user.masterId || user.masterName) && !user.notBjjUser">
        <v-card-title class="text-overline">
          {{ $t("message.lineage") }}
        </v-card-title>
        <div class="pa-4 pt-0">
          <LineageDisplay :master="user.masterName" :user-id="this.userId" :master-id="user.masterId"
            :user="user.fName" />
        </div>
      </v-sheet>
      <v-sheet class="mt-2 pb-0" :rounded="isSmallerDisplay() ? '' : 'lg'"
        v-if="!user.hidden && checkinsByAcademy && academyLocation">
        <v-card-title class="text-overline">
          <span v-html="$t('message.checkins_aggregates_explained', [totalCheckins, checkinsByAcademy.length])"></span>
        </v-card-title>
        <div xs12 v-if="!user.hidden && checkinsByAcademy && academyLocation">
          <v-card elevation="0">
            <CheckinsMap :academies="checkinsByAcademy"></CheckinsMap>
          </v-card>
        </div>
      </v-sheet>
      <v-sheet class="mt-2 pb-0" :rounded="isSmallerDisplay() ? '' : 'lg'" v-if="images && images.length >= 3">
        <v-card-title class="text-overline">
          {{ $t("message.photos") }}
        </v-card-title>
        <image-gallery :images="images" :color="backGroundColor"></image-gallery>
        <v-card-actions>
          <v-btn block class="text-caption" v-if="viewMoreImages" text color="primary"
            @click="navigateTo({ name: 'viewuserimages'})">
            {{ $t("message.view_more") }}
          </v-btn>
        </v-card-actions>
      </v-sheet>
      <div v-if="!user.hidden">
        <v-sheet v-if="postsLoading || (posts && posts.length > 0)" class="mt-2 pb-0"
          :rounded="isSmallerDisplay() ? '' : 'lg'">
          <v-card-title class="text-overline">
            {{ $t("message.posts") }}
          </v-card-title>
        </v-sheet>
        <PostsDisplay v-if="posts && posts.length > 0" :posts="posts">
        </PostsDisplay>
        <v-skeleton-loader v-if="postsLoading" class="mx-auto mt-2" type="card"></v-skeleton-loader>
        <v-card-actions>
          <v-btn block small elevation="0" @click="getPosts(true)" color="primary">
            {{ $t("message.view_more") }}
          </v-btn>
        </v-card-actions>
      </div>

      <new-message :recipient="messageRecipient" v-model="newMessageDialog"></new-message>

      <div style="height:20px">

      </div>
    </div>
    <v-dialog v-model="inviteFriendsDialog" max-width="80%">
      <v-card outlined class="pa-2" v-if="user && user.academy">
        <v-card-title class="text-subtitle-1">
          {{ $t("message.invite_friends_bjjlink") }}
        </v-card-title>
        <v-card-text class="pa-2" v-if="user && user.academy && user.academy._id">
          <v-switch v-model="inviteToAcademy"
            :label="$t('message.auto_add_to_academy') + (user.academy ? ': '+ user.academy.name : '')">
          </v-switch>
        </v-card-text>
        <v-card-text dark class="pt-2 text-truncate text-caption">
          {{inviteFriendsLink}}
        </v-card-text>
        <v-card-actions>
          <v-btn small color="primary" @click.stop="copyInviteFriendsLink()">
            <ClipboardCopyIcon class="hero-button"></ClipboardCopyIcon>
            {{$t("message.copy_link")}}
          </v-btn>
          <v-btn small @click.stop="inviteFriendsDialog=false">{{
            $t("message.close")
            }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="linksDialog" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card elevation="0">
        <v-toolbar dense color="primary">
          <v-spacer></v-spacer>
          <v-btn icon @click="linksDialog = false">
            <XCircleIcon class="hero-button"></XCircleIcon>
          </v-btn>
        </v-toolbar>
        <UsersList :users="links"></UsersList>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="idCardDialog"
      transition="dialog-top-transition"
      width="100%"
    >
      <v-card outlined light>
        <div class="pa-2" style="overflow: hidden">
          <div v-if="loadingIDCard" class="text-center">
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
          </div>
          <div v-else-if="idCard">
            <div>
              <div v-if="familyMembers && familyMembers.length > 0">
                <v-select @change="loadIdCard()" v-model="selectedFamilyMember" :items="familyMembers"
                  item-text="name" item-value="userId" :label="$t('message.select_one')" clearable outlined dense>
                </v-select>
              </div>
            </div>
            <div v-if="idCard.nationality">
              <country-flag
                :countryCode="idCard.nationality"
              ></country-flag>
            </div>
            <table width="100%">
              <tr>
                <td style="vertical-align: top" class="text-center">
                  <v-avatar size="60px" class="grey lighten-4 clickable">
                    <img v-if="!$store.state.userImage" :src="assets('blank-profile-picture.png')" alt="avatar" />
                    <img v-else :src="userFile($store.state.userImage)" alt="avatar" />
                  </v-avatar>
                </td>
              </tr>
              <tr>
                <td class="text-center">
                  <div class="text-h6 truncate-text">
                    {{ idCard.name }}
                  </div>
                  <div v-if="idCard.academy" class="text-subtitle-2 truncate-text">
                    {{ idCard.academy }}
                  </div>
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <div v-if="idCard.membershipStatus" class="text-center text-h4 pa-2">
                    <div v-if="idCard.membershipStatus == 'active'" class="success--text">
                      {{ $t("message.active").toUpperCase() }}
                    </div>
                    <div v-else class="error--text">
                      {{ $t("message.inactive").toUpperCase() }}
                    </div>
                  </div>
                </td>
              </tr>
            </table>
            <div v-if="idCard && idCard.verificationUrl" class="text-center pa-2">
              <div class="text-caption">{{ $t('message.scan_to_verify_membership') }}</div>
              <div>
                <vue-qr :text="idCard.verificationUrl" :size="200"></vue-qr>
              </div>
            </div>
            <v-card-actions>
              <v-avatar v-if="idCard.academyImage" size="25px" class="grey lighten-4 clickable">
                <img :src="userFile(idCard.academyImage)" alt="avatar" />
              </v-avatar>
              <v-spacer></v-spacer>
              <div class="text-right text-caption">
                <span class="mr-1">{{ $t("message.member_since") }}</span>
                <span>{{ $d(new Date(idCard.memberSince), 'month_day_year', this.$i18n.locale)}}</span>
              </div>
            </v-card-actions>
          </div>
          <div v-else class="text-center error--text text-h6">
            {{ $t('message.invalid') }}
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import AuthenticationService from "@/services/AuthenticationService";
import AcademyService from "@/services/AcademyService";
import PostService from "@/services/PostService";
import ConnectService from "@/services/ConnectService";
import UserService from "@/services/UserService";
import LineageService from "@/services/LineageService";
import SyllabusService from "@/services/SyllabusService";
import CountryDisplay from "@/components/displays/CountryDisplay.vue";
import DobDisplay from "@/components/displays/DobDisplay.vue";
import BeltDisplay from "@/components/displays/BeltDisplay.vue";
import LineageDisplay from "@/components/displays/LineageDisplay.vue";
import LinkButton from "@/components/displays/LinkUserButtonDisplay.vue";
import UsersList from "@/components/displays/UsersListDisplay.vue";
import IconBelt from "@/components/displays/icons/IconBelt.vue";
import IconTwitter from "@/components/displays/icons/IconTwitter.vue";
import IconInstagram from "@/components/displays/icons/IconInstagram.vue";
import IconTiktok from "@/components/displays/icons/IconTiktok.vue";
import IconMeta from "@/components/displays/icons/IconMeta.vue";
import NewMessage from "@/components/displays/NewMessageDisplay.vue";
import NotificationService from "@/services/NotificationService";
import ImageGallery from "@/components/displays/ImageGallery.vue";
import CheckinsMap from "@/components/displays/CheckinsMapDisplay.vue";
import BeltContainer from "@/components/displays/BeltContainer";
import IconCompMedal from "@/components/displays/icons/IconCompMedal";
import UserAvatar from "@/components/displays/UserAvatarDisplay";
import VueQr from 'vue-qr'
import CountryFlag from "@/components/displays/CountryFlagDisplay.vue";
import PostsDisplay from "@/components/displays/PostsDisplay.vue";


var moment = require("moment");

//Icons
import { 
  CakeIcon,
  ClockIcon,
  LibraryIcon,
  HandIcon,
  XCircleIcon,
  ClipboardCopyIcon,
  ChevronDoubleUpIcon,
  FireIcon,
} from '@vue-hero-icons/outline'

export default {
  data() {
    return {
      userId: this.$route.params.userId ? this.$route.params.userId : this.$store.state.user._id,
      inviteFriendsQueryParam: this.$route.query.invite,
      notificationId: this.$route.params.notificationId,
      managedAcademy: this.$store.state.userManagedAcademy,
      user: null,
      infoTab: 0,
      links: null,
      history: null,
      idCard: null,
      loadingIDCard: false,
      idCardDialog: null,
      linksDialog: null,
      loading: true,
      sectionHeight: "max-height:390px",
      historyLoading: true,
      linksLoading: true,
      alreadyLinked: false,
      backGroundColor: null,
      inviteFriendsDialog: false,
      pointer: 0,
      newMessageDialog: false,
      messageRecipient: null,
      masterName: null,
      inviteToAcademy: false,
      academyLocation: null,
      selectedFamilyMember: null,
      images: null,
      progress: null,
      totalCheckins: 0,
      checkinsByAcademy: null,
      viewMoreImages: false,
      postsLoading: true,
      posts: [],
      familyMembers: [],
      viewMorePosts:false,
    };
  },
  components: {
    CountryDisplay,
    DobDisplay,
    BeltDisplay,
    LineageDisplay,
    LinkButton,
    IconBelt,
    UsersList,
    NewMessage,
    ImageGallery,
    IconTwitter,
    IconInstagram,
    IconTiktok,
    CheckinsMap,
    BeltContainer,
    IconCompMedal,
    UserAvatar,
    CountryFlag,
    CakeIcon,
    ClockIcon,
    LibraryIcon,
    HandIcon,
    XCircleIcon,
    IconMeta,
    ClipboardCopyIcon,
    ChevronDoubleUpIcon,
    FireIcon,
    VueQr,
    PostsDisplay
  },
  beforeRouteUpdate(to, from, next) {
    this.userId = to.params.userId;
    this.loading = true;
    this.historyLoading = true;
    this.postsLoading = true;
    this.posts = [];
    this.linksLoading = true;
    this.alreadyLinked = false;
    this.linksDialog = false;
    if (this.isValidId(this.userId)) {
      this.initializeProfile();
      next();
    } else {
      this.navigateTo({ name: "home" });
    }
  },
  async created() {
    if (this.isValidId(this.userId)) this.initializeProfile();
    else this.navigateTo({ name: "home" });
    if (this.notificationId) this.readNotification();
    const vm = this;
    vm.getMessagesCount(function (countM) {
      vm.getNotificationsCount(function (countS) {
        vm.$store.dispatch("setMessagesCount", countM);
        vm.$store.dispatch("setNotifications", countS);
        if (countM + countS == 0) {
          //NotificationService.clearMobile();
        }
      });
    });
    if(this.inviteFriendsQueryParam && this.inviteFriendsQueryParam == 'true' && 
      this.isSessionUser(this.userId)){
      this.inviteFriendsDialog = true;
    }
  },
  methods: {
    getFamilyMembers() {
      if (this.managedAcademy) {
        AcademyService.academyFamily(this.managedAcademy)
          .then((data) => {
            if (data && data.members && data.members.length > 0) {
              const familyMembers = [{
                name: this.$t('message.you'),
                userId: null
              }];
              this.familyMembers = familyMembers.concat(data.members);
            }
          });
      }
    },
    async getPosts(append) {
      this.postsLoading = true;
      this.viewMorePosts = false;
      if (!append) this.posts = [];
      PostService.userPosts(this.userId, this.pointer)
        .then((data) => {
          if (data) {
            this.posts = this.posts.concat(data.posts);
            this.postsLoading = false;
            this.pointer = this.pointer + data.count;
            if (data.count >= this.searchesLimit) this.viewMorePosts = true;
          }
        });
    },
    logout() {
      AuthenticationService.logout();
      this.$store.dispatch("setToken", null);
      this.$store.dispatch("setUser", null);
      this.$store.dispatch("setInApp", null);
      localStorage.removeItem("token");
      window.location.replace("/login");
    },
    async loadIdCard() {
      if (!this.idCardDialog)
        this.getFamilyMembers()
      this.idCardDialog = true;
      this.loadingIDCard = true;
      const me = await ConnectService.getMe(true, this.selectedFamilyMember);
      if (me.data && me.data.idCard) {
        this.idCard = me.data.idCard;
      }
      this.loadingIDCard = false;
      this.trackEvent('user', 'view_id_card', this.$store.state.user._id);
    },
    getCheckinsByAcademy(){
      this.checkinsByAcademy = null;
      UserService.checkinsByAcademy(this.userId)
        .then((data) => {
          if(data && data.checkins && data.checkins.length > 0)
            this.checkinsByAcademy = data.checkins;
          if(data && data.academy){
            this.totalCheckins = data.total;
            if(!isNaN(data.academy.latitude)){
              this.academyLocation = {lat: data.academy.latitude, lng: data.academy.longitude};
            }
          }
        });
    },
    toggleThemeColor() {
      this.darkTheme = !this.darkTheme;
      this.$vuetify.theme.dark = this.darkTheme;
      //push color update
      let theme = 'light';
      if (this.darkTheme)
        theme = 'dark';
      this.$store.dispatch("setTheme", theme);
      //Make Api Call
      UserService.toggleTheme();
      this.$router.go();
    },
    goToPoints(){
      if(this.isSessionUser(this.userId))
        this.navigateTo({ name: "points"});
    },
    copyInviteFriendsLink(){
      let urlToCopy = this.inviteFriendsLink;
      this.copyToClipboard(urlToCopy);
    },
    copyPublicUrl() {
      if(this.user.identifier){
        let urlToCopy = 'https://bjjlink.com/pub/' + this.user.identifier;
        this.copyToClipboard(urlToCopy);
      }
    },
    goToProgressDetails(){
      if(this.progress && this.progress.showDetails){
        this.navigateTo({ name: "viewprogressdetails", params: { instanceId: this.progress.instanceId } });
      }
    },
    reloadToEditProfile() {
      this.navigateTo({ name: 'editprofile' });
    },
    doNothing() {},
    openDialog() {
      this.newMessageDialog = true;
    },
    readNotification() {
      NotificationService.read(this.notificationId);
    },
    loadHistory(){
      UserService.history(this.userId)
        .then((data) => {
          this.history = data;
          this.historyLoading = false;
        });
    },
    initializeProfile() {
      this.user = null;
      this.loading = true;
      this.images = null;
      if (this.$store.state.user._id == this.userId){ 
        this.alreadyLinked = true;
        UserService.myAcademy()
          .then((data) => {
            if(data && data.managedAcademy)
              this.$store.dispatch("setUserManagedAcademy", data.managedAcademy);
            else
              this.$store.dispatch("setUserManagedAcademy", null);
          });
      }
      UserService.userImages(this.userId, 0)
        .then((data) => {
          let tmp = [];
          let index = 0;
          let imagesSize = this.isSmallerDisplay() ? 6 : 8;
          if(data.images.length > 0){
            for(const i of data.images){
              if(index > imagesSize - 1)
                break;
              else
                tmp.push(i);
              index++;
            }
            this.images = tmp;
            this.backGroundColor = this.getBeltColor(data.user.belt);
            if(data.images.length > imagesSize)
              this.viewMoreImages = true;
          }
        });
      UserService.user(this.userId)
        .then((data) => {
          this.user = data;
          if(!this.user.social)
            this.user.social = {};
          if (!this.user.hidden) {
            if (this.user.masterId) {
              if (this.user.masterId) {
                LineageService.getNode(this.user.masterId)
                  .then((data) => {
                    this.masterName = data.name;
                  })
                  .catch();
              }
            } else {
              const vm = this;
              //If not available... lets wait and do it again since it will be updated soon
              setTimeout(function () {
                if (vm.user.masterId) {
                  LineageService.getNode(vm.user.masterId)
                    .then((data) => {
                      vm.masterName = data.name;
                    })
                    .catch();
                }
              }, 2000);
            }
            this.getCheckinsByAcademy();
            this.messageRecipient = this.user;
            var i = this.user.linkedBy.indexOf(this.$store.state.user._id);
            if (i >= 0) this.alreadyLinked = true;
            if (this.$store.state.user._id == this.userId) {
              this.$store.dispatch(
                "setUserImage",
                data.userImageSmall ? data.userImageSmall : data.userImage
              );
              this.$store.dispatch("setBelt", data.belt);
            }
            UserService.getLinkedBy(this.userId)
              .then((data) => {
                this.links = data;
                this.linksLoading = false;
              });
            this.getSyllabusProgress();
            this.getPosts();
            this.loading = false;
          } else {
            this.loading = false;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    goToProfile(id) {
      this.navigateTo({ name: "userprofile", params: { userId: id } });
    },
    getSectionColor() {
      var style = "";
      //console.log(this.user.belt+ ' - ' + this.getBeltColor(this.user.belt));
      const beltColor = this.getBeltColor(this.user.belt);
      if(this.user.belt == 'red_white'){
        style += "background: repeating-linear-gradient(90deg,white,white 10px,"+beltColor+" 10px,"+beltColor+" 20px);"
      }else if(this.user.belt == 'red_black'){
        style += "background: repeating-linear-gradient(90deg,black,black 10px,"+beltColor+" 10px,"+beltColor+" 20px);"
      }
      style += "background-color:" + beltColor + ";";
      if (this.user.belt == "white") style += "color:black;";
      return style;
    },
    hasMedals() {
      if (
        this.user.overview.goldMedals > 0 ||
        this.user.overview.silverMedals > 0 ||
        this.user.overview.bronceMedals > 0
      )
        return true;
      return false;
    },
    getSyllabusProgress(){
      if(this.$store.state.user._id == this.userId){
      SyllabusService.progress()
        .then((data) => {
          this.progress = data.progress;
          if(this.progress.jjTemplate){
            this.progressColor = this.getBeltColorForProgress(this.progress.level);
          }else if(this.progress.color){
            this.progressColor = this.progress.color;
          }else{
            this.progressColor = this.getColor('light');
          }
        });
      }
    },
  },
  mounted() {},
  computed: {
    age: function () {
      return this.user ? "(" + moment().diff(this.user.dob, "years") + ")" : "";
    },
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
    inviteFriendsLink(){
      if(this.$store.state.user._id && this.user && this.user.academy){
        return 'https://bjjlink.com/register?i=' + btoa(this.$store.state.user._id) + 
          (this.inviteToAcademy && this.user.academy ? '&a=' + btoa(this.user.academy._id) : '') + 
          (this.inviteToAcademy && this.user.academy ? '&an=' + btoa(this.user.academy.name) : '');
      }
      return null;
    }
  },
};
</script>

<style scoped>
</style>
