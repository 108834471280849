<template>
  <div class="fill-height" v-if="!$store.state.hidden">
    <v-sheet :rounded="isSmallerDisplay() ? '' : 'lg'" class="pa-2 pb-0">
      <v-card-title class="text-caption ma-0 pl-0 pt-0">
        <img class="mr-2" style="width:25px;vertical-align: middle;" :src="assets()+'/external-logos/ibjjf-logo.png'" />
        {{ $t("message.data_provided_by_ibjjf") }}
        <v-spacer></v-spacer>
        <v-btn color="primary" v-if="selectedTournament" elevation="0" small @click="viewSavedCompetitors()">
          <StarIcon size="20" class="hero-button"></StarIcon>
          <span v-if="!isSmallerDisplay()">{{ $t("message.saved_competitors") }}</span>
        </v-btn>
      </v-card-title>
      <v-container>
        <v-row class="mt-0 pt-0 pb-0 mb-0" v-show="selectedTournament && selectedTournament.active">
          <v-col colclass="mt-0 pt-0 pb-2 mb-0">
            <v-select @change="selectTournament()" :items="tournaments" v-model="selectedTournament"
              :label="$t('message.tournament')" item-text="name" :return-object="true" dense rounded outlined>
            </v-select>
          </v-col>
          <v-col sm="12" class="mt-0 pt-0 pb-0 mb-0 text-center" v-if="hasSavedCompetitors">
            <v-btn text outlined small color="primary" @click="viewAllSaved()">
              <StarIcon class="mr-1 hero-button" size="20"></StarIcon>
              {{ $t("message.view_saved_competitors") }}</v-btn>
          </v-col>
        </v-row>
        <v-row v-if="selectedTournament && !selectedTournament.active">
          <v-col class="mt-0 pt-0 pb-0 mb-0 text-subtitle-1 text-center">
            {{selectedTournament.name}}
          </v-col>
        </v-row>
        <v-row class="mt-0 pt-0 pb-0 mb-0" v-if="!selectedTournament && !loadingTournaments">
          <v-col cols="12" sm="12" class="mt-0 pt-0 pb-0 mb-0 text-center" v-if="hasSavedCompetitors">
            <v-btn v-if="!selectedTournament" block text outlined small color="primary" elevation="0" @click="viewSavedCompetitors()">
              <StarIcon size="20" class="hero-button"></StarIcon>
              <span>{{ $t("message.view_saved_competitors") }}</span>
            </v-btn>
            <v-btn class="mt-1" block text outlined small color="primary" @click="viewAllSaved()">
              <StarIcon class="mr-1 hero-button" size="20"></StarIcon>
              {{ $t("message.view_saved_competitors_matches") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
      <v-list three-line v-if="!selectedTournament && !loadingTournaments" class="mt-0">
        <div v-html="$t('message.ongoing_tournaments', [this.tournaments.length])" class="ml-2 primary--text text-caption"></div>
        <template v-for="(e, index) in tournaments">
          <v-list-item link :key="'tourney_list_item_'+index" @click="selectedTournament=e;selectTournament()">
            <v-list-item-content>
              <v-list-item-subtitle class="text-caption">
                <b>{{ e.name }}</b>
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                <RefreshIcon style="margin-bottom:-2px" class="rotate" size="15" v-if="isOngoing(e)"></RefreshIcon>
                <b>{{ $t("message.first_day") }}:</b>
                <span class="ml-1 mr-2">{{$d(new Date(e.firstDay), "month_day_year", $i18n.locale)}}</span>
                <span class="">•</span>
                <span class="ml-2">{{e.days.length}} {{ $t("message.days") }}</span>
              </v-list-item-subtitle>
              <v-list-item-subtitle>{{e.competitors.length}} {{ $t("message.competitors") }}</v-list-item-subtitle>
              <v-list-item-subtitle v-if="e.savedCompetitors > 0">
                <StarIcon style="margin-bottom:-2px" :color="themeColor('primary')" size="15" class="mr-2"></StarIcon>
                {{e.savedCompetitors}} {{ $t("message.favorite_competitors_fighting") }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider :key="'tourney_list_divider_'+index"></v-divider>
        </template>
      </v-list>
    </v-sheet>
    <v-skeleton-loader v-if="loadingTournaments"
      type="list-item-three-line, list-item-three-line, list-item-three-line"></v-skeleton-loader>
    <v-sheet v-if="!selectedTournament && !loadingTournaments" :rounded="isSmallerDisplay() ? '' : 'lg'"
      class="pa-2 mt-2 pb-0">
      <v-list three-line>
        <v-subheader>{{ $t("message.past_tournaments") }}</v-subheader>
        <template v-for="(e, index) in pastTournaments">
          <v-list-item link :key="'past_tourney_list_item_'+index">
            <v-list-item-content>
              <v-list-item-subtitle>
                <span>{{ e.name }}</span>
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                <b>{{ $t("message.first_day") }}:</b>
                <span class="ml-1 mr-2">{{$d(new Date(e.firstDay), "month_day_year", $i18n.locale)}}</span>
                <span class="">•</span>
                <span class="ml-2">{{e.days.length}} {{ $t("message.days") }}</span>
              </v-list-item-subtitle>
              <v-list-item-subtitle>{{e.competitors.length}} {{ $t("message.competitors") }}</v-list-item-subtitle>
              <v-list-item-subtitle v-if="e.savedCompetitors > 0">
                <StarIcon style="margin-bottom:-2px" :color="themeColor('primary')" size="15" class="mr-2"></StarIcon>
                {{e.savedCompetitors}} {{ $t("message.favorite_competitors_competed") }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider :key="'past_tourney_list_divider_'+index"></v-divider>
        </template>
      </v-list>
    </v-sheet>
    <v-sheet v-if="selectedTournament" :rounded="isSmallerDisplay() ? '' : 'lg'" class="pa-2 mt-2 pb-0">
      <v-container v-if="selectedTournament.active">
        <div class="text-center mb-2 text-caption">{{ selectedTournament.name }}</div>
        <div v-if="hasSavedCompetitors" class="text-center mb-2 text-caption primary--text">
          <StarIcon style="margin-bottom:-2px" :color="themeColor('primary')" size="15" class="mr-2"></StarIcon>
          <span>
            {{ selectedTournament.savedCompetitors}} {{ $t("message.favorite_competitors_fighting") }}
          </span>
        </div>
        <v-row class="mt-0 pt-0 pb-0 mb-0"
          v-if="selectedTournament && hasSavedCompetitors && savedCompetitorsFiltered.length > 0">
          <v-col class="mt-0 pt-0 pb-0 mb-0">
            <v-autocomplete clearable :label="$t('message.saved_competitor')" @change="filterEntries('saved')" outlined
              dense :items="savedCompetitorsFiltered" item-text="name" item-value="ibjjfId"
              v-model="selectedSavedCompetitor">
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-row class="mt-0 pt-0 pb-0 mb-0" v-if="selectedTournament.active">
          <v-col class="mt-0 pt-0 pb-0 mb-0">
            <v-autocomplete clearable :label="$t('message.competitor')" @change="filterEntries('competitor')"
              :loading="loadingFilters" outlined dense :items="competitors" item-text="name" item-value="ibjjfId"
              v-model="selectedCompetitor">
            </v-autocomplete>
          </v-col>
          <v-col class="mt-0 pt-0 pb-0 mb-0" v-if="selectedTournament.active">
            <v-autocomplete clearable :label="$t('message.affiliation_generic')" @change="filterEntries('affiliation')"
              :loading="loadingFilters" outlined dense :items="affiliations" item-text="name" item-value="name"
              v-model="selectedAffiliation">
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-row class="mt-0 pt-0 pb-0 mb-0" v-if="selectedTournament && selectedTournament.active">
          <v-col class="mt-0 pt-0 pb-0 mb-0 pl-2">
            <v-select @change="selectTournamentDay()" :items="days" v-model="selectedDay" :label="$t('message.day')"
              dense outlined>
            </v-select>
          </v-col>
          <v-col class="mt-0 pt-0 pb-0 mb-0">
            <v-select @change="filterEntries()" :items="mats" v-model="selectedMat" :label="'Mat'"
              :loading="loadingMats" dense outlined>
            </v-select>
          </v-col>
        </v-row>
      </v-container>
      <v-container v-else class="mb-0 pb-0">
        <v-card-text class="pb-0 mb-0">
          <v-autocomplete :label="$t('message.bracket_category')" @change="openDivisionBracket()" outlined dense
            :items="divisions" item-text="name" item-value="ibjjfId" v-model="selectedBracket">
          </v-autocomplete>
        </v-card-text>
        <v-card-text class="mt-0 pt-0" v-if="savedCompetitorsFiltered.length > 0">
          <div class="mb-1">
            <StarIcon style="margin-bottom:-2px" :color="themeColor('primary')" size="15" class="mr-2"></StarIcon>
            {{ savedCompetitorsFiltered.length - 1 }} {{ $t("message.favorite_competitors_competed") }}
          </div>
          <div>
            <template v-for="(i, index) in savedCompetitorsFiltered">
              <v-chip class="mt-1" v-if="i.ibjjfId != 'all'" :key="'saved_comp_past_event_'+index">{{i.name}}</v-chip>
            </template>
          </div>
        </v-card-text>
      </v-container>
      <v-card-actions class="ma-0 pa-0" v-if="selectedTournament">
        <div v-if="selectedTournament.active">
          <div class="text-caption gray--text">{{$t('message.last_updated')}}</div>
          <div class="text-subtitle-1 primary--text">{{lastUpdated}}</div>
        </div>
        <v-spacer></v-spacer>
        <v-btn color="primary" v-if="selectedTournament.active" :disabled="loadingEntries" icon small
          @click="filterEntries()" :class="loadingEntries ? 'rotate' : ''">
          <RefreshIcon class="hero-button"></RefreshIcon>
        </v-btn>
        <v-btn v-if="selectedTournament.active && divisions && divisions.length" icon small
          @click="viewSelectBracketDialog=true">
          <IconLineage size="20px" color="white" style="margin-bottom:0px"></IconLineage>
        </v-btn>
        <v-btn icon small @click="viewDetailsDialog=true">
          <InformationCircleIcon class="hero-button"></InformationCircleIcon>
        </v-btn>
      </v-card-actions>
    </v-sheet>
    <v-sheet v-if="selectedTournament" :rounded="isSmallerDisplay() ? '' : 'lg'" class="pa-0 mt-2">
      <v-card-actions v-if="selectedTournament">
        <v-spacer></v-spacer>
        <v-btn elevation="0" small @click="selectedTournament = null;">{{$t('message.back')}}</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-sheet>
    <v-sheet v-if="selectedTournament && entries.length > 0" :rounded="isSmallerDisplay() ? '' : 'lg'"
      class="pa-2 mt-2">
      <div v-if="loadingEntries">
        <v-skeleton-loader type="article, article, article"></v-skeleton-loader>
      </div>
      <div v-if="resultsDate" class="text-subtitle-2">
        <span>{{$t('message.date')}}:</span>
        <span class="ml-2">{{$d(new Date(resultsDate), "month_day_year", $i18n.locale)}}</span>
      </div>
      <div v-if="entries.length > 0" class="text-caption gray--text">
        {{$t('message.times_are_local')}}
        <span v-if="selectedTournament && selectedTournament.timeZone">
          <b>{{selectedTournament.timeZone}}</b>
        </span>
      </div>
      <template v-for="(e, index) in entries">
        <v-card outlined :key="'entry_'+index" class="mt-2 pl-2 pr-2">
          <v-card-actions class="text-subtitle-1 pb-0 pt-0 pl-0">
            <CheckCircleIcon color="green" v-if="e.statusColor == 'green' && e.completed" size="20" class="hero-icon">
            </CheckCircleIcon>
            <StopIcon color="error" v-else-if="e.isLast" size="20" class="hero-icon"></StopIcon>
            <RefreshIcon color="green" v-else-if="e.isFirst" size="20" class="hero-icon rotate"></RefreshIcon>
            <BellIcon color="yellow" v-else-if="e.statusColor == 'yellow'" size="20" class="hero-icon"></BellIcon>
            <ClockIcon color="orange" v-else-if="e.statusColor == 'orange'" size="20" class="hero-icon"></ClockIcon>
            <ArrowCircleUpIcon color="gray" v-else size="20" class="hero-icon"></ArrowCircleUpIcon>
            <span class="ml-1">{{e.timeString}}</span>
            <span class="ml-2">({{e.round}})</span>
            <span v-if="!selectedMat" class="ml-2"> • mat {{e.mat}}</span>
            <v-spacer></v-spacer>
            <span v-if="!selectedDay && e.day" class="text-overline gray--text mr-2">{{$t('message.day')}} {{e.day}} •
            </span>
            <span v-if="e.fightNumber" class="text-overline gray--text">{{$t('message.fight')}} {{e.fightNumber}}</span>
          </v-card-actions>
          <v-card-text class="gray--text text-subtitle-2 pb-0 pt-0 pl-0">
            <BeltDisplay size="15" :show-text="false" :belt="e.belt" class="mr-2"></BeltDisplay>{{e.age}} / {{e.gender}}
            / {{e.weight}}
          </v-card-text>
          <v-divider class="mb-2"></v-divider>
          <table width="100%">
            <tr>
              <td style="width:42%;vertical-align: top;" class="text-left">
                <v-card v-if="e.competitorOne" elevation="0">
                  <div class="competitor-name text-subtitle-1 pb-0 pt-0 text-left font-weight-bold">
                    <CheckIcon v-if="e.completed && !e.competitorOne.loser" class="hero-icon" size="20"></CheckIcon>
                    <span
                      :class="(e.competitorOne.loser ? 'text-decoration-line-through' : '')+' '+((selectedCompetitor || selectedSavedCompetitor) && e.competitorOne.selected ? 'primary--text' : '')">{{e.competitorOne.name.toUpperCase()}}</span>
                  </div>
                  <v-card-text class="pt-0 pb-0 competitor-aff text-left pl-0">
                    <span
                      :class="selectedAffiliation && e.competitorOne.selected ? 'primary--text' : ''">{{e.competitorOne.affiliation}}</span>
                  </v-card-text>
                  <v-card-actions class="pl-0">
                    <span v-if="e.competitorOne.seed" class="text-caption">{{$t('message.seed')}}
                      {{e.competitorOne.seed}}</span>
                    <v-spacer></v-spacer>
                    <div v-if="e.competitorOne.id">
                      <v-btn small v-if="e.competitorOne.isSaved" icon
                        @click="updateSavedCompetitor(e.competitorOne.id, e.competitorOne, 'remove')">
                        <StarIcon :color="themeColor('gold')" size="20" class="hero-button"></StarIcon>
                      </v-btn>
                      <v-btn v-else small icon
                        @click="updateSavedCompetitor(e.competitorOne.id, e.competitorOne, 'add')">
                        <StarIcon color="gray" size="20" class="hero-button"></StarIcon>
                      </v-btn>
                    </div>
                  </v-card-actions>
                </v-card>
              </td>
              <td style="width:16%;vertical-align: middle;" class="text-center">
                <div class="text-h3">vs</div>
                <div v-if="e.bracketUrl">
                  <v-btn icon small @click="openBracketUrl(e.bracketUrl)">
                    <IconLineage size="18px" style="margin-bottom:0px"></IconLineage>
                  </v-btn>
                </div>
                <!-- IBJJF returns data in portuguese so we cant find matches unless is in english
                <div v-else-if="divisions && divisions.length > 0 && e.weight != 'Open Class'">
                  <v-btn icon small @click="showBracket(e)">
                    <IconLineage size="18px" style="margin-bottom:0px"></IconLineage>
                  </v-btn>
                </div>
                -->
              </td>
              <td style="width:42%;vertical-align: top;" class="text-left">
                <v-card v-if="e.competitorTwo" elevation="0">
                  <div class="competitor-name text-subtitle-1 pb-0 pt-0 text-left font-weight-bold">
                    <CheckIcon v-if="e.completed && !e.competitorTwo.loser" class="hero-icon" size="20"></CheckIcon>
                    <span
                      :class="(e.competitorTwo.loser ? 'text-decoration-line-through' : '')+' '+((selectedCompetitor || selectedSavedCompetitor) && e.competitorTwo.selected ? 'primary--text' : '')">{{e.competitorTwo.name.toUpperCase()}}</span>
                  </div>
                  <v-card-text class="pt-0 pb-0 competitor-aff text-left pl-0">
                    <span
                      :class="selectedAffiliation && e.competitorTwo.selected ? 'primary--text' : ''">{{e.competitorTwo.affiliation}}</span>
                  </v-card-text>
                  <v-card-actions class="pl-0">
                    <span class="text-caption" v-if="e.competitorTwo.seed">{{$t('message.seed')}}
                      {{e.competitorTwo.seed}}</span>
                    <v-spacer></v-spacer>
                    <div v-if="e.competitorTwo.id">
                      <v-btn small v-if="e.competitorTwo.isSaved" icon
                        @click="updateSavedCompetitor(e.competitorTwo.id, e.competitorTwo, 'remove')">
                        <StarIcon :color="themeColor('gold')" size="20" class="hero-button"></StarIcon>
                      </v-btn>
                      <v-btn v-else small icon
                        @click="updateSavedCompetitor(e.competitorTwo.id, e.competitorTwo, 'add')">
                        <StarIcon color="gray" size="20" class="hero-button"></StarIcon>
                      </v-btn>
                    </div>
                  </v-card-actions>
                </v-card>
              </td>
            </tr>
          </table>
          <v-divider v-if="isDifferentTimeZone && e.time"></v-divider>
          <v-card-text v-if="isDifferentTimeZone && e.time && e.timeString" class="text-subtitle-2 text-left ma-0 pl-0 pt-1 pb-1">
            <v-icon size="20" class="mr-1" color="primary">mdi-airplane-clock</v-icon>
            <span>{{$t('message.your_time')}}:</span>
            <span class="ml-1">
              {{ timeStringToTimeZone(e.timeString) }}
            </span>
            <span class="ml-2 mr-2">•</span>
            <span>
              {{myTimeZone}}
            </span>
          </v-card-text>
          <v-card-text v-if="e.isLast" class="text-overline text-center">
            <StopIcon color="error" size="20" class="hero-icon"></StopIcon>
            {{$t('message.end_of_mat')}}
          </v-card-text>
        </v-card>
      </template>
    </v-sheet>
    <v-dialog v-model="viewSelectBracketDialog" width="80%">
      <v-card elevation="0" outlined v-if="selectedTournament">
        <v-card-title>
          {{$t('message.view_bracket')}}
        </v-card-title>
        <v-card-text>
          <v-autocomplete :label="$t('message.bracket_category')" @change="openDivisionBracket()" outlined dense
            :items="divisions" item-text="name" item-value="ibjjfId" v-model="selectedBracket">
          </v-autocomplete>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn small @click="viewSelectBracketDialog=false">
            {{$t('message.close')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="viewDetailsDialog" width="80%">
      <v-card elevation="0" outlined v-if="selectedTournament">
        <v-card-title>
          {{selectedTournament.name}}
        </v-card-title>
        <v-card-text>
          <div>
            <span class="text-subtitle-1 mr-2">{{$t('message.first_day')}}</span>
            <span class="text-body-2 font-weight-bold">{{$d(new Date(selectedTournament.firstDay), "month_day_year",
            $i18n.locale)}}</span>
          </div>
          <div>
            <span class="text-subtitle-1 mr-2">{{$t('message.days')}}</span>
            <span class="text-body-2 font-weight-bold">{{selectedTournament.days.length}}</span>
          </div>
          <div>
            <span class="text-subtitle-1 mr-2">{{$t('message.competitors')}}</span>
            <span class="text-body-2 font-weight-bold">{{selectedTournament.competitors.length}}</span>
          </div>
          <div>
            <span class="text-subtitle-1 mr-2">{{$t('message.divisions')}}</span>
            <span class="text-body-2 font-weight-bold">{{selectedTournament.divisions}}</span>
          </div>
          <div>
            <span class="text-subtitle-1 mr-2">{{$t('message.affiliations')}}</span>
            <span class="text-body-2 font-weight-bold">{{selectedTournament.affiliations}}</span>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn small @click="viewDetailsDialog=false">
            {{$t('message.close')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="viewSavedCompetitorsDialog" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card elevation="0">
        <v-toolbar dense color="primary">
          <StarIcon class="hero-icon" size="25"></StarIcon>
          <span class="ml-2">{{$t('message.your_saved_competitors')}}</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="viewSavedCompetitorsDialog = false">
            <XCircleIcon size="20" class="hero-button"></XCircleIcon>
          </v-btn>
        </v-toolbar>
        <div>
          <div class="pa-1 ma-1">
            <v-autocomplete clearable :search-input.sync="filterCompetitors" :loading="searchingCompetitors"
              :no-data-text="$t('message.filter_competitor_no_data')" :label="$t('message.add_saved_competitor')"
              @change="saveCompetitor" outlined dense :items="filteredCompetitors" item-text="name" item-value="ibjjfId"
              v-model="filteredCompetitor">
            </v-autocomplete>
          </div>
          <v-list dense class="mt-0">
            <v-list-item v-for="(e, index) in savedCompetitors" :key="'saved_competitor_'+e.ibjjfId">
              <v-list-item-content>
                <v-list-item-title>
                  <span class="mr-2 text-overline">{{index+1}}</span>
                  {{e.name}}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn icon small @click="updateSavedCompetitor(e.ibjjfId, e, 'remove')">
                  <TrashIcon size="20" class="hero-icon-button"></TrashIcon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="viewAllSavedMatchesDialog" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card elevation="0">
        <v-toolbar dense color="primary">
          <StarIcon class="hero-icon" size="25"></StarIcon>
          <span class="ml-2">{{$t('message.all_matches')}}</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="closeAllSaved()">
            <XCircleIcon size="20" class="hero-button"></XCircleIcon>
          </v-btn>
        </v-toolbar>
        <div>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn elevation="0" @click="viewAllSaved()" small :disabled="loadingAllSavedMatches">
              <RefreshIcon :class="'hero-button '+(loadingAllSavedMatches ? 'rotate' : '')"></RefreshIcon>
              {{$t('message.refresh')}}
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
          <div v-if="loadingAllSavedMatches">
            <v-skeleton-loader type="article, article, article"></v-skeleton-loader>
          </div>
          <div dense class="mt-0 pa-2" v-else>
            <v-card outlined class="mb-2 pa-1" elevation="0" v-for="(e) in allSavedMatches" :key="'saved_competitor_match_'+e._id">
              <div class="text-caption primary--text">{{e.tournament}}</div>
              <v-card-actions class="text-subtitle-1 pb-0 pt-0 pl-0">
                <CheckCircleIcon color="green" v-if="e.statusColor == 'green' && e.completed" size="20" class="hero-icon">
                </CheckCircleIcon>
                <StopIcon color="error" v-else-if="e.isLast" size="20" class="hero-icon"></StopIcon>
                <RefreshIcon color="green" v-else-if="e.isFirst" size="20" class="hero-icon rotate"></RefreshIcon>
                <BellIcon color="yellow" v-else-if="e.statusColor == 'yellow'" size="20" class="hero-icon"></BellIcon>
                <ClockIcon color="orange" v-else-if="e.statusColor == 'orange'" size="20" class="hero-icon"></ClockIcon>
                <ArrowCircleUpIcon color="gray" v-else size="20" class="hero-icon"></ArrowCircleUpIcon>
                <span class="ml-1">{{e.timeString}}</span>
                <span class="ml-2">({{e.round}})</span>
                <span v-if="e.mat" class="ml-2"> • mat {{e.mat}}</span>
                <v-spacer></v-spacer>
                <span v-if="!selectedDay && e.day" class="text-overline gray--text mr-2">{{$t('message.day')}} {{e.day}} •
                </span>
                <span v-if="e.fightNumber" class="text-overline gray--text">{{$t('message.fight')}} {{e.fightNumber}}</span>
              </v-card-actions>
              <v-card-text class="gray--text text-subtitle-2 pb-0 pt-0 pl-0">
                <BeltDisplay size="15" :show-text="false" :belt="e.belt" class="mr-2"></BeltDisplay>{{e.age}} / {{e.gender}}
                / {{e.weight}}
              </v-card-text>
              <v-divider class="mb-2"></v-divider>
              <table width="100%">
                <tr>
                  <td style="width:42%;vertical-align: top;" class="text-left">
                    <v-card v-if="e.competitorOne" elevation="0">
                      <div class="competitor-name text-subtitle-1 pb-0 pt-0 text-left font-weight-bold">
                        <CheckIcon v-if="e.completed && !e.competitorOne.loser" class="hero-icon" size="20"></CheckIcon>
                        <span
                          :class="(e.competitorOne.loser ? 'text-decoration-line-through' : '')+' '+((selectedCompetitor || selectedSavedCompetitor) && e.competitorOne.selected ? 'primary--text' : '')">{{e.competitorOne.name.toUpperCase()}}</span>
                      </div>
                      <v-card-text class="pt-0 pb-0 competitor-aff text-left pl-0">
                        <span
                          :class="selectedAffiliation && e.competitorOne.selected ? 'primary--text' : ''">{{e.competitorOne.affiliation}}</span>
                      </v-card-text>
                      <v-card-actions class="pl-0">
                        <span v-if="e.competitorOne.seed" class="text-caption">{{$t('message.seed')}}
                          {{e.competitorOne.seed}}</span>
                        <v-spacer></v-spacer>
                        <div v-if="e.competitorOne.id">
                          <v-btn small v-if="e.competitorOne.isSaved" icon
                            @click="updateSavedCompetitor(e.competitorOne.id, e.competitorOne, 'remove')">
                            <StarIcon :color="themeColor('gold')" size="20" class="hero-button"></StarIcon>
                          </v-btn>
                          <v-btn v-else small icon
                            @click="updateSavedCompetitor(e.competitorOne.id, e.competitorOne, 'add')">
                            <StarIcon color="gray" size="20" class="hero-button"></StarIcon>
                          </v-btn>
                        </div>
                      </v-card-actions>
                    </v-card>
                  </td>
                  <td style="width:16%;vertical-align: middle;" class="text-center">
                    <div class="text-h3">vs</div>
                  </td>
                  <td style="width:42%;vertical-align: top;" class="text-left">
                    <v-card v-if="e.competitorTwo" elevation="0">
                      <div class="competitor-name text-subtitle-1 pb-0 pt-0 text-left font-weight-bold">
                        <CheckIcon v-if="e.completed && !e.competitorTwo.loser" class="hero-icon" size="20"></CheckIcon>
                        <span
                          :class="(e.competitorTwo.loser ? 'text-decoration-line-through' : '')+' '+((selectedCompetitor || selectedSavedCompetitor) && e.competitorTwo.selected ? 'primary--text' : '')">{{e.competitorTwo.name.toUpperCase()}}</span>
                      </div>
                      <v-card-text class="pt-0 pb-0 competitor-aff text-left pl-0">
                        <span
                          :class="selectedAffiliation && e.competitorTwo.selected ? 'primary--text' : ''">{{e.competitorTwo.affiliation}}</span>
                      </v-card-text>
                      <v-card-actions class="pl-0">
                        <span class="text-caption" v-if="e.competitorTwo.seed">{{$t('message.seed')}}
                          {{e.competitorTwo.seed}}</span>
                        <v-spacer></v-spacer>
                        <div v-if="e.competitorTwo.id">
                          <v-btn small v-if="e.competitorTwo.isSaved" icon
                            @click="updateSavedCompetitor(e.competitorTwo.id, e.competitorTwo, 'remove')">
                            <StarIcon :color="themeColor('gold')" size="20" class="hero-button"></StarIcon>
                          </v-btn>
                          <v-btn v-else small icon
                            @click="updateSavedCompetitor(e.competitorTwo.id, e.competitorTwo, 'add')">
                            <StarIcon color="gray" size="20" class="hero-button"></StarIcon>
                          </v-btn>
                        </div>
                      </v-card-actions>
                    </v-card>
                  </td>
                </tr>
              </table>
              <v-divider v-if="e.time && e.timeZone && myTimeZone && e.timeZone != myTimeZone"></v-divider>
              <v-card-text v-if="e.time && e.timeZone && myTimeZone && e.timeZone != myTimeZone" class="text-subtitle-2 text-left ma-0 pl-0 pt-1 pb-1">
                {{$t('message.your_time')}}:
                <span class="ml-1">
                  {{$d(new Date(e.time), "time", $i18n.locale)}}
                </span>
                <span class="ml-2 mr-2">•</span>
                <span>
                  {{myTimeZone}}
                </span>
              </v-card-text>
            </v-card>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import NotificationService from "@/services/NotificationService";
import IBJJFService from "@/services/IBJJFService";
import BeltDisplay from "@/components/displays/BeltDisplay.vue";
import IconLineage from "@/components/displays/icons/IconLineage";

import { 
  StarIcon,
  InformationCircleIcon,
  RefreshIcon,
  ClockIcon,
  BellIcon,
  TrashIcon,
  XCircleIcon,
  CheckCircleIcon,
  CheckIcon,
  StopIcon,
  ArrowCircleUpIcon,
} from '@vue-hero-icons/outline'

export default {
  data() {
    return {
      tournaments: [],
      tournamentId: this.$route.params.tournamentId,
      notificationId: this.$route.params.notificationId,
      pastTournaments: [],
      days: [],
      mats: [],
      savedCompetitors: [],
      competitors: [],
      filteredCompetitors: [],
      filterCompetitors: null,
      filteredCompetitor: null,
      searchingCompetitors: false,
      affiliations: [],
      entries:[],
      divisions: [],
      savedCompetitorsFiltered: [],
      isDifferentTimeZone: false,
      myTimeZone: null,
      viewDetailsDialog: null,
      viewSelectBracketDialog: null,
      viewSavedCompetitorsDialog: null,
      hasSavedCompetitors: false,
      nameFilter: null,
      loadingTournaments: true,
      loadingEntries: false,
      loadingFilters: false,
      loadingMats: false,
      selectedBracket: null,
      selectedTournament: null,
      selectedCompetitor: null,
      selectedSavedCompetitor: null,
      selectedAffiliation: null,
      onlySavedFilter: null,
      selectedMat: null,
      selectedDay: null,
      lastUpdated: null,
      resultsDate: null,
      bracketUrl: null,
      viewAllSavedMatchesDialog: null,
      allSavedMatches: [],
      loadingAllSavedMatches: false
    };
  },
  components: {
    StarIcon,
    InformationCircleIcon,
    RefreshIcon,
    ClockIcon,
    BellIcon,
    ArrowCircleUpIcon,
    XCircleIcon,
    CheckCircleIcon,
    TrashIcon,
    BeltDisplay,
    StopIcon,
    CheckIcon,
    IconLineage,
  },
  created() {
    this.getActiveTournaments();
    if (this.notificationId) {
      this.readNotification();
    }
    this.getPastTournaments();
    this.myTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  },
  methods: {
    timeStringToTimeZone(timeString) {
      if (timeString) {
        const eventTimezone = this.selectedTournament.timeZone;
        let parts = timeString.match(/(\d+):(\d+) (\w+)/),
          hours = /am/i.test(parts[3]) ? parseInt(parts[1], 10) : parseInt(parts[1], 10) + 12,
          minutes = parseInt(parts[2], 10);
        let d = new Date();
        d.setHours(hours);
        d.setMinutes(minutes);
        d = this.adjustTimeToOffsetIBJJF(d, eventTimezone);
        return this.$d(d, "time", this.$i18n.locale);
      }
    },
    adjustTimeToOffsetIBJJF(date, timeZone) {
      //This is a quick fix to get european 2023 working w/o breaking other stuff
      if (timeZone) {
        let offset = this.getTimezoneOffset(timeZone);
        const d = new Date(date);
        //console.log("The date is: ", d);
        //console.log("Offset with [" + timeZone + "] is: ", offset);
        if (offset != 0) {
          offset = -1 * offset;
          d.setHours(d.getHours() + offset);
          //console.log("Returning adjusted: ", d);
          return d;
        } else {
          //console.log("Returning date: ", d);
          return d;
        }
      }
      return date;
    },
    viewAllSaved() {
      this.viewAllSavedMatchesDialog = true;
      this.loadingAllSavedMatches = true;
      IBJJFService.savedCompetitorsMatches()
        .then(data => {
          if (data) {
            this.allSavedMatches = data.fights;
            this.loadingAllSavedMatches = false;
          }
        });
    },
    closeAllSaved() {
      this.viewAllSavedMatchesDialog = false;
      this.allSavedMatches = [];
    },
    saveCompetitor(id) {
      this.savedCompetitors = [];
      IBJJFService.updateSavedCompetitor(id, 'add')
        .then(data => {
          if (data) {
            if (data.savedCompetitors && data.savedCompetitors.length > 0)
              this.hasSavedCompetitors = true;
            this.savedCompetitors = data.savedCompetitors;
          }
        });
    },
    readNotification() {
      NotificationService.read(this.notificationId);
    },
    openDivisionBracket(){
      if(this.selectedBracket){
        this.viewSelectBracketDialog = false;
        const link = "https://www.bjjcompsystem.com/tournaments/"+this.selectedTournament.ibjjfId+"/categories/"+this.selectedBracket;
        this.selectedBracket = null;
        this.goToLink(link);
      }
    },
    showBracket(entry) {
      console.log('Showing bracket: ', entry)
      if (entry && this.divisions && this.divisions.length > 0) {
        console.log('Checking divisions for: belt[' + entry.belt + '] age[' + entry.age + '] gender[' + entry.gender + '] weight[' + entry.weight +']')
        for (const d of this.divisions) {
          console.log('belt[' + d.belt + '] age[' + d.age + ']')
          if(entry.belt == d.belt && entry.age == d.age && entry.gender == d.gender && entry.weight == d.weight){
            console.log('HERE')
            const link = "https://www.bjjcompsystem.com/tournaments/" + this.selectedTournament.ibjjfId + "/categories/" + d.ibjjfId;
            this.selectedBracket = null;
            this.goToLink(link);
            return;
          }
        }
      }
    },
    openBracketUrl(url){
      if(url){
        this.selectedBracket = null;
        this.goToLink(url);
      }
    },
    filtedBySaved(item){
      this.selectedSavedCompetitor = item.ibjjfId;
      this.viewSavedCompetitorsDialog = false;
      this.filterEntries();
    },
    updateSavedCompetitor(id, competitor, action){
      if(action =='add')
        competitor.isSaved = true;
      else
        competitor.isSaved = false;
      this.savedCompetitors = [];
      IBJJFService.updateSavedCompetitor(id, action)
        .then(data => {
          if (data) {
            if(data.savedCompetitors && data.savedCompetitors.length > 0)
              this.hasSavedCompetitors =true;
            this.savedCompetitors = data.savedCompetitors;
            this.processSavedCompetitors();
          }
        });
    },
    viewSavedCompetitors(){
      this.viewSavedCompetitorsDialog = true;
    },
    getSavedCompetitors(){
      IBJJFService.savedCompetitors()
        .then(data => {
          if (data) {
            if(data.savedCompetitors && data.savedCompetitors.length > 0){
              this.savedCompetitors = data.savedCompetitors;
            }
          }
        });
    },
    getActiveTournaments() {
      IBJJFService.getActive()
        .then(data => {
          if (data) {
            this.tournaments = data.tournaments;
            if (this.tournamentId) {
              let selected = false;
              for (const t of this.tournaments) {
                if (t._id.toString() == this.tournamentId){
                  this.selectedTournament = t;
                  this.selectTournament();
                  this.selected = true;
                }
              }
              if (!selected) {
                //Load tourney and then select it
                IBJJFService.getTournament(this.tournamentId)
                  .then(tourneyData => {
                    if (tourneyData) {
                      this.selectedTournament = tourneyData.tournament;
                      this.selectTournament();
                      this.loadingTournaments = false;
                    }
                  });
              } else {
                this.loadingTournaments = false;
              }
            } else {
              this.loadingTournaments = false;
            }
            if(data.savedCompetitors && data.savedCompetitors.length > 0){
              this.hasSavedCompetitors = true;
              this.savedCompetitors = data.savedCompetitors;
            }
          }
        });
    },
    getPastTournaments(){
      IBJJFService.getPastTournaments()
        .then(data => {
          if (data) {
            this.pastTournaments = [];
            let tmp = data.tournaments;
            for (const e of tmp){
              if (e.divisions > 0)
                this.pastTournaments.push(e);
            }
          }
        });
    },
    processSavedCompetitors(){
      this.savedCompetitorsFiltered = [];
      if(this.savedCompetitors && this.savedCompetitors.length > 0 
        && this.selectedTournament 
        && this.selectedTournament.competitors){
        let foundOne = false;
        let tmp = [];
        let uniqueIds = [];
        for(let sc of this.savedCompetitors){
          if (this.selectedTournament.competitors.indexOf(sc._id) >= 0 && uniqueIds.indexOf(sc.ibjjfId) < 0){
            tmp.push(sc);
            uniqueIds.push(sc.ibjjfId);
            foundOne = true;
          }
        }
        if (foundOne) {
          if (tmp.length > 1){
            this.savedCompetitorsFiltered.push({
              ibjjfId: 'all',
              name: this.$t('message.all')
            });
          }
          this.savedCompetitorsFiltered = this.savedCompetitorsFiltered.concat(tmp);
        }
      }
    },
    selectTournament(){
      this.selectedAffiliation = null;
      this.selectedCompetitor = null;
      this.selectedSavedCompetitor = null;
      this.entries = [];
      if(this.selectedTournament.active){
        this.loadDays();
        this.filterEntries();
        this.selectedMat = 1;
      }
      this.loadFilters();
      this.processSavedCompetitors();
      if(this.selectedTournament.timeZone && this.myTimeZone && this.selectedTournament.timeZone != this.myTimeZone)
        this.isDifferentTimeZone = true;

      this.timeStringToTimeZone()
    },
    loadFilters(){
      this.loadingFilters = true;
      IBJJFService.getFilters(this.selectedTournament._id)
        .then(data => {
          if (data) {
            this.affiliations = data.affiliations;
            this.competitors = data.competitors;
            this.divisions = data.divisions;
            this.loadingFilters = false;
          }
        });
    },
    loadDays(){
      const days = this.selectedTournament.days.length;
      this.days = [];
      for(let i = 1; i<=days; i++){
        this.days.push(i);
      }
      let day = this.getCurrentDay();
      if(day > days)
        day = days;
      this.selectedDay = day;
      this.loadMats(day);
    },
    loadMats(day){
      this.mats = [];
      this.loadingMats = true;
      IBJJFService.getTournamentDayMats(this.selectedTournament._id, day)
        .then(data => {
          if (data) {
            const matsCount = data.mats;
            for(let i = 1; i<=matsCount; i++){
              this.mats.push(i);
            }
            this.loadingMats = false;
          }
        });
    },
    getCurrentDay(){
      let day = 1;
      const firstDay = this.toBeginningOfDay(new Date(this.selectedTournament.firstDay));
      const today = this.toBeginningOfDay(new Date());
      if(today > firstDay){
        const daysBetween = this.daysBetween(today, firstDay);
        if(daysBetween > 0)
          day = day + daysBetween;
      }
      return day;
    },
    isOngoing(t){
      if(t && t.firstDay){
        const days = t.days ? t.days.length : 1;
        const d1 = new Date(t.firstDay);
        d1.setHours(23);
        d1.setMinutes(59);//End of day
        if(days > 1)
          d1.setDate(d1.getDate() + (days - 1));
        const d2 = new Date();
        if(d1 > d2){
          return true;
        }
      }
      return false;
    },
    selectTournamentDay(){
      this.selectedMat = 1;
      this.selectedAffiliation = null;
      this.selectedCompetitor = null;
      this.selectedSavedCompetitor = null;
      this.loadMats(this.selectedDay);
      this.filterEntries();
    },
    getCompetitorMatches(competitorId){
      IBJJFService.getCompetitorMatches(this.selectedTournament._id, competitorId)
        .then(data => {
          if (data) {
            this.resultsDate = data.resultsDate;
            this.loadingEntries = false;
            this.entries = data.fights;
            this.lastUpdated = this.$d(new Date(data.lastUpdate), "long_seconds", this.$i18n.locale);
          }
        });
    },
    filterEntries(filter){
      if (filter == 'competitor') {
        this.selectedAffiliation = null;
        this.selectedSavedCompetitor = null;
      } else if (filter == 'saved') {
        this.selectedAffiliation = null;
        this.selectedCompetitor = null;
      } else if (filter == 'affiliation') {
        this.selectedCompetitor = null;
        this.selectedSavedCompetitor = null;
      }

      this.loadingEntries = true;
      this.lastUpdated = this.$t('message.loading')+'...';
      this.entries = [];
      if (this.selectedCompetitor) {
        this.selectedAffiliation = null;
        this.selectedSavedCompetitor = null;
      } else if (this.selectedAffiliation) {
        this.selectedCompetitor = null;
        this.selectedSavedCompetitor = null;
      }
      if(this.selectedSavedCompetitor){
        this.selectedCompetitor = null;
        this.selectedAffiliation = null;
      }
      if(this.selectedCompetitor || this.selectedAffiliation || this.selectedSavedCompetitor){
        this.selectedMat = null;
        this.selectedDay = null;
      } else if(!this.selectedDay || !this.selectedMat){
        if(!this.selectedMat)
          this.selectedMat = 1;
        if(!this.selectedDay)
          this.selectedDay = 1;
      }
      if(!this.selectedTournament.active){
        if(!this.selectedCompetitor && !this.selectedSavedCompetitor){
          this.entries = [];
        }else{
          this.entries = [];
          let competitorId = this.selectedCompetitor;
          if(!competitorId)
            competitorId = this.selectedSavedCompetitor;
          IBJJFService.getCompetitorBracket(this.selectedTournament._id, competitorId)
            .then(data => {
              if (data) {
                this.loadingEntries = false;
                this.bracketUrl = data.url;
              }
            });
        }
      }else if(this.selectedSavedCompetitor && this.selectedSavedCompetitor != 'all'){
        this.getCompetitorMatches(this.selectedSavedCompetitor);
      }else if(this.selectedCompetitor){
        this.getCompetitorMatches(this.selectedCompetitor)
      }else{
        const data = {
          tournamentId: this.selectedTournament._id,
          day: this.selectedDay,
          mat: this.selectedMat,
          savedCompetitor: this.selectedSavedCompetitor,
          affiliation: this.selectedAffiliation ? this.selectedAffiliation : null,
          competitor: this.selectedCompetitor ? this.selectedCompetitor : null,
        };
        IBJJFService.getFights(data)
          .then(data => {
            if (data) {
              this.resultsDate = data.resultsDate;
              this.loadingEntries = false;
              this.entries = data.fights;
              this.lastUpdated = this.$d(new Date(data.lastUpdate), "long_seconds", this.$i18n.locale);
            }
          });
      }
    }
  },
  watch: {
    nameFilter: {
      handler: function() {
        this.filterEntries();
      }
    },
    filterCompetitors(term) {
      this.searchingCompetitors = true;
      IBJJFService.filterCompetitors(term)
        .then(data => {
          if (data) {
            this.searchingCompetitors = false;
            this.filteredCompetitors = data.results;
          }
        });
    },
  }
};
</script>

<style scoped>
.competitor-name{
  height: 55px;
  overflow-y: hidden;
  vertical-align: bottom;
}
.competitor-aff{
  height: 25px;
  overflow-y: hidden;
  vertical-align: top;
}
</style>