<template>
  <v-sheet v-if="isAppAdmin(this.$store.state.user._id)" :rounded="isSmallerDisplay() ? '' : 'lg'">
    <v-skeleton-loader v-if="loadingData" type="card, table"></v-skeleton-loader>
    <v-card v-if="!loadingData" class="pa-2" elevation="0">
      <div>
        <v-card-actions>
          <span class="mr-2">
            Total Academies: <b class="ml-2 text-h6">{{totalAcademies}}</b>
          </span>
          Total Countries: <b v-if="academiesByCountry.data" class="ml-2 text-h6">{{academiesByCountry.data.length}}</b>
          <v-spacer></v-spacer>
          <v-btn elevation="0" color="primary" v-if="!isSmallerDisplay()" small primary
            @click="addManagedAcademyDialog=true">Add New
            Managed Academy</v-btn>
        </v-card-actions>
      </div>
      <v-row>
        <v-col cols="12" lg="3" class="pa-3">
          <div>
            <ApexChart v-if="academiesByCountry.options" type="donut" :options="academiesByCountry.options" height="350"
              :series="academiesByCountry.data"></ApexChart>
          </div>
        </v-col>
        <v-col cols="12" lg="3" class="pa-3">
          <div>
            <ApexChart v-if="usingPayments.options" type="pie" :options="usingPayments.options" height="350"
              :series="usingPayments.data"></ApexChart>
          </div>
        </v-col>
        <v-col cols="12" lg="6" class="pa-3">
          <ApexChart v-if="membershipsTimeline.options" type="area" :options="membershipsTimeline.options" height="350"
            :series="membershipsTimeline.data"></ApexChart>
        </v-col>
      </v-row>
    </v-card>
    <v-flex style="width:100%" v-if="!loadingData">
      <v-card style="width:100%">
        <v-card-text>
          <v-data-table :search="search" :custom-filter="filterTable" :headers="headers" :items="stats"
            class="elevation-1">
            <template v-slot:top>
              <v-text-field dense outlined v-model="search" label="Filter Table by Customer Name"
                class="mx-4"></v-text-field>
              <div class="text-overline">Table data from: {{$d(new Date(dataFrom), 'month_day_year', $i18n.locale)}}
              </div>
            </template>
            <template v-slot:item.name="{ item }">
              <v-avatar size="25" class="mr-2">
                <img :src="userImg(item.image)" />
              </v-avatar>
              <a @click="selectAcademy(item)">{{ item.name }}</a>
            </template>
            <template v-slot:item.country="{ item }">
              <CountryFlagDisplay :avatar="false" :countryCode="item.country"></CountryFlagDisplay>
            </template>
            <template v-slot:item.stripeConnected="{ item }">
              <CreditCardIcon @click="selectAcademy(item)" color="gray" v-if="!item.paymentsReady" class="hero-icon">
              </CreditCardIcon>
              <CreditCardIcon @click="selectAcademy(item)" color="teal" v-else-if="item.stripeConnected"
                class="hero-icon"></CreditCardIcon>
              <CreditCardIcon @click="selectAcademy(item)" color="error" v-else class="hero-icon"></CreditCardIcon>
            </template>
            <template v-slot:item.engagementIndex="{ item }">
              <v-progress-circular @click="selectAcademy(item)" :size="40" :width="6" :value="item.engagementIndex"
                :color="getIndexColor(item.engagementIndex)">
                {{ item.engagementIndex }}
              </v-progress-circular>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-btn icon @click="viewEditAcademy(item)">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
      <v-dialog width="80%" v-model="addManagedAcademyDialog">
        <v-card outlined flat>
          <v-card-title>Add New Managed Academy</v-card-title>
          <v-card-text>
            <v-text-field dense outlined v-model="newManagedAcademy.academy" label="Academy ID"
              class="mx-4"></v-text-field>
            <v-text-field dense outlined v-model="newManagedAcademy.name" label="Internal Name (no spaces)"
              class="mx-4"></v-text-field>
            <v-text-field dense outlined v-model="newManagedAcademy.email" label="Email" class="mx-4"></v-text-field>
            <v-text-field dense outlined v-model="newManagedAcademy.tabletId" label="Tablet ID"
              class="mx-4"></v-text-field>
            <v-text-field dense outlined v-model="newManagedAcademy.preferredLanguage"
              label="Preferred Language (EN|ES|PT)" class="mx-4"></v-text-field>
            <v-text-field dense outlined v-model="newManagedAcademy.admins" label="Admins (Comma Separated)"
              class="mx-4 mb-0 pb-0"></v-text-field>
            <v-text-field dense outlined v-model="newManagedAcademy.academyLineageNode"
              label="Main Lineage Node (go to lineage manager)" class="mx-4 mb-0 pb-0"></v-text-field>
            <div style="margin-top:-10px" class="mb-2 ml-2 text-caption">*Academy managers addeded automaticallt. noah
              santi and Info also added by default</div>
            <v-text-field dense outlined v-model="newManagedAcademy.currency" label="Currency"
              class="mx-4 mt-2"></v-text-field>
            <v-text-field dense outlined v-model="newManagedAcademy.currencySymbol" label="Currency Symbol"
              class="mx-4"></v-text-field>
            <v-text-field dense outlined v-model="newManagedAcademy.zipCode" label="Zip Code"
              class="mx-4"></v-text-field>
            <v-switch label="Payments Ready" v-model="newManagedAcademy.paymentsReady"></v-switch>
            <div v-if="newManagedAcademy.paymentsReady">
              <div>
                <v-select outlined dense @change="fillPaymentModuleDetails" :items="paymentModules"
                  v-model="newManagedAcademy.paymentsModule" label="Payments Module" item-text="name" item-value="value"
                  :clearable="true">
                </v-select>
              </div>
              <div
                v-if="newManagedAcademy.paymentsModule == 'infinicept' && newManagedAcademy.paymentsModuleDetails && newManagedAcademy.paymentsModuleDetails.fees">
                <v-text-field dense outlined v-model="newManagedAcademy.paymentsModuleDetails.fees.masterAndVisa.fee"
                  label="MC/Visa Fee"></v-text-field>
                <v-text-field dense outlined v-model="newManagedAcademy.paymentsModuleDetails.fees.masterAndVisa.rate"
                  label="MC/Visa Rate"></v-text-field>
                <v-text-field dense outlined v-model="newManagedAcademy.paymentsModuleDetails.fees.amex.fee"
                  label="Amex Fee"></v-text-field>
                <v-text-field dense outlined v-model="newManagedAcademy.paymentsModuleDetails.fees.amex.rate"
                  label="Amex Rate"></v-text-field>
                <v-text-field dense outlined v-model="newManagedAcademy.paymentsModuleDetails.fees.discover.fee"
                  label="Discover Fee"></v-text-field>
                <v-text-field dense outlined v-model="newManagedAcademy.paymentsModuleDetails.fees.discover.rate"
                  label="Discover Rate"></v-text-field>
              </div>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-btn elevation="0" color="primary" @click="addManagedAcademy()">Add</v-btn>
            <v-btn elevation="0"  @click="addManagedAcademyDialog=false">close</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog width="70%" v-model="academyEditsDialog" hide-overlay transition="dialog-bottom-transition" scrollable>
        <v-card outlined>
          <v-toolbar flat dark>
            <v-btn icon dark @click="academyEditsDialog = false; selectedAcademy = null;">
              <XCircleIcon class="hero-icon-button"></XCircleIcon>
            </v-btn>
            <v-toolbar-title v-if="selectedAcademy">
              <h3>{{selectedAcademy.name}}</h3>
            </v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text style="text-align: center" v-if="selectedAcademy && selectedAcademy.configuration">
            <v-switch hide-details dense label="Allow Terminal"
              v-model="selectedAcademy.configuration.allowTerminal"></v-switch>
            <v-switch hide-details dense label="Automatic Tax Calculator"
              v-model="selectedAcademy.configuration.automaticTaxCalculation"></v-switch>
            <v-switch hide-details dense label="Alternative Tablet Checkin"
              v-model="selectedAcademy.configuration.altTabletCheckin"></v-switch>
            <v-switch dense label="Payments Ready" v-model="selectedAcademy.paymentsReady"></v-switch>
            <v-text-field outlined dense v-model="selectedAcademy.feePercent" type="number"
              label="Application Fee Percent"></v-text-field>
            <v-text-field hide-details outlined dense v-model="selectedAcademy.newAdmins"
              label="New Admins"></v-text-field>
          </v-card-text>
          <v-card-actions class="mt-0 pt-0">
            <v-btn elevation="0" color="primary" small @click="updateManagedAcdemy()">Update</v-btn>
            <v-btn :disabled="processingCheck ? true : false" small elevation="0" color="primary"
              @click="subscriptionsCheck(selectedAcademy)">
              <RefreshIcon v-if="processingCheck" size="20" class="hero-icon rotate"></RefreshIcon>
              Subscriptions Check
            </v-btn>
            <v-btn small outlined elevation="0" color="error" @click="toggleActive(selectedAcademy)">
              Deactivate
            </v-btn>
          </v-card-actions>
          <v-divider class="mt-1 mb-2"></v-divider>
          <v-card-text>
            <v-text-field hide-details v-model="lineageNodeId" dense outlined
              label="Academy Lineage Node"></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" elevation="0" :disabled="lineageNodeId ? false : true" small outlined
              @click="updateLineageNode()">
              Update Node
            </v-btn>
          </v-card-actions>
          <v-divider class="mt-1 mb-2"></v-divider>
          <v-card-text>
            <v-text-field hide-details v-model="transactionId" dense outlined
              label="Inspect Transaction"></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-btn elevation="0" :disabled="transactionId ? false : true" small outlined color="primary"
              @click="checkTransaction()">
              Check Transaction/PI
            </v-btn>
          </v-card-actions>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn elevation="0" @click="academyEditsDialog = false; selectedAcademy = null;">Close</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog width="70%" v-model="academyDetailsDialog" hide-overlay transition="dialog-bottom-transition"
        scrollable>
        <v-card outlined>
          <v-toolbar dark color="secondary">
            <v-btn icon dark @click="closeDialog()">
              <XCircleIcon class="hero-icon-button"></XCircleIcon>
            </v-btn>
            <v-toolbar-title v-if="selectedAcademy">
              <h3>{{selectedAcademy.name}}</h3>
            </v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <div style="text-align: center" v-if="selectedAcademy">
            <table width="100%">
              <tr>
                <td class="details-title">DB ID: </td>
                <td class="text-left">{{selectedAcademy._id}}</td>
              </tr>
              <tr>
                <td class="details-title">BJJLINK Social Academy: </td>
                <td @click="goToAcademy(selectedAcademy)" class="text-left"><a>{{selectedAcademy.academyName}}</a></td>
              </tr>
              <tr>
                <td class="details-title">Stripe Connected: </td>
                <td class="text-left">{{selectedAcademy.stripeConnected}}</td>
              </tr>
              <tr>
                <td class="details-title">Country: </td>
                <td class="text-left">
                  <CountryFlagDisplay :avatar="false" :countryCode="selectedAcademy.country"></CountryFlagDisplay>
                  <span class="ml-2">
                    {{selectedAcademy.country}}
                  </span>
                </td>
              </tr>
              <tr>
                <td class="details-title">Total Admins: </td>
                <td class="text-left">{{selectedAcademy.totalAdmins}}</td>
              </tr>
              <tr>
                <td class="details-title">Total Members: </td>
                <td class="text-left">{{selectedAcademy.totalMembers}}</td>
              </tr>
              <tr>
                <td class="details-title">Last Activity: </td>
                <td class="text-left">{{ selectedAcademy.lastActivity ? $d(new Date(selectedAcademy.lastActivity),
                  'short', $i18n.locale) : 'n/a' }}</td>
              </tr>
              <tr>
                <td class="details-title">Total Activities: </td>
                <td class="text-left">{{selectedAcademy.adminActivitiesCount}}</td>
              </tr>
              <tr>
                <td class="details-title">Last Checkin: </td>
                <td class="text-left">{{ selectedAcademy.lastCheckin ? $d(new Date(selectedAcademy.lastCheckin),
                  'short', $i18n.locale) : 'n/a' }}</td>
              </tr>
              <tr>
                <td class="details-title">Distinct User Checkins: </td>
                <td class="text-left">{{ selectedAcademy.userCheckins}}</td>
              </tr>
              <tr>
                <td class="details-title">Last Transaction </td>
                <td class="text-left">{{ selectedAcademy.lastTransaction ? $d(new Date(selectedAcademy.lastTransaction),
                  'short', $i18n.locale) : 'n/a' }}</td>
              </tr>
              <tr>
                <td class="details-title">Total Transactions: </td>
                <td class="text-left">{{selectedAcademy.totalTransactions}}</td>
              </tr>
              <tr>
                <td class="details-title">Total Card Transactions: </td>
                <td class="text-left">{{selectedAcademy.cardTransactionsCount}}</td>
              </tr>
              <tr>
                <td class="details-title">Total Products: </td>
                <td class="text-left">{{selectedAcademy.totalProducts}}</td>
              </tr>
              <tr>
                <td class="details-title">Checkins: </td>
                <td class="text-left">{{selectedAcademy.userCheckins}}</td>
              </tr>
              <tr>
                <td class="details-title">Currency: </td>
                <td class="text-left">{{selectedAcademy.currency}}</td>
              </tr>
              <tr>
                <td class="details-title">Engagement Index: </td>
                <td class="text-left">{{selectedAcademy.engagementIndex}}</td>
              </tr>
              <tr>
                <td class="details-title">Main Lineage Node: </td>
                <td class="text-left">{{selectedAcademy.academyLineageNode }}</td>
              </tr>
            </table>
            <div class="text-right text-overline">All counts are for the last 30 days</div>
          </div>
        </v-card>
      </v-dialog>
    </v-flex>
    <v-flex class="mt-2">
      <v-card elevation="0">
        <v-list v-if="!loadingData">
          <v-subheader>
            Inactive Managed Academies
          </v-subheader>
          <template v-for="(i) in inactiveAcademies">
            <v-list-item :key="'inactive_'+i._id">
              <v-list-item-avatar size="25" class="mr-2">
                <img :src="userImg(i.image)" />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  {{ i.name}}
                </v-list-item-title>
                <v-list-subtitle v-if="i.demoAccount">
                  Demo account
                </v-list-subtitle>
              </v-list-item-content>
              <v-list-item-action class="text-caption">
                <v-btn small elevation="0" color="success" @click="toggleActive(i)">
                  <v-icon>mdi-checkbox-marked-circle</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </template>
        </v-list>
      </v-card>
    </v-flex>
  </v-sheet>
  <v-sheet v-else :rounded="isSmallerDisplay() ? '' : 'lg'">
    <div class="text-center">
      <h2>You can't come to these secret places</h2>
      <div>
        <icon-master color="error" :size="200" />
      </div>
    </div>
  </v-sheet>
</template>

<script>
import IconMaster from "@/components/displays/icons/IconMaster.vue";
import BjjlinkAdminService from "@/services/BjjlinkAdminService";
import ApexChart from 'vue-apexcharts';
import CountryFlagDisplay from "@/components/displays/CountryFlagDisplay.vue";

//Icons
import { 
  XCircleIcon,
  CreditCardIcon,
  RefreshIcon,
} from '@vue-hero-icons/outline'

export default {
  components: {
    IconMaster,
    XCircleIcon,
    ApexChart,
    CreditCardIcon,
    CountryFlagDisplay,
    RefreshIcon,
  },
  data() {
    return {
      academyDetailsDialog: false,
      academyEditsDialog: false,
      lineageNodeId: null,
      transactionId: null,
      addManagedAcademyDialog: null,
      loadingData: true,
      selectedAcademy: null,
      paymentModules: ['stripe','infinicept'],
      search: null,
      processingCheck: false,
      newManagedAcademy: {
        preferredLanguage: 'EN',
        admins: "5bf0448692698d06ab415088,5bf1b1a849808205ba15039a,5bf1b1a849808205ba15039a",
        currencySymbol: "$",
        currency:"USD",
        paymentsModuleDetails: {}
      },
      totalAcademies: 0,
      totalWithPayments: 0,
      academiesByCountry: {},
      membershipsTimeline: {},
      usingPayments: {},
      dataFrom: null,
      headers: [
        {
          text: "Name",
          align: "left",
          sortable: true,
          value: "name",
        },
        {
          text: "Country",
          align: "left",
          sortable: true,
          value: "country",
        },
        {
          text: "Stripe",
          align: "left",
          sortable: true,
          value: "stripeConnected",
        },
        {
          text: "Admins",
          align: "left",
          sortable: true,
          value: "totalAdmins",
        },
        {
          text: "Members",
          align: "left",
          sortable: true,
          value: "totalMembers",
        },
        {
          text: "Transactions",
          align: "left",
          sortable: true,
          value: "totalTransactions",
        },
        {
          text: "Products",
          align: "left",
          sortable: true,
          value: "totalProducts",
        },
        {
          text: "Checkins",
          align: "left",
          sortable: true,
          value: "userCheckins",
        },
        {
          text: "EIDX",
          align: "left",
          sortable: true,
          value: "engagementIndex",
        },
        {
          text: "",
          align: "left",
          value: "actions",
        },
      ],
      stats: [],
      inactiveAcademies:[],
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    fillPaymentModuleDetails() {
      if (this.newManagedAcademy.paymentsModule) {
        if (this.newManagedAcademy.paymentsModule == 'stripe') {
          this.newManagedAcademy.paymentsModuleDetails = {module: 'stripe'};
        } else if (this.newManagedAcademy.paymentsModule == 'infinicept') {
          this.newManagedAcademy.paymentsModuleDetails = {
            module: 'infinicept',
            gateway: 'datacap',
            invited: null,
            ready: false,
            fees: {
              amex: { rate: 3.5, fee: 0.30 },
              masterAndVisa: { rate: 2.89, fee: 0.30 },
              discover: { rate: 2.89, fee: 0.30 },
            }, };         
        }
      }
    },
    updateLineageNode() {
      if (!this.lineageNodeId) {
        this.showAlert("error", "Oops!", this.$t("message.error_generic"));
        return;
      }
      BjjlinkAdminService.uopdateAcademyLineage(this.selectedAcademy._id, this.lineageNodeId)
        .then((data) => {
          if (data) {
            this.showAlert("success", data.message);
          }
        }).catch((e) => {
          if (e)
            this.showAlert("error", "Oops!", this.$t("message.error_generic"));
        });
    },
    addManagedAcademy() {
      this.newManagedAcademy.preferredLanguage = this.newManagedAcademy.preferredLanguage.toUpperCase();
      this.newManagedAcademy.email = this.newManagedAcademy.email.toLowerCase().trim();
      this.newManagedAcademy.currency = this.newManagedAcademy.currency.toUpperCase().trim();
      const split = this.newManagedAcademy.admins.split(',');
      this.newManagedAcademy.admins = split;
      BjjlinkAdminService.addManagedAcademy(this.newManagedAcademy)
        .then((data) => {
          if (data) {
            this.newManagedAcademy = {
              preferredLanguage: 'EN',
              admins: "5bf0448692698d06ab415088,5bf1b1a849808205ba15039a,5bf1b1a849808205ba15039a",
              currencySymbol: "$",
              currency: "USD"
            };
            this.addManagedAcademyDialog = false;
            this.showAlert("success", "Added!");
          }
        }).catch((e) => {
          if (e)
            this.showAlert("error", "Oops!", this.$t("message.error_generic"));
        });
    },
    checkTransaction() {
      if (this.transactionId.indexOf('pi_') >= 0) {
        BjjlinkAdminService.checkPaymentIntent(this.selectedAcademy._id, this.transactionId)
          .then((data) => {
            if (data) {
              this.transactionId = null;
              this.showAlert("success", "Check inspector!");
            }
          }).catch((e) => {
            if (e)
              this.showAlert("error", "Oops!", this.$t("message.error_generic"));
          });
      } else {
        //checkTransaction
        BjjlinkAdminService.checkTransaction(this.transactionId)
          .then((data) => {
            if (data) {
              this.transactionId = null;
              this.showAlert("success", "Check inspector!");
            }
          }).catch((e) => {
            if (e)
              this.showAlert("error", "Oops!", this.$t("message.error_generic"));
          });
      }
    },
    loadData() {
      this.loadingData = true;
      this.selectedAcademy = null;
      BjjlinkAdminService.managedAcademiesStats()
        .then((data) => {
          if (data) {
            this.stats = data.academies;
            this.totalAcademies = data.totalAcademies;
            this.totalWithPayments = data.totalWithPayments;
            //Using payments
            this.buildPaymentsChart();
            //Dount by country
            this.buildCountriesChart(data.academiesByCountry);
            //Memberhips timeline
            this.buildMembershipsTimeline(data.newMembershipsTimeline);
            this.loadingData = false;
            this.dataFrom = data.from;
          }
        }).catch((e) => {
          if (e)
            this.showAlert("error", "Oops!", this.$t("message.error_generic"));
        });
      BjjlinkAdminService.inactiveManagedAcademies()
        .then((data) => {
          if (data) {
            this.inactiveAcademies = data.academies;
          }
        });
    },
    toggleActive(i) {
      const maId = i._id;
      BjjlinkAdminService.toogleManagedAcademyActive(maId)
        .then((data) => {
          if (data) {
            this.showAlert("success", "Updated");
            this.loadData();
            this.selectAcademy();
          }
        }).catch((e) => {
          if (e)
            this.showAlert("error", this.$t("message.error_generic"));
        });
    },
    subscriptionsCheck(i) {
      this.processingCheck = true;
      const maId = i._id;
      BjjlinkAdminService.subscriptionsCheck(maId)
        .then((data) => {
          if (data) {
            this.showAlert("success", "Check your email");
            this.processingCheck = false;
          }
        }).catch((e) => {
          if (e)
            this.showAlert("error", this.$t("message.error_generic"));
        });
    },
    updateManagedAcdemy() {
      this.selectedAcademy.feePercent = new Number(this.selectedAcademy.feePercent).valueOf();
      BjjlinkAdminService.updateManagedAcademyConfiguration(this.selectedAcademy._id, this.selectedAcademy)
        .then((data) => {
          if (data) {
            this.showAlert("success", "Updated");
          }
        }).catch((e) => {
          if (e)
            this.showAlert("error", "Oops!", this.$t("message.error_generic"));
        });
    },
    goToAcademy(item){
      window.open('https://bjjlink.com/secure/academy/'+item.academyId);
    },
    getIndexColor(index){
      if(index >= 75)
        return 'teal';
      else if(index >= 50)
        return 'amber';
      else 
        return 'red'
    },
    buildMembershipsTimeline(data){
      let cashTransactions = [];
      let creditTransactions = [];
      let categories = [];
      for (const d of data) {
        const dateString = this.$d(new Date(d.date), "month_year", this.$i18n.locale)
        categories.push(dateString);
        cashTransactions.push(d.cash);
        creditTransactions.push(d.card);
      }
      const series = [
        {
          name: 'Cash',
          data: cashTransactions
        },
        {
          name: 'Credit',
          data: creditTransactions
        },
      ];
      this.membershipsTimeline.data = series;
      this.membershipsTimeline.options = {
        labels: ['Cash', 'Credit'],
        xaxis: {
                  categories: categories,
                  labels: {
                    show: true,
                    style: {
                      colors: this.themeColor('title')
                    },
                  }
        },
        yaxis: {
                  labels: {
                    show: true,
                    style: {
                      colors: this.themeColor('title')
                    },
                  }
        },
        dataLabels: {
                  enabled: false,
        },
        stroke: {
                  curve: 'smooth'
        },
        theme: {
                  mode: this.isDarkTheme() ? 'dark' : 'light',
        },
        chart: {
                  toolbar: {
                    show: false
                  },
                  background: 'none'
        },
        legend:{
                  position: 'bottom'
        },
        grid: {
                  borderColor: this.themeColor('gray'),
        },
      };
    },
    buildPaymentsChart(){
      let labels = ['Not Using Payments', 'Using Payments'];
      
      let series =[this.totalAcademies-this.totalWithPayments, this.totalWithPayments];
      this.usingPayments.data = series;
      this.usingPayments.options = {
        theme: {
          mode: this.isDarkTheme() ? 'dark' : 'light',
        },
        chart: {
          background: 'none'
        },
        legend:{
          position: 'bottom'
        },
        labels: labels
      };
    },
    buildCountriesChart(data){
      let countries = [];
      let series =[];
      for(const d of data){
        countries.push(d.country);
        series.push(d.count);
      }
      this.academiesByCountry.data = series;
      this.academiesByCountry.options = {
        theme: {
          mode: this.isDarkTheme() ? 'dark' : 'light',
        },
        chart: {
          background: 'none'
        },
        legend:{
          position: 'bottom'
        },
        labels: countries
      };
    },
    addPartner() {},
    selectAcademy(academy, skipDialog) {
      if (!academy) {
        this.academyDetailsDialog = false;
        this.academyEditsDialog = false;
        this.selectedAcademy = null;
        return;
      }
      let a = academy;
      if (!a.configuration) {
        a.configuration = {
          allowTerminal: false,
          automaticTaxCalculation: false,
          altTabletCheckin: false,
          privateAcademyFeed: false,
        };
      }
      console.log(a)
      this.selectedAcademy = a;
      if(!skipDialog)
        this.academyDetailsDialog = true;
    },
    viewEditAcademy(academy) {
      this.selectAcademy(academy, true);
      this.academyEditsDialog = true;
    },
    closeDialog(){
      this.selectAcademy();
    },
    filterTable(value, search) {
      return value != null &&
        search != null &&
        typeof value === 'string' &&
          value.toString().toLowerCase().indexOf(search.toLowerCase()) !== -1
    },
  },
};
</script>

<style scoped>
.text-left {
  text-align: left;
  padding-left: 10px
}
.details-title{
  font-weight: bold;
  text-align: right;
  width:50%
}
</style>
